import {
  DataGridPro,
  GridColDef,
  GridColumnGroupingModel,
  GridPinnedRowsProp,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import styled from "styled-components";
import GuestBranchOfficeTree from "../../../containers/guest/components/GuestBranchOfficeTree";
import {
  CompanyGroupRequest,
  RequiredRequest,
} from "../../../interface/CommonRequest";
import { BuyingCompanyGroup, GuestGridColumn } from "../../../interface/Guest";
import { color } from "../../../styles/color";
import { getValueFormattedPercent } from "../../../utils/gridUtils";
import GroupHeaderChip from "../../table/GroupHeaderChip";
import WdbTooltip from "../../tooltip/WdbTooltip";

export interface GuestGridProps {
  gridData: GuestGridColumn[];
  getCompanyGroupData: (
    params: CompanyGroupRequest
  ) => Promise<BuyingCompanyGroup[] | undefined>;
  startDate: string | null;
  endDate: string | null;
  loading: boolean;
}

export function GuestGrid({
  startDate,
  endDate,
  getCompanyGroupData,
  gridData,
  loading,
}: GuestGridProps) {
  const gridRef = useGridApiRef();

  const totalRow = gridData?.filter(
    (item: GuestGridColumn) => item.sigunguName === "합계"
  );
  const pinnedRows: GridPinnedRowsProp = {
    top: totalRow,
  };

  return (
    <GuestGridWrapper>
      <DataGridPro
        sx={{ mt: 2 }}
        apiRef={gridRef}
        experimentalFeatures={{ columnGrouping: true }}
        rows={gridData}
        columns={guestColumns}
        loading={loading}
        disableSelectionOnClick
        columnGroupingModel={guestColumnGroupingModel}
        columnVisibilityModel={{
          sigunguCode: false,
          sigunguName: false,
        }}
        groupingColDef={{
          renderCell: (params: GridRenderCellParams) => (
            <>
              <GuestBranchOfficeTree
                {...params}
                getTree={getCompanyGroupData}
                startDate={startDate}
                endDate={endDate}
                gridData={gridData}
              />
            </>
          ),
          headerName: "지역(손님이 원하는 기준)",
          renderHeader: () => (
            <p>
              지역
              <br />
              (손님이 원하는 기준)
            </p>
          ),
          headerClassName: "area-name",
          cellClassName: "area-name",
          headerAlign: "center",
          flex: 1,
        }}
        treeData
        getTreeDataPath={(row) => row.path}
        rowHeight={40}
        hideFooter={true}
        pinnedRows={pinnedRows}
      />
    </GuestGridWrapper>
  );
}

export const guestColumns: GridColDef[] = [
  {
    field: "sigunguCode",
  },
  {
    field: "otherTradeCount",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    headerClassName: "other-trade-count",
    cellClassName: "other-trade-count",
    headerAlign: "center",
    disableColumnMenu: true,
    align: "right",
    flex: 0.3,
  },
  {
    field: "otherRentalCount",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "otherMonthlyCount",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "othertotalCount",
    headerName: "전체",
    headerAlign: "center",
    headerClassName: "other-total-count",
    cellClassName: "other-total-count",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },

  {
    field: "otherBuyingTradeCount",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "otherBuyingRentalCount",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "otherBuyingMonthlyCount",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "otherBuyingTotalCount",
    headerName: "전체",
    headerAlign: "center",
    headerClassName: "other-buying-total-count",
    cellClassName: "other-buying-total-count",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },

  {
    field: "wdbTradeCount",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "wdbRentalCount",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "wdbMonthlyCount",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
  },
  {
    field: "wdbTotalCount",
    headerName: "전체",
    renderHeader: () => (
      <p>
        전체 <br />
        <Red>(중복 제거)</Red>
      </p>
    ),
    headerAlign: "center",
    headerClassName: "wdb-count",
    cellClassName: "wdb-count",
    align: "right",
    disableColumnMenu: true,
    flex: 0.5,
  },

  {
    field: "tradeRate",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
    valueFormatter: getValueFormattedPercent,
  },
  {
    field: "rentalRate",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
    valueFormatter: getValueFormattedPercent,
  },
  {
    field: "monthlyRentalRate",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    disableColumnMenu: true,
    flex: 0.3,
    valueFormatter: getValueFormattedPercent,
  },
  {
    field: "totalRate",
    headerName: "전체",
    renderHeader: () => (
      <p>
        전체 <br />
        <Red>(중복 제거)</Red>
      </p>
    ),
    headerAlign: "center",
    headerClassName: "rate",
    cellClassName: "rate",
    align: "right",
    disableColumnMenu: true,
    flex: 0.7,
    valueFormatter: getValueFormattedPercent,
  },
];
export const guestColumnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "우대빵 보유매물 중 타부동산 거래 건수",
    renderHeaderGroup: () => (
      <p>
        우대빵 보유매물 중
        <br />
        타부동산 거래 건수
      </p>
    ),
    headerAlign: "center",
    headerClassName: "header-other-count",
    children: [
      { field: "otherTradeCount" },
      { field: "otherRentalCount" },
      { field: "otherMonthlyCount" },
      { field: "othertotalCount" },
    ],
  },
  {
    groupId: "지역 손님 수(추정)",
    renderHeaderGroup: () => (
      <p>
        지역 손님 수
        <br />
        <Secondary>(추정)</Secondary>
      </p>
    ),
    headerAlign: "center",
    headerClassName: "header-other-buying-count",
    children: [
      { field: "otherBuyingTradeCount" },
      { field: "otherBuyingRentalCount" },
      { field: "otherBuyingMonthlyCount" },
      { field: "otherBuyingTotalCount" },
    ],
  },
  {
    groupId: "우대빵 등록 손님 수",
    headerAlign: "center",
    headerClassName: "header-wdb-count-allowed",
    children: [
      { field: "wdbTradeCount" },
      { field: "wdbRentalCount" },
      { field: "wdbMonthlyCount" },
      { field: "wdbTotalCount" },
    ],
  },
  {
    groupId: "손님 확보 비율",
    headerAlign: "center",
    headerClassName: "header-rate",
    description: "우대빵 등록 손님수 / (지역손님수 + 우대빵등록 손님수)",
    renderHeaderGroup: () => (
      <p>
        손님 확보 비율{" "}
        <WdbTooltip
          title={"우대빵 등록 손님수 / (지역손님수 + 우대빵등록 손님수)"}
          fontSize={"0.8rem"}
        />
      </p>
    ),
    children: [
      { field: "tradeRate" },
      { field: "rentalRate" },
      { field: "monthlyRentalRate" },
      { field: "totalRate" },
    ],
  },
];

const GuestGridWrapper = styled.div`
  width: 100%;
  height: 80vh;
  .MuiDataGrid-columnHeadersInner > div:nth-child(1) {
    & .MuiDataGrid-columnHeader:nth-child(2n + 3) {
      /* background-color: ${color.grid.background.monthlyCell}; */
    }
  }

  .header-other-count {
    border-left: 4px double #f0f0f0;
    border-right: 4px double #f0f0f0;
  }
  .other-trade-count {
    border-left: 4px double #f0f0f0;
  }
  .other-total-count,
  .other-buying-total-count,
  .header-other-buying-count,
  .header-wdb-count-allowed {
    border-right: 4px double #f0f0f0;
  }

  .wdb-count {
    border-right: 4px double #f0f0f0;
    background-color: ${color.grid.background.tradeCell};
  }

  .rate {
    background-color: ${color.grid.background.tradeCell};
  }

  /* .MuiDataGrid-row--lastVisible > div:first-child {
    justify-content: center;
  } */

  & div [data-id="total"] {
    font-weight: bold;
  }
`;

const Secondary = styled.span`
  font-weight: 500;
`;

const Red = styled.span`
  color: ${color.trade};
`;
