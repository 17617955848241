import { SelectOption } from "./../interface/CommonInterface";
import { SearchType } from "./../interface/SalesHolding";

import { districtFetch } from "../store/api/commonApi";
import { DistrictResponse, DistrictType } from "../interface/District";

export function tradeTypeToString(tradeType: string) {
  switch (tradeType) {
    case "TRADE":
      return "매매";
    case "RENTAL":
      return "전세";
    case "MONTHLY_RENTAL":
      return "월세";
    default:
      return "";
  }
}

export function getDistrictSelectOptions(
  districtType: SearchType,
  data: DistrictResponse[]
) {
  const options: SelectOption[] = [];

  switch (districtType) {
    default:
      break;
    case "SIDO":
      options.push({ value: "전체", label: "전체" });
      break;
    case "SIGUNGU":
      options.push({ value: "전체", label: "전체" });
      data.map((item: DistrictResponse) => {
        options.push({
          value: item.sigungu.code,
          label: item.sigungu.name,
        });
      });
      break;
    case "BJDONG":
      options.push({ value: "전체", label: "전체" });
      data.map((item: DistrictResponse) => {
        options.push({
          value: item.bjdong.code,
          label: item.bjdong.name,
        });
      });
      break;
  }

  return options;
}

export const getDistrictOptions = async (
  districtType: DistrictType,
  totalCode: string
) => {
  const res = await districtFetch(districtType, totalCode);

  return getDistrictSelectOptions(districtType, res.data?.data.data);
};
