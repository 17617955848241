import { prefixZeroFomatter } from "./numberUtils";

export const getSelectableMonthList = (year?: number, max?: number) => {
  const now = new Date();
  const [currentYear, currentMonth] = [
    now.getFullYear(),
    max || now.getMonth() + 1,
  ];

  const getMaxCount = () => {
    if (year === currentYear) return currentMonth;
    return 12;
  };

  return [...Array(getMaxCount())].map((it, index) => index + 1);
};

export const formattedDate = (
  selectedYear: number,
  selectedMonth: number,
  selectedDay?: number
): string => {
  const result = `${selectedYear}-${prefixZeroFomatter(selectedMonth)}`;
  if (selectedDay) {
    return `${result}-${prefixZeroFomatter(selectedDay)}`;
  }
  return result;
};

export const getEndOfMonth = (year: number, month: number) => {
  const lastDate = new Date(year, month, 0).getDate();
  return `${year}-${month}-${lastDate}`;
};

export const getFormattedMonthAndDate = (targetDate: string) => {
  const [year, month, date] = targetDate.split("-");
  return `${month}월 ${date}일`;
};

export const getFormattedMonth = (targetDate: string) => {
  const [year, month, date] = targetDate.split("-");
  return `${month}월`;
};

export const getFormattedYearMonth = (targetMonth: number) => {
  return `${targetMonth}월`;
};
