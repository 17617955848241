import { API_OFFER_STAT } from "./../../config";
import { get, WebResponse } from "../../module/HTTPWebClient";
import {
  SalesHoldingBjdongResponse,
  SalesHoldingChartRequest,
  SalesHoldingChartResponse,
  SalesHoldingComplexResponse,
  SalesHoldingRequest,
  SalesHoldingResponse,
} from "./../../interface/SalesHolding";

export async function getSalesHoldingApi({
  searchDate,
  searchType,
  areaCd,
  includingOfficetels,
}: SalesHoldingRequest): Promise<WebResponse<SalesHoldingBjdongResponse>> {
  try {
    const url = `${API_OFFER_STAT}`;
    const { data } = await get<{
      data: SalesHoldingBjdongResponse;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        searchDate,
        searchType,
        areaCd,
        includingOfficetels,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getSalesHoldingComplexApi({
  searchDate,
  searchType,
  areaCd,
  includingOfficetels,
}: SalesHoldingRequest): Promise<WebResponse<SalesHoldingComplexResponse>> {
  try {
    const url = `${API_OFFER_STAT}`;
    const { data } = await get<{
      data: SalesHoldingComplexResponse;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        searchDate,
        searchType,
        areaCd,
        includingOfficetels,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getSalesHoldingChartApi({
  areaCd,
  searchStartDate,
  searchEndDate,
  includingOfficetels,
}: SalesHoldingChartRequest): Promise<WebResponse<SalesHoldingChartResponse>> {
  try {
    const url = "/offers/stat/chart";
    const { data } = await get<{
      data: SalesHoldingChartResponse;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        areaCd,
        searchStartDate,
        searchEndDate,
        includingOfficetels,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}
