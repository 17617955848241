import { Box, Alert, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useQueries } from "@tanstack/react-query";
import React, { useState } from "react";
import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AreaBreadCrumb from "../components/AreaBreadCrumb";
import { ContentBox } from "../components/ContentBox";
import WdbPaperContainer from "../components/WdbPaperContainer";
import SelectGroup from "../components/select/SelectGroup";
import WdbSelect from "../components/select/WdbSelect";
import WdbTooltip from "../components/tooltip/WdbTooltip";
import GuestChart from "../components/trade/content/GuestChart";
import GuestChartTradeTypeButtonGroup from "../components/trade/content/GuestChartTradeTypeButtonGroup";
import { GuestGrid } from "../components/trade/content/GuestGrid";
import { useGuest } from "../containers/guest/hooks/useGuest";
import {
    getFormattedGuestDailyChartData,
    getFormattedGuestMonthlyChartData,
} from "../containers/guest/utils/chartResourceFormatter";
import { getFormattedGuestData } from "../containers/guest/utils/tableResourceFormatter";
import useAreaController from "../hook/useAreaController";
import useRangePickerController from "../hook/useRangePickerController";
import {
    selectableDateFilterOptionList,
    selectableSidoOptionList,
} from "../static/optionList";
import { getGuestApi, getGuestChartApi } from "../store/api/guestApi";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ko";

export type GuestTabType = "TABLE_TAB" | "CHART_TAB";
const tabs: { label: string; value: GuestTabType }[] = [
    {
        label: "표",
        value: "TABLE_TAB",
    },
    {
        label: "그래프",
        value: "CHART_TAB",
    },
];

export function GuestPage() {
    const [startChartDate, setStartChartDate] = useState<Dayjs | null>(
        dayjs().subtract(1, "M")
    );
    const [endChartDate, setEndChartDate] = useState<Dayjs | null>(dayjs());
    const { getCompanyGroupData, chartInputs, onClickTradeTypeButton } =
        useGuest();

    const {
        selectedRangePickerFilter,
        onChangeRangePickerFilter,
        rangePickerValue,
        onChangeRangePicker,
        startDate,
        endDate,
        selectedDate,
        handleDateChange1,
        handleDateChange2,
    } = useRangePickerController(dayjs("2022-08-01", "YYYY-MM-DD"));
    const {
        selectableSigunguList,
        selectedSido,
        selectedSigungu,

        resetSigungu,

        onChangeSido,
        onChangeSigungu,
    } = useAreaController();

    const [tabValue, setTabValue] = useState<GuestTabType>("TABLE_TAB");
    const handleTabChange = (
        event: React.SyntheticEvent,
        newValue: GuestTabType
    ) => {
        setTabValue(newValue);
    };

    const disabledDate = (day: Dayjs) => {
        const startDate = dayjs("2022-08-01", "YYYY-MM-DD");
        const endDate = dayjs().subtract(1, "d");

        return day < startDate || day > endDate;
    };

    const results = useQueries({
        queries: [
            {
                queryKey: [
                    "getGuestData",
                    startDate,
                    endDate,
                    selectedSido,
                    selectedSigungu,
                    rangePickerValue,
                ],
                queryFn: async () => {
                    const areaCd =
                        selectedSigungu !== "전체"
                            ? selectedSigungu
                            : selectedSido;
                    const response = await getGuestApi({
                        startDate,
                        endDate,
                        areaCd,
                    });
                    if (response.error) return;

                    return response;
                },
                refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
                retry: 0, // 실패시 재호출 몇번 할지
            },
            {
                queryKey: [
                    "getGuestChartData",
                    selectedDate,
                    selectedSido,
                    selectedSigungu,
                    startChartDate,
                    endChartDate,
                ],
                queryFn: async () => {
                    const areaCd =
                        selectedSigungu !== "전체"
                            ? selectedSigungu
                            : selectedSido;

                    const startYearMonth = startChartDate?.format("YYYY-MM");
                    const endYearMonth = endChartDate?.format("YYYY-MM");
                    const response = await getGuestChartApi({
                        startYearMonth,
                        endYearMonth,
                        areaCd,
                    });
                    if (response.error) return;
                    return response;
                },
                refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
                retry: 0, // 실패시 재호출 몇번 할지
            },
        ],
    });
    const gridData = results[0];
    const chartData = results[1];
    const minDate = dayjs("2022-08-01");
    const maxDate = dayjs().endOf("month");

    return (
        <GuestWrapper>
            <WdbPaperContainer>
                <SelectGroup
                    label={
                        <>
                            <span>기간 </span>
                            <WdbTooltip title='2022-08-01 ~ 어제까지 조회 가능' />
                        </>
                    }
                >
                    {tabValue === "TABLE_TAB" ? (
                        <>
                            <WdbSelect
                                label={"기간"}
                                value={selectedRangePickerFilter}
                                onChange={onChangeRangePickerFilter}
                            >
                                {selectableDateFilterOptionList.map(
                                    (option) => (
                                        <MenuItem value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    )
                                )}
                            </WdbSelect>

                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale='ko'
                                localeText={{ start: "시작일", end: "종료일" }}
                            >
                                <DateRangePicker
                                    value={rangePickerValue}
                                    onChange={onChangeRangePicker}
                                    renderInput={(startProps, endProps) => (
                                        <React.Fragment>
                                            <TextField
                                                {...startProps}
                                                size='small'
                                            />
                                            <Box sx={{ mx: 2 }}> ~ </Box>
                                            <TextField
                                                {...endProps}
                                                size='small'
                                            />
                                        </React.Fragment>
                                    )}
                                    shouldDisableDate={disabledDate}
                                    inputFormat={"YYYY-MM-DD"}
                                    mask={"____-__-__"} // warning 방지
                                />
                            </LocalizationProvider>
                        </>
                    ) : (
                        <>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"ko"}
                            >
                                <DatePicker
                                    label={"년-월"}
                                    views={["year", "month"]}
                                    value={startChartDate}
                                    onChange={(newValue) =>
                                        setStartChartDate(newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} size='small' />
                                    )}
                                    minDate={endChartDate?.subtract(1, "year")}
                                    maxDate={maxDate}
                                    inputFormat={"YYYY-MM"}
                                />
                            </LocalizationProvider>
                            ~
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"ko"}
                            >
                                <DatePicker
                                    label={"년-월"}
                                    views={["year", "month"]}
                                    value={endChartDate}
                                    onChange={(newValue) =>
                                        setEndChartDate(newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} size='small' />
                                    )}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    inputFormat={"YYYY-MM"}
                                />
                            </LocalizationProvider>
                        </>
                    )}
                </SelectGroup>
                <SelectGroup
                    label={
                        <>
                            <span>지역 </span>
                            <WdbTooltip title='손님이 원하는 지역 기준' />
                        </>
                    }
                >
                    <WdbSelect
                        label={"시/도"}
                        value={selectedSido}
                        onChange={onChangeSido}
                    >
                        {selectableSidoOptionList.map((option) => (
                            <MenuItem value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </WdbSelect>
                    <WdbSelect
                        label={"시/군/구"}
                        value={selectedSigungu}
                        onChange={onChangeSigungu}
                    >
                        {selectableSigunguList.map((option) => (
                            <MenuItem value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </WdbSelect>
                </SelectGroup>
            </WdbPaperContainer>

            <ContentBox>
                <GuestTap value={tabValue} onChange={handleTabChange}>
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                        />
                    ))}
                </GuestTap>
                <AreaBreadCrumb
                    sidoSelect={selectableSidoOptionList.find(
                        (option) => option.value === selectedSido
                    )}
                    sigunguSelect={selectableSigunguList.find(
                        (option) => option.value === selectedSigungu
                    )}
                    onClickSigunguXButton={resetSigungu}
                />

                {tabValue === "TABLE_TAB" && (
                    <>
                        {selectedSido !== "전체" ? (
                            <GuestGrid
                                gridData={getFormattedGuestData(
                                    gridData.data?.data.responses
                                )}
                                getCompanyGroupData={getCompanyGroupData}
                                startDate={startDate}
                                endDate={endDate}
                                loading={gridData.isLoading}
                            />
                        ) : (
                            <Alert severity='error'>
                                시도(시군구 : 전체) 단위의 기간 조회만
                                가능합니다.
                            </Alert>
                        )}
                    </>
                )}

                {tabValue === "CHART_TAB" && (
                    <>
                        {selectedSido !== "전체" ? (
                            <>
                                <GuestChartTradeTypeButtonGroup
                                    chartInputs={chartInputs}
                                    onClickTradeTypeButton={
                                        onClickTradeTypeButton
                                    }
                                />
                                <GuestChart
                                    monthlyChartData={getFormattedGuestMonthlyChartData(
                                        chartData.data?.data.monthlyCount
                                    )}
                                    dailyChartData={getFormattedGuestDailyChartData(
                                        chartData.data?.data.dailyCount
                                    )}
                                    areaName={chartData.data?.data.area.name}
                                    chartInputs={chartInputs}
                                />
                            </>
                        ) : (
                            <Alert severity='error'>
                                시도(시군구 : 전체) 단위의 기간 조회만
                                가능합니다.
                            </Alert>
                        )}
                    </>
                )}
            </ContentBox>
        </GuestWrapper>
    );
}

export const GuestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const GuestTap = styled(Tabs)({
    marginBottom: "15px",
});
