import { Button, InputAdornment, OutlinedInput } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";
import { TradeSalesSizeRequestUserInput } from "../../../../interface/TradeSales";
import { font } from "../../../../styles/font";

export interface TotalCommisionInputsProps {
  inputs: TradeSalesSizeRequestUserInput;
  setInputs: React.Dispatch<
    React.SetStateAction<TradeSalesSizeRequestUserInput>
  >;
  salesRate: number;
  onClick: () => void;
}

function TotalCommisionInputs({
  inputs,
  setInputs,
  salesRate,

  onClick,
}: TotalCommisionInputsProps) {
  const {
    bothRate,
    monthlyMarketShare,
    rentalMarketShare,
    tradeMarketShare,
    wdbDiscountRate,
  } = inputs;

  return (
    <Wrapper>
      <InputGroup label={<GuideText>[우대빵 거래 점유율(목표값)]</GuideText>}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">매매</InputAdornment>
          }
          size="small"
          type="number"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (
              Number(event.currentTarget.value) < 0 ||
              Number(event.currentTarget.value) > 100
            )
              return;

            setInputs({
              ...inputs,
              tradeMarketShare: Number(event.currentTarget.value),
            });
          }}
          value={tradeMarketShare}
          endAdornment={"%"}
        />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">전세</InputAdornment>
          }
          size="small"
          type="number"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (
              Number(event.currentTarget.value) < 0 ||
              Number(event.currentTarget.value) > 100
            )
              return;
            setInputs({
              ...inputs,
              rentalMarketShare: Number(event.currentTarget.value),
            });
          }}
          value={rentalMarketShare}
          endAdornment={"%"}
        />
        <OutlinedInput
          id="standard-adornment-amount"
          startAdornment={
            <InputAdornment position="start">월세</InputAdornment>
          }
          size="small"
          type="number"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (
              Number(event.currentTarget.value) < 0 ||
              Number(event.currentTarget.value) > 100
            )
              return;
            setInputs({
              ...inputs,
              monthlyMarketShare: Number(event.currentTarget.value),
            });
          }}
          value={monthlyMarketShare}
          endAdornment={"%"}
        />
      </InputGroup>

      <InputGroup label={<GuideText>[우대빵 양타 비율]</GuideText>}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">우대빵 양타비율</InputAdornment>
          }
          size="small"
          type="number"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (
              Number(event.currentTarget.value) < 0 ||
              Number(event.currentTarget.value) > 100
            )
              return;
            setInputs({
              ...inputs,
              bothRate: Number(event.currentTarget.value),
            });
          }}
          value={bothRate}
          endAdornment={"%"}
        />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">매출 비율</InputAdornment>
          }
          size="small"
          type="number"
          value={salesRate * 100}
          endAdornment={"%"}
          disabled
        />
      </InputGroup>
      <InputGroup label={<GuideText>[우대빵 할인율]</GuideText>}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">우대빵 할인율</InputAdornment>
          }
          size="small"
          type="number"
          value={wdbDiscountRate}
          endAdornment={"%"}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (
              Number(event.currentTarget.value) < 0 ||
              Number(event.currentTarget.value) > 100
            )
              return;
            setInputs({
              ...inputs,
              wdbDiscountRate: Number(event.currentTarget.value),
            });
          }}
        />

        <Button variant="contained" onClick={onClick}>
          조회
        </Button>
      </InputGroup>
    </Wrapper>
  );
}

export default TotalCommisionInputs;

interface InputGroupProps {
  label: ReactElement;
}
function InputGroup({ label, children }: PropsWithChildren<InputGroupProps>) {
  return (
    <div>
      {label}
      <InputGroupWrapper>{children}</InputGroupWrapper>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
  .MuiOutlinedInput-input {
    text-align: right;
  }
`;

const GuideText = styled.div`
  font-size: ${font.px12};
  margin-bottom: 8px;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 8px;
  & .ant-input-number-input-wrap {
    & input {
      text-align: right;
    }
  }
  & .ant-input-number-handler-wrap {
    display: none !important;
  }
  @media screen and (max-width: 1660px) {
    & .ant-input-number-input-wrap {
      width: 120px;
    }
  }
  @media screen and (max-width: 1400px) {
    & .ant-input-number-input-wrap {
      width: 60px;
    }
  }
`;
