import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useBreadCrumb } from "../hook/useMenu";
import useSelectorTyped from "../store/useSelectorTyped";

const MainPanelWrapper = styled.div<{ isMenu: boolean }>`
  padding: 20px;
`;

export function MainPanel() {
  const { isMenu } = useSelectorTyped((state) => ({
    isMenu: state.menuSlice.isMenu,
  }));

  const { breadCrumb, setBreadCrumb } = useBreadCrumb();

  const location = useLocation();

  switch (location.pathname) {
    default:
      setBreadCrumb([]);
      break;
    case "/trading":
      setBreadCrumb(["거래량", "거래량"]);
      break;
  }

  return (
    <>
      <MainPanelWrapper isMenu={isMenu}>
        <Outlet />
      </MainPanelWrapper>
    </>
  );
}
