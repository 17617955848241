import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";

function useRangeController(defaultYear?: number, defaultMonth?: number) {
  const currentYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(defaultYear || currentYear);
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);

  const onChangeYear = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    const selectedValue = Number(event.target.value);
    setSelectedYear(selectedValue);
    //년도 변경시 월 초기화
    setSelectedMonth(defaultMonth);
  };

  const onChangeMonth = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    setSelectedMonth(Number(event.target.value));
  };

  return {
    selectedYear,
    selectedMonth,

    onChangeYear,
    onChangeMonth,
  };
}

export default useRangeController;
