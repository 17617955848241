import {
  TradeTypeValue,
  TransactionResponse,
  TransactionResponseList,
  TransactionResponseListMonthlyColumn,
  TransactionResponseListRentalColumn,
  TransactionResponseListTradeColumn,
} from "./../../../interface/Transaction";

export const getFormattedTransactionTradeData = (
  responseDataList: TransactionResponse | undefined
): TransactionResponseListTradeColumn[] => {
  const result = responseDataList?.list.map(
    (
      {
        sigunguName,
        bunji,
        complexName,
        privateArea,
        contractDate,
        price,
        floor,
      }: TransactionResponseList,
      index: number
    ) => {
      return {
        id: index,
        sigunguName,
        bunji,
        complexName,
        privateArea,
        contractDate,
        price,
        floor,
      };
    }
  );

  if (result) {
    return result;
  } else {
    return [];
  }
};

export const getFormattedTransactionRentalData = (
  responseDataList: TransactionResponse | undefined
): TransactionResponseListRentalColumn[] => {
  const result = responseDataList?.list.map(
    (
      {
        sigunguName,
        bunji,
        complexName,
        privateArea,
        contractDate,
        price,
        floor,
        contractType,
        previousContractDeposit,
        previousContractMonthlyPrice,
        contractStartYearMonth,
        contractEndYearMonth,
      }: TransactionResponseList,
      index: number
    ) => {
      return {
        id: index,
        sigunguName,
        bunji,
        complexName,
        privateArea,
        contractDate,
        price,
        floor,
        contractType,
        previousContractDeposit,
        previousContractMonthlyPrice,
        contractStartEnd: getFormattedContractStartEnd(
          contractStartYearMonth,
          contractEndYearMonth
        ),
      };
    }
  );

  if (result) {
    return result;
  } else {
    return [];
  }
};

export const getFormattedTransactionMonthlyData = (
  responseDataList: TransactionResponse | undefined
): TransactionResponseListMonthlyColumn[] => {
  const result = responseDataList?.list.map(
    (
      {
        sigunguName,
        bunji,
        complexName,
        privateArea,
        contractDate,
        price,
        floor,
        contractType,
        previousContractDeposit,
        previousContractMonthlyPrice,
        contractStartYearMonth,
        contractEndYearMonth,
        monthlyPrice,
      }: TransactionResponseList,
      index: number
    ) => {
      return {
        id: index,
        sigunguName,
        bunji,
        complexName,
        privateArea,
        contractDate,
        price,
        floor,
        contractType,
        previousContractDeposit,
        previousContractMonthlyPrice,
        contractStartEnd: getFormattedContractStartEnd(
          contractStartYearMonth,
          contractEndYearMonth
        ),
        monthlyPrice,
      };
    }
  );

  if (result) {
    return result;
  } else {
    return [];
  }
};

const getFormattedContractStartEnd = (
  contractStartYearMonth: string | null,
  contractEndYearMonth: string | null
) => {
  if (contractStartYearMonth && contractEndYearMonth) {
    return `${contractStartYearMonth} ~ ${contractEndYearMonth}`;
  } else {
    return "-";
  }
};
