import dayjs, { Dayjs } from "dayjs";
import { SelectChangeEvent } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import { useEffect, useState } from "react";
import { SelectedGraphDateType } from "../interface/Guest";

const DEFAULT_FILTER = "last30days";
const DEFAULT_END_MOMENT = dayjs().subtract(1, "day");
export interface RangePickerControllerProps {
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
}

function useRangePickerController(
  defaultStartDate?: Dayjs | null,
  defaultEndDate?: Dayjs | null
) {
  const [selectedRangePickerFilter, setSelectedRangePickerFilter] =
    useState(DEFAULT_FILTER);

  const [rangePickerValue, setRangePickerValue] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "M"),
    DEFAULT_END_MOMENT,
  ]);

  const startDate =
    rangePickerValue[0] && rangePickerValue[0].format("YYYY-MM-DD");
  const endDate =
    rangePickerValue[1] && rangePickerValue[1].format("YYYY-MM-DD");

  const [selectedDate, setSelectedDate] = useState<SelectedGraphDateType>({
    startYear: dayjs().year(),
    startMonth: dayjs().month() - 1,
    lastYear: dayjs().year(),
    lastMonth: dayjs().month(),
  });

  const handleDateChange1 = (date: any) => {
    setSelectedDate((prevState: SelectedGraphDateType) => ({
      ...prevState,
      startYear: date.year(),
      startMonth: date.month(),
    }));
  };

  const handleDateChange2 = (date: any) => {
    setSelectedDate((prevState: SelectedGraphDateType) => ({
      ...prevState,
      lastYear: date.year(),
      lastMonth: date.month(),
    }));
  };

  useEffect(() => {
    switch (selectedRangePickerFilter) {
      default:
        break;
      case "last30days":
        setRangePickerValue([
          defaultStartDate && defaultStartDate > dayjs().subtract(1, "M")
            ? defaultStartDate
            : dayjs().subtract(1, "M"),
          dayjs().subtract(1, "day"),
        ]);
        break;
      case "last180days":
        setRangePickerValue([
          defaultStartDate && defaultStartDate > dayjs().subtract(0.5, "y")
            ? defaultStartDate
            : dayjs().subtract(0.5, "y"),
          dayjs().subtract(1, "day"),
        ]);
        break;
      case "last365days":
        setRangePickerValue([
          defaultStartDate && defaultStartDate > dayjs().subtract(1, "y")
            ? defaultStartDate
            : dayjs().subtract(1, "y"),
          dayjs().subtract(1, "day"),
        ]);
        break;
      case "thisMonth":
        setRangePickerValue([dayjs().startOf("M"), dayjs()]);
        break;
      case "lastMonth":
        setRangePickerValue([
          dayjs().subtract(1, "M").startOf("M"),
          dayjs().subtract(1, "M").endOf("M"),
        ]);
        break;
    }
  }, [selectedRangePickerFilter, selectedDate]);

  const onChangeRangePickerFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectedRangePickerFilter(event.target.value as string);
  };

  const onChangeRangePicker = (newValue: DateRange<Dayjs | null>) => {
    setRangePickerValue(newValue);
  };

  return {
    selectedRangePickerFilter,
    onChangeRangePickerFilter,
    rangePickerValue,
    onChangeRangePicker,
    startDate,
    endDate,

    selectedDate,
    handleDateChange1,
    handleDateChange2,
  };
}

export default useRangePickerController;
