import { CompanyGroupRequest } from "./../../../interface/CommonRequest";
import { useState } from "react";
import { getGuestCompanyGroupApi } from "../../../store/api/guestApi";
export interface tradeTypeInputs {
  [K: string]: boolean;
  total: boolean;
  trade: boolean;
  rental: boolean;
  monthlyRental: boolean;
}

export function useGuest() {
  const [chartInputs, setChartInputs] = useState<tradeTypeInputs>({
    total: true,
    trade: true,
    rental: true,
    monthlyRental: true,
  });

  const onClickTradeTypeButton = (value: string) => {
    setChartInputs({
      ...chartInputs,
      [value]: !chartInputs[value],
    });
  };

  const getCompanyGroupData = async (params: CompanyGroupRequest) => {
    const response = await getGuestCompanyGroupApi(params);
    if (response.error) return;
    return response.data;
  };

  return {
    getCompanyGroupData,

    chartInputs,
    onClickTradeTypeButton,
  };
}
