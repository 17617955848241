import React, { useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import { getManagerDailyChartData } from "../store/api/managerDataApi";
import { getManagerOfBranchList } from "../store/api/managerDataApi";
import { ChartData } from "../interface/chartFormatter";
import { BranchInfo } from "../interface/Branch";
import {
  MamagerOfBranchRequest,
  ManagerChartRequest,
  ManagerDataColumn,
} from "../interface/Manager";
import {
  ManagerChartTradeType,
  ManagerTradeType,
} from "../interface/CommonInterface";
import {
  getFormattedManagerbuyingPropertyChartData,
  getFormattedManagerOfferPropertyChartData,
  getFormattedManagerVisitPropertyChartData,
} from "../containers/manager/utils/chartResourceFormatter";

function useManager() {
  const [selectableBranchList, setSelectableBranchList] = useState<
    BranchInfo[]
  >([]);
  const [apiManagerDataList, setApiManagerDataList] = useState<
    ManagerDataColumn[]
  >([]);

  const [selectedValue, setSelectedValue] = React.useState("일간별");
  const toggleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const [selectedBranchNo, setSelectedBranchNo] = useState<number | undefined>(
    0
  );
  const [selectedManagerNo, setManagerNo] = useState<number | undefined>(0);
  const [selectedManagerAccountInfoNo, setManagerAccountInfoNo] = useState({});
  const [selectedManagerList, setManagerList] = useState<
    MamagerOfBranchRequest[]
  >([
    {
      accountInfoNo: 0,
      accountName: "선택",
    },
  ]);

  const handleManagerOptionList = (managerList: {
    data: MamagerOfBranchRequest[];
  }) => {
    const modifiedManagerList: MamagerOfBranchRequest[] = [
      {
        accountInfoNo: 0,
        accountName: "선택",
      },
      ...managerList.data,
    ];

    setManagerList(modifiedManagerList);
  };

  const handleBranchList = (branchList: { data: BranchInfo[] }) => {
    const modifiedBranchList: BranchInfo[] = [
      {
        companyGroupName: "전체",
        companyGroupNo: 0,
      },
      ...branchList.data,
    ];
    setSelectableBranchList(modifiedBranchList);
  };

  const onChangeManagerOfBranch = async (event: SelectChangeEvent<unknown>) => {
    const selectedBranchNo = event.target.value as number;
    const resultManagerOfBranchList = await getManagerOfBranchList(
      selectedBranchNo
    );
    setManagerAccountInfoNo({});
    setManagerNo(0);
    setSelectedBranchNo(selectedBranchNo);
    if (resultManagerOfBranchList.error) return;
    handleManagerOptionList(resultManagerOfBranchList);
    setManagerNo(0);
  };

  const onChangeBranch = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    setSelectedBranchNo(event.target.value as number);
  };

  // 기간조회 탭 관련
  const INIT_TRADE_TYPE: { [tradeType in ManagerTradeType]: boolean } = {
    first: true,
    second: true,
    third: true,
    fourth: true,
  };

  const [chartTradeTypeList, setChartTradeTypeList] =
    useState<ManagerChartTradeType>(INIT_TRADE_TYPE);

  const [resultManagerChartData, setResultManagerChartData] = useState<any>();
  const [managerOfferChartData, setmanagerOfferChartData] = useState<ChartData>(
    getFormattedManagerOfferPropertyChartData(resultManagerChartData)
  );
  const [buyingPropertyChartData, setBuyingPropertyChartData] =
    useState<ChartData>(
      getFormattedManagerbuyingPropertyChartData(resultManagerChartData)
    );

  const [visitPropertyChartData, setVisitPropertyChartData] =
    useState<ChartData>(
      getFormattedManagerVisitPropertyChartData(resultManagerChartData)
    );
  const getManagerChartData = async (params: ManagerChartRequest) => {
    const response = await getManagerDailyChartData(params);
    if (response.error) return;
    setResultManagerChartData(response);
  };

  return {
    selectedValue,
    selectedManagerList,
    selectedBranchNo,
    selectedManagerNo,
    selectedManagerAccountInfoNo,
    selectableBranchList,

    apiManagerDataList,
    setApiManagerDataList,
    setManagerList,
    setManagerAccountInfoNo,
    setManagerNo,
    setSelectedBranchNo,
    handleManagerOptionList,

    handleBranchList,
    toggleRadio,

    onChangeBranch,
    onChangeManagerOfBranch,

    // 기간조회
    chartTradeTypeList,
    getManagerChartData,
    resultManagerChartData,
    setmanagerOfferChartData,
    managerOfferChartData,
    buyingPropertyChartData,
    setBuyingPropertyChartData,
    visitPropertyChartData,
    setVisitPropertyChartData,
  };
}

export default useManager;
