import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  ButtonProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import styled from "styled-components";
import { GridCaption } from "../../../components/GridCaption";
import { FlexWrapper } from "../../../components/atoms/layout/FlexWrapper";
import {
  AssignedDistrictTabContentProps,
  BranchAssignedDistrictSummaryDto,
} from "../../../interface/Branch";
import {
  getEndOfMonth,
  getFormattedMonthAndDate,
} from "../../../utils/dateUtils";
import { COMMON_COLUMNS_OPTIONS } from "../../brokerageCommissionSize/utils/tableResourceFormatter";
import {
  TOTAL_HOLD_OFFER,
  TOTAL_NAME,
  TOTAL_NEW_NAME,
  TOTAL_OFFER,
  getFormattedBranchAssignedDistrictSummaryDataRow,
  getTreeDataPathOfAssignedDistrictSummary,
} from "../utils/assignedDistrictTableResourceFormatter";

function AssignedDistrictTabContent({
  year,
  summaryData,
  averageCommission,
  averageContractCount,
  startAssignDate,
  lastAssignDate,
}: AssignedDistrictTabContentProps) {
  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "",
    minWidth: 260,
    renderCell: (params) => {
      if (params.formattedValue === TOTAL_NAME) {
        return <CustomGridTreeDataGroupingCell {...params} />;
      }
      // 전체 (+)
      if (params.formattedValue === TOTAL_NEW_NAME) {
        return <CustomGridTreeDataGroupingCell {...params} />;
      }
      // 매물 합계 (+)
      if (params.formattedValue === TOTAL_OFFER) {
        return <CustomGridTreeDataGroupingCell {...params} />;
      }
      // 매물 합계 보유율 (+)
      if (params.formattedValue === TOTAL_HOLD_OFFER) {
        return <CustomGridTreeDataGroupingCell {...params} />;
      }
      if (!params.row.hierarchy || params.row.hierarchy.length === 1) {
        return params.formattedValue;
      }
      return (
        <span style={{ paddingLeft: "40px" }}>{params.formattedValue}</span>
      );
    },
  };

  const columns: GridColDef[] = [
    ...Array.from({ length: 12 }, (_, index) => {
      const month = index + 1;
      return {
        field: `${getFormattedMonthAndDate(getEndOfMonth(year, month))}`,
        flex: 1 / 12,
      };
    }),
  ];

  const formattedColumns: GridColDef[] = columns.map((item) => {
    const result: GridColDef = {
      ...item,
      ...COMMON_COLUMNS_OPTIONS,
      align: item.align ?? COMMON_COLUMNS_OPTIONS.align,
      headerAlign: item.headerAlign ?? COMMON_COLUMNS_OPTIONS.headerAlign,
    };
    return result;
  });

  return (
    <AssignedDistrictTabContentWrapper>
      <FlexWrapper justifyContent="space-between">
        <AverageDataWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="title">관할구역 포함 기간</TableCell>
                <TableCell>
                  {startAssignDate} ~ {lastAssignDate}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="title">월 평균 계약 건수</TableCell>
                <TableCell>{averageContractCount}건</TableCell>
                <TableCell className="title">
                  월 평균 매출(가계약일 기준)
                </TableCell>
                <TableCell>
                  {Math.round(averageCommission * 1.1).toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AverageDataWrapper>
        <GridCaption textAlign="right">(단위 : 원 (VAT포함))</GridCaption>
      </FlexWrapper>
      <div style={{ height: "978px", width: "100%" }}>
        <DataGridPro
          experimentalFeatures={{ columnGrouping: true }}
          rows={getFormattedBranchAssignedDistrictSummaryDataRow(
            year,
            summaryData,
            formattedColumns
          )}
          rowHeight={40}
          groupingColDef={groupingColDef}
          columns={formattedColumns}
          disableSelectionOnClick
          treeData
          getTreeDataPath={getTreeDataPathOfAssignedDistrictSummary} // hierarchy를 자체를 보여준다.
          defaultGroupingExpansionDepth={1}
          hideFooter
        />
      </div>
    </AssignedDistrictTabContentWrapper>
  );
}

export default AssignedDistrictTabContent;

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const [opened, setOpened] = useState(false);
  const { id, field, rowNode, formattedValue } = props;
  const apiRef = useGridApiContext();

  const handleClick: ButtonProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
    setOpened(!opened);
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        <Accordion onClick={handleClick} tabIndex={-1}>
          {opened ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
          {formattedValue}
        </Accordion>
      </div>
    </Box>
  );
}

const AverageDataWrapper = styled.div`
  display: flex;
  gap: 24px;
  font-size: 1rem;
  padding: 12px 0;
  & table * {
    border: none;
  }
  & td {
    padding: 4px 12px 4px 0px;
    min-width: 80px;
  }
  & .title {
    font-weight: 700;
  }
  & .MuiButtonBase-root {
    font-weight: 500 !important;
    color: black !important;
  }
`;

const Accordion = styled.button`
  border: none;
  display: flex;
  align-items: center;
  background-color: inherit;
  cursor: pointer;
`;

const AssignedDistrictTabContentWrapper = styled.div``;
