import { transactionSlice } from "./temp/TransactionR";
import { menuSlice } from "./menuR";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    menuSlice: menuSlice.reducer,
    transactionSlice: transactionSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
