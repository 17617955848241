import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { MenuList } from "../components/menu/MenuList";
import { Link } from "react-router-dom";
import { useMenu } from "../hook/useMenu";
import { useEffect, useState } from "react";
import { font } from "../styles/font";
import { color } from "../styles/color";
import { devMenu, productionMenu } from "../static/menu";
import WdbSmallLogoIcon from "../assets/WdbSmallLogoIcon";
import Overlay from "./Overlay";

export function SideMenu() {
  const { isMenu, setMenu } = useMenu();
  const [menuOn, setMenuOn] = useState("");
  const [onOverlay, setOverlay] = useState("");
  useEffect(() => {
    if (isMenu) {
      setMenuOn("menu-on");
      setOverlay("overlay-on");
    } else {
      setMenuOn("");
      setOverlay("");
    }
  }, [isMenu]);

  const menu = process.env.NODE_ENV === "production" ? productionMenu : devMenu;
  return (
    <>
      <SideMenuWrapper isMenu={isMenu} className={menuOn}>
        <div className="logo">
          <div className="logo-left">
            <Link to="/">
              <WdbSmallLogoIcon />
              <h4>우대빵 데이터센터</h4>
            </Link>
          </div>
          <div className="logo-right">
            <FontAwesomeIcon
              icon={faX}
              onClick={() => {
                setMenu(false);
              }}
            />
          </div>
        </div>

        <div className="menu-list">
          <ul>
            {menu.map((item, index: number) => {
              return (
                <>
                  <MenuList
                    // link={item.link}
                    listName={item.listName}
                    icon={item.icon}
                    isDropdown={item.isDropdown}
                    dropdownContent={item.dropdownContent}
                    key={index}
                  />
                </>
              );
            })}
          </ul>
        </div>
      </SideMenuWrapper>
      {isMenu ? <Overlay onOverlay={onOverlay} isMenu={isMenu} /> : <></>}
    </>
  );
}

const SideMenuWrapper = styled.div<{ isMenu: boolean }>`
  font-size: ${font.px16};
  color: ${(props) => props.theme.bgColor};
  background: ${(props) => props.theme.color};
  width: var(--menu-width);
  height: 90vh;
  padding: 1.5vh 1.5vw 1vh;
  position: absolute;
  top: 8vh;
  transform: translate(0, 0);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow-y: auto;
  z-index: 2;
  left: var(--menu-width-minus);

  border-radius: 10px;
  &.menu-on {
    transform: translate(var(--menu-width), 0);
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
  }

  ul {
    svg {
      margin-right: 1vw;
    }

    a:hover {
      color: white;
      cursor: pointer;
    }
  }

  li {
    padding: 2.5vh 0;
    font-size: ${font.px16};
    opacity: 0.8;
    a:hover {
      opacity: 1;
      color: ${color.antColor.daybreakBlue.blue2};
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1vh 0 1.5vh;
    font-size: ${font.px20};
    border-bottom: 1px solid white;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 1vw;
    }

    &-left {
      display: flex;
      align-items: center;

      h4 {
        font-size: 18px;
      }

      a {
        display: flex;
        align-items: center;
      }
    }

    &-right {
      cursor: pointer;
      svg {
        padding: 20px 0px;
        margin-right: 0;
      }
    }
  }

  .current {
    color: ${color.antColor.daybreakBlue.blue3};
  }

  .ant-dropdown-trigger {
    position: relative;
  }

  .overlay-link {
    background: red;
  }
`;
