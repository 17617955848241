import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

function useDatePickerController() {
  const [datePickerValue, setDatePickerValue] = useState<Dayjs | null>(
    dayjs().subtract(1, "day")
  );
  const selectedDate = datePickerValue && datePickerValue.format("YYYY-MM-DD");

  const onChangeDatePicker = (newValue: Dayjs | null) => {
    newValue ? setDatePickerValue(newValue) : setDatePickerValue(null);
  };

  return {
    datePickerValue,
    onChangeDatePicker,
    selectedDate,
  };
}

export default useDatePickerController;
