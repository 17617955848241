import { useDispatch } from "react-redux";
import {
  settingBreadcrumb,
  settingDarkMode,
  settingIsDropdown,
  settingMenu,
} from "../store/menuR";
import useSelectorTyped from "../store/useSelectorTyped";

export function useMenu() {
  const dispatch = useDispatch();
  const isMenu = useSelectorTyped((state) => state.menuSlice.isMenu);
  const setMenu = (type: boolean) => {
    dispatch(settingMenu(type));
  };

  return { isMenu, setMenu };
}

export function useDarkMode() {
  const dispatch = useDispatch();
  const isDarkMode = useSelectorTyped((state) => state.menuSlice.isDark);
  const setDarkMode = (type: boolean) => {
    dispatch(settingDarkMode(type));
  };

  return { isDarkMode, setDarkMode };
}

export function useBreadCrumb() {
  const dispatch = useDispatch();
  const breadCrumb = useSelectorTyped((state) => state.menuSlice.breadCrumb);
  const setBreadCrumb = (type: string[]) => {
    dispatch(settingBreadcrumb(type));
  };

  return { breadCrumb, setBreadCrumb };
}

export function useIsDropdown() {
  const dispatch = useDispatch();
  const isDropdown = useSelectorTyped((state) => state.menuSlice.isDropdown);
  const setIsDropdown = (type: boolean) => {
    dispatch(settingIsDropdown(type));
  };

  return { isDropdown, setIsDropdown };
}
