import { Alert, Divider, Typography } from "@mui/material";
import {
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  LegendElement,
  LegendItem,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { useEffect, useRef } from "react";
import { Chart } from "react-chartjs-2";
import styled from "styled-components";
import { AreaBreadCrumbProps } from "../../../components/AreaBreadCrumb";
import { ManagerChartTradeType } from "../../../interface/CommonInterface";
import { color } from "../../../styles/color";
import { ChartData } from "../../../interface/chartFormatter";
ChartJS.register(
  LineController,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export interface ManagerTabContentsProps {
  chartTradeTypeList: ManagerChartTradeType;
  managerOfferChartData: ChartData;
  buyingPropertyChartData: ChartData;
  visitPropertyChartData: ChartData;
  selectedManagerNo: number | undefined;
}

export type ManagerTabContentsType = ManagerTabContentsProps &
  AreaBreadCrumbProps;

const ManagerDataTabContents = ({
  chartTradeTypeList,
  managerOfferChartData,
  buyingPropertyChartData,
  visitPropertyChartData,
  selectedManagerNo,
}: ManagerTabContentsType) => {
  const chartManagerOfferRef = useRef<ChartJS<"line", number[], string>>(null);
  const chartBuyingPropertyRef =
    useRef<ChartJS<"line", number[], string>>(null);
  const chartVisitPropertyRef = useRef<ChartJS<"line", number[], string>>(null);
  const { first, second, third, fourth } = chartTradeTypeList;

  useEffect(() => {
    const legendItemsManagerOffer =
      chartManagerOfferRef.current?.legend?.legendItems;
    const chartManagerOffer = chartManagerOfferRef.current?.legend?.chart;
    const legendItemsBuyingProperty =
      chartBuyingPropertyRef.current?.legend?.legendItems;
    const chartBuyingProperty = chartBuyingPropertyRef.current?.legend?.chart;
    const legendItemsVisitProperty =
      chartVisitPropertyRef.current?.legend?.legendItems;
    const chartVisitProperty = chartVisitPropertyRef.current?.legend?.chart;

    if (
      legendItemsManagerOffer &&
      legendItemsBuyingProperty &&
      legendItemsVisitProperty
    ) {
      const totalManagerOfferItem1 = legendItemsManagerOffer[0];
      const totalBuyingPropertyItem1 = legendItemsBuyingProperty[0];
      const totalVisitPropertyItem1 = legendItemsVisitProperty[0];

      const tradeManagerOfferItem1 = legendItemsManagerOffer[1];
      const tradeBuyingPropertyItem1 = legendItemsBuyingProperty[1];
      const tradeVisitPropertyItem1 = legendItemsVisitProperty[1];

      const rentalManagerOfferItem1 = legendItemsManagerOffer[2];
      const rentalBuyingPropertyItem1 = legendItemsBuyingProperty[2];
      const rentalVisitPropertyItem1 = legendItemsVisitProperty[2];

      const monthlyRentalManagerOfferItem1 = legendItemsManagerOffer[3];
      const monthlyRentalBuyingPropertyItem1 = legendItemsBuyingProperty[3];
      const monthlyRentalVisitPropertyItem1 = legendItemsVisitProperty[3];

      const handleChartWdbShow = (item1: LegendItem) => {
        const index1 = item1.datasetIndex;

        if (index1 !== undefined) {
          chartManagerOffer?.show(index1);
          item1.hidden = false;
        }
      };

      const handleChartWdbHidden = (item1: LegendItem) => {
        const index1 = item1.datasetIndex;

        if (index1 !== undefined) {
          chartManagerOffer?.hide(index1);
          item1.hidden = true;
        }
      };

      const handleChartNaverShow = (item1: LegendItem) => {
        const index1 = item1.datasetIndex;
        if (index1 !== undefined) {
          chartBuyingProperty?.show(index1);
          item1.hidden = false;
        }
      };

      const handleChartNaverHidden = (item1: LegendItem) => {
        const index1 = item1.datasetIndex;

        if (index1 !== undefined) {
          chartBuyingProperty?.hide(index1);
          item1.hidden = true;
        }
      };

      const handleChartVisitShow = (item1: LegendItem) => {
        const index1 = item1?.datasetIndex;
        if (index1 !== undefined) {
          chartVisitProperty?.show(index1);
          item1.hidden = false;
        }
      };

      const handleChartVisitHidden = (item1: LegendItem) => {
        const index1 = item1?.datasetIndex;

        if (index1 !== undefined) {
          chartVisitProperty?.hide(index1);
          item1.hidden = true;
        }
      };

      if (legendItemsManagerOffer?.length <= 4) {
        if (first) {
          handleChartWdbShow(totalManagerOfferItem1);
          handleChartNaverShow(totalBuyingPropertyItem1);
          handleChartVisitShow(totalVisitPropertyItem1);
        } else {
          handleChartWdbHidden(totalManagerOfferItem1);
          handleChartNaverHidden(totalBuyingPropertyItem1);
          handleChartVisitHidden(totalVisitPropertyItem1);
        }

        if (second) {
          handleChartWdbShow(tradeManagerOfferItem1);
          handleChartNaverShow(tradeBuyingPropertyItem1);
          handleChartVisitShow(tradeVisitPropertyItem1);
        } else {
          handleChartWdbHidden(tradeManagerOfferItem1);
          handleChartNaverHidden(tradeBuyingPropertyItem1);
          handleChartVisitHidden(tradeVisitPropertyItem1);
        }

        if (third) {
          handleChartWdbShow(rentalManagerOfferItem1);
          handleChartNaverShow(rentalBuyingPropertyItem1);
          handleChartVisitShow(rentalVisitPropertyItem1);
        } else {
          handleChartWdbHidden(rentalManagerOfferItem1);
          handleChartNaverHidden(rentalBuyingPropertyItem1);
          handleChartVisitHidden(rentalVisitPropertyItem1);
        }

        if (fourth) {
          handleChartWdbShow(monthlyRentalManagerOfferItem1);
          handleChartNaverShow(monthlyRentalBuyingPropertyItem1);
          handleChartVisitShow(monthlyRentalVisitPropertyItem1);
        } else {
          handleChartWdbHidden(monthlyRentalManagerOfferItem1);
          handleChartNaverHidden(monthlyRentalBuyingPropertyItem1);
          handleChartVisitHidden(monthlyRentalVisitPropertyItem1);
        }
      }
    }
  }, [managerOfferChartData, buyingPropertyChartData, visitPropertyChartData]);

  return (
    <Wrapper>
      {selectedManagerNo ? (
        <>
          <Divider sx={{ my: 3 }} />
          {/* 매물 관리 상태 */}
          <Typography
            variant="h5"
            align="center"
            color={color.woodaeRed}
            fontWeight={600}
          >
            매물 관리 상태
          </Typography>
          <ChartWrapper>
            <Chart
              type="line"
              data={managerOfferChartData}
              ref={chartManagerOfferRef}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "매물 관리 상태",
                      padding: {
                        top: 0,
                        bottom: 10,
                        y: 0,
                      },
                    },
                  },
                },
                plugins: {
                  legend: {
                    onClick: (
                      e: ChartEvent,
                      legendItem: LegendItem,
                      legend: LegendElement<"line">
                    ) => {
                      const index = legendItem.datasetIndex;
                      const ci = legend.chart;
                      if (index !== undefined) {
                        if (ci.isDatasetVisible(index)) {
                          ci.hide(index);
                          legendItem.hidden = true;
                        } else {
                          ci.show(index);
                          legendItem.hidden = false;
                        }
                      }
                    },
                  },
                },
              }}
            />
          </ChartWrapper>
          <Divider sx={{ my: 3 }} />
          {/* 손님 관리 상태 */}
          <Typography
            variant="h5"
            align="center"
            color={color.woodaeRed}
            fontWeight={600}
          >
            손님 관리 상태
          </Typography>
          <ChartWrapper>
            <Chart
              type="line"
              data={buyingPropertyChartData}
              ref={chartBuyingPropertyRef}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "손님 관리 상태",
                      padding: {
                        top: 0,
                        bottom: 10,
                        y: 0,
                      },
                    },
                  },
                },
                plugins: {
                  legend: {
                    onClick: (
                      e: ChartEvent,
                      legendItem: LegendItem,
                      legend: LegendElement<"line">
                    ) => {
                      const index = legendItem.datasetIndex;
                      const ci = legend.chart;
                      if (index !== undefined) {
                        if (ci.isDatasetVisible(index)) {
                          ci.hide(index);
                          legendItem.hidden = true;
                        } else {
                          ci.show(index);
                          legendItem.hidden = false;
                        }
                      }
                    },
                  },
                },
              }}
            />
          </ChartWrapper>
          <Divider sx={{ my: 3 }} />
          {/* 임장/계약 관리 상태 */}
          <Typography
            variant="h5"
            align="center"
            color={color.woodaeRed}
            fontWeight={600}
          >
            임장 / 계약 관리 상태
          </Typography>
          <ChartWrapper>
            <Chart
              type="line"
              data={visitPropertyChartData}
              ref={chartVisitPropertyRef}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "임장/계약 관리 상태",
                      padding: {
                        top: 0,
                        bottom: 10,
                        y: 0,
                      },
                    },
                  },
                },
                plugins: {
                  legend: {
                    onClick: (
                      e: ChartEvent,
                      legendItem: LegendItem,
                      legend: LegendElement<"line">
                    ) => {
                      const index = legendItem.datasetIndex;
                      const ci = legend.chart;

                      if (index !== undefined) {
                        if (ci.isDatasetVisible(index)) {
                          ci.hide(index);
                          legendItem.hidden = true;
                        } else {
                          ci.show(index);
                          legendItem.hidden = false;
                        }
                      }
                    },
                  },
                },
              }}
            />
          </ChartWrapper>
        </>
      ) : (
        <Alert severity="error">중개매니저까지 선택해주세요.</Alert>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .label-total {
    background-color: black;
    color: white;
    svg {
      color: white;
    }
  }

  .label-selected {
    background-color: #999999ff;
    color: white;
    svg {
      color: white;
    }
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 50vh;
`;

export default ManagerDataTabContents;
