import styled from "styled-components";
import useSelectorTyped from "../store/useSelectorTyped";
import { MainHeader } from "./MainHeader";
import { MainPanel } from "./MainPanel";

const MainWrapper = styled.div<{ isMenu: boolean }>`
  min-height: 100vh;
  position: ${(props) => (props.isMenu ? "relative" : "inherit")};
  /* background: var(--background-main); */
`;

export function Main() {
  const { isMenu } = useSelectorTyped((state) => ({
    isMenu: state.menuSlice.isMenu,
  }));
  return (
    <MainWrapper isMenu={isMenu}>
      <MainHeader />
      <MainPanel />
    </MainWrapper>
  );
}
