export const prefixZeroFomatter = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

export const transformNumberToRatio = (value: number) => {
  return Number((value * 100).toFixed(2));
};

export const transformRatioToNumber = (value: number) => {
  return Number((value / 100).toFixed(2));
};

export const tranformNumberValuesToCommaSeperatedNumber = (
  dataGrid: object[]
) => {
  return dataGrid.map((data) => {
    const item: any = {};
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === "number") {
        item[key] = value.toLocaleString();
        return;
      }

      item[key] = value;
      return;
    });
    return item;
  });
};
