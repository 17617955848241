import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid";
import {
  TotalBrokerageCommissionSize,
  TradeSales,
  TradeSalesMediationCommisionSalesColumn,
  TradeSalesTotalColumn,
  TradeSalesTradeCountPriceColumn,
} from "../../../interface/TradeSales";
import { transformNumberToRatio } from "../../../utils/numberUtils";

export const COMMON_COLUMNS_OPTIONS = {
  headerAlign: "center" as GridAlignment,
  align: "right" as GridAlignment,
  renderCell: (params: GridRenderCellParams) => params.value?.toLocaleString(),
};

export const getCellValueTotal = (dataGrid: object[]) => {
  const total: any = {
    id: "total",
    areaName: "합계",
  };
  dataGrid.forEach((data) => {
    Object.entries(data).forEach(([key, value]) => {
      const prevValue = total[key] || 0;
      if (key.toUpperCase().includes("COMMISSIONRATE")) {
        return;
      }
      if (typeof value === "number") {
        total[key] = prevValue + value;
        return;
      }
      return;
    });
  });

  return total;
};

export const getFormattedTradeSalesCountAndPrice = (
  responseDataList: TradeSales[]
): TradeSalesTradeCountPriceColumn[] => {
  const result = responseDataList.map(
    ({
      areaName,
      tradeTotalCount,
      tradeTotalPrice,
      rentalTotalCount,
      monthlyTotalCount,
      rentalPrice,
      monthlyPrice,
    }) => {
      return {
        id: areaName,
        areaName,
        tradeTotalCount,
        tradeTotalPrice,
        rentalTotalCount,
        monthlyTotalCount,

        rentalTradeTotalPrice: rentalPrice.deposit.totalPrice,
        rentalDepositMinPrice: rentalPrice.deposit.newPrice,
        rentalTradeMaxPrice: rentalPrice.rentalTradeMaxPrice,

        monthlyTradeTotalPrice: monthlyPrice.monthlyTradePrice.totalPrice,
        monthlyTradeMinPrice: monthlyPrice.monthlyTradePrice.newPrice,
        monthlyTradeMaxPrice: monthlyPrice.rentalTradeMaxPrice,
      };
    }
  );
  return result;
};

export const getFormattedTotalBrokerageCommissionSize = (
  responseDataList: TradeSales[]
): TotalBrokerageCommissionSize[] => {
  const result = responseDataList.map(
    ({
      areaName,
      totalOneSideCommission,
      totalBothCommission,
      tradeTotalPrice,
      tradePrice,
      rentalPrice,
      monthlyPrice,
    }) => {
      return {
        id: areaName,
        areaName,
        totalOneSideCommission,
        totalBothCommission,

        tradeAveragePerTradePrice: tradePrice.averagePerTradePrice,
        tradeMaxCommissionRate: transformNumberToRatio(
          tradePrice.maxCommissionRate
        ),
        tradeTotalPrice,
        tradeOneSideCommission: tradePrice.oneSideCommission,

        rentalAveragePerTradePrice: rentalPrice.averagePerTradePrice,
        rentalMaxCommissionRate: transformNumberToRatio(
          rentalPrice.maxCommissionRate
        ),
        rentalTradeMaxPrice: rentalPrice.rentalTradeMaxPrice,
        rentalOneSideCommission: rentalPrice.oneSideCommission,

        monthlyAveragePerTradePrice: monthlyPrice.averagePerTradePrice,
        monthlyMaxCommissionRate: transformNumberToRatio(
          monthlyPrice.maxCommissionRate
        ),
        monthlyTradeMaxPrice: monthlyPrice.rentalTradeMaxPrice,
        monthlyOneSideCommission: monthlyPrice.oneSideCommission,
      };
    }
  );
  return result;
};

export const getFormattedWdbCommission = (
  responseDataList: TradeSales[]
): TradeSalesMediationCommisionSalesColumn[] => {
  const result = responseDataList.map(
    ({
      areaName,
      totalSales,
      discountTotalSales,
      tradePrice,
      rentalPrice,
      monthlyPrice,
    }) => {
      return {
        id: areaName,
        areaName,
        totalSales,
        discountTotalSales,

        tradeBothStandardSales: tradePrice.bothStandardSales,
        rentalBothStandardSales: rentalPrice.bothStandardSales,
        monthlyBothStandardSales: monthlyPrice.bothStandardSales,
        tradeApplyBothRateSales: tradePrice.applyBothRateSales,
        rentalApplyBothRateSales: rentalPrice.applyBothRateSales,
        monthlyApplyBothRateSales: monthlyPrice.applyBothRateSales,
      };
    }
  );
  return result;
};

export const getFormattedAnalyzedRental = (
  responseDataList: TradeSales[]
): TradeSalesTotalColumn[] => {
  const result = responseDataList.map(
    ({ areaName, rentalTotalCount, rentalPrice, monthlyPrice }) => {
      return {
        id: areaName,
        areaName,
        rentalTotalCount,

        rentalDepositNewPrice: rentalPrice.deposit.newPrice,
        rentalDepositReNewPrice: rentalPrice.deposit.renewPrice,
        rentalDepositUndefinePrice: rentalPrice.deposit.undefinePrice,
        rentalDepositTotalPrice: rentalPrice.deposit.totalPrice,
        rentalDepositMinPrice: rentalPrice.deposit.newPrice,
        rentalTradeMaxPrice: rentalPrice.rentalTradeMaxPrice,

        monthlyNewPrice: monthlyPrice.monthlyPrice.newPrice,
        monthlyDepositNewPrice: monthlyPrice.deposit.newPrice,
        monthlyTradeNewPrice: monthlyPrice.monthlyTradePrice.newPrice,
        monthlyReNewPrice: monthlyPrice.monthlyPrice.renewPrice,
        monthlyDepositReNewPrice: monthlyPrice.deposit.renewPrice,
        monthlyTradeReNewPrice: monthlyPrice.monthlyTradePrice.renewPrice,
        monthlyUndefinePrice: monthlyPrice.monthlyPrice.undefinePrice,
        monthlyDepositUndefinePrice: monthlyPrice.deposit.undefinePrice,
        monthlyTradeUndefinePrice: monthlyPrice.monthlyTradePrice.undefinePrice,
        monthlyTotalPrice: monthlyPrice.monthlyPrice.totalPrice,
        monthlyDepositTotalPrice: monthlyPrice.deposit.totalPrice,
        monthlyTradeTotalPrice: monthlyPrice.monthlyTradePrice.totalPrice,
        monthlyMinPrice: monthlyPrice.monthlyPrice.newPrice,
        monthlyDepositMinPrice: monthlyPrice.deposit.newPrice,
        monthlyTradeMinPrice: monthlyPrice.monthlyTradePrice.newPrice,

        monthlyMonthlyMaxPrice: monthlyPrice.monthlyMonthlyMaxPrice,
        monthlyDepositMaxPrice: monthlyPrice.monthlyDepositMaxPrice,
        monthlyRentalTradeMaxPrice: monthlyPrice.rentalTradeMaxPrice,
      };
    }
  );
  return result;
};
