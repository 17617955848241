import { RootState } from "./index";
import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Menu {
  isMenu: boolean /** true일 때 메뉴창 열린 상태 */;
  isDark: boolean /** true일 때 다크모드 상태 */;
  isDropdown: boolean;
  breadCrumb: string[];
}

const initialState: Menu = {
  isMenu: true,
  isDark: false,
  isDropdown: false,
  breadCrumb: [],
};

export const menuSlice = createSlice({
  name: "menuSlice",
  initialState,
  reducers: {
    settingMenu: (state, action: PayloadAction<boolean>) => {
      state.isMenu = action.payload;
    },
    settingDarkMode: (state, action: PayloadAction<boolean>) => {
      state.isDark = action.payload;
    },
    settingIsDropdown: (state, action: PayloadAction<boolean>) => {
      state.isDropdown = action.payload;
    },
    settingBreadcrumb: (state, action: PayloadAction<string[]>) => {
      state.breadCrumb = action.payload;
    },
  },
});

export const {
  settingMenu,
  settingDarkMode,
  settingIsDropdown,
  settingBreadcrumb,
} = menuSlice.actions;

export default menuSlice.reducer;
