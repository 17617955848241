import { DataGridPro } from "@mui/x-data-grid-pro";
import styled from "styled-components";
import { commisionColumn } from "../store/data/SalesSizeData";

function CommisionTradeGuideGrid() {
  return (
    <Wrapper>
      <DataGridPro
        columns={commisionColumn}
        rows={[
          {
            id: 1,
            거래금액: "5천만원 미만",
            상한요율: "0.6",
            한도액: 250000,
          },
          {
            id: 2,
            거래금액: "5천만원 이상~2억원 미만",
            상한요율: "0.5",
            한도액: 800000,
          },
          {
            id: 3,
            거래금액: "2억원 이상~9억원 미만",
            상한요율: "0.4",
            한도액: 0,
          },
          {
            id: 4,
            거래금액: "9억원 이상~12억원 미만",
            상한요율: "0.5",
            한도액: 0,
          },
          {
            id: 5,
            거래금액: "12억원 이상~15억원 미만",
            상한요율: "0.6",
            한도액: 0,
          },
          {
            id: 6,
            거래금액: "15억원 이상",
            상한요율: "0.7",
            한도액: 0,
          },
        ]}
        rowHeight={40}
        disableColumnMenu
        hideFooter
        autoHeight
      />
    </Wrapper>
  );
}

export default CommisionTradeGuideGrid;

const Wrapper = styled.div`
  min-width: 400px;
  width: 100%;
  margin: 16px 0;
`;
