import { GridColDef } from "@mui/x-data-grid";
import {
  getValueFormatteSquareMeter,
  getValueFormattedToLocaleString,
} from "../../utils/gridUtils";

export const transactionTradeColumns: GridColDef[] = [
  {
    field: "sigunguName",
    headerName: "시군구",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    flex: 1 / 10,
  },
  {
    field: "bunji",
    headerName: "번지",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    flex: 1 / 10,
  },
  {
    field: "complexName",
    headerName: "단지명",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    flex: 2 / 10,
  },
  {
    field: "privateArea",
    headerName: "전용면적",
    headerAlign: "center",
    align: "center",
    valueFormatter: getValueFormatteSquareMeter,
    disableColumnMenu: true,
    flex: 1 / 10,
  },
  {
    field: "contractDate",
    headerName: "계약년월일",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    flex: 1 / 10,
  },
  {
    field: "price",
    headerName: "거래금액(만원)",
    headerAlign: "center",
    align: "right",
    valueFormatter: getValueFormattedToLocaleString,
    disableColumnMenu: true,
    flex: 1 / 10,
  },
  {
    field: "floor",
    headerName: "층",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    flex: 1 / 10,
  },
];

export const transactionRentalColumns: GridColDef[] = [
  {
    field: "sigunguName",
    headerName: "시군구",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
  },
  {
    field: "bunji",
    headerName: "번지",
    headerAlign: "center",
    align: "right",
  },
  {
    field: "complexName",
    headerName: "단지명",
    flex: 0.2,
  },
  {
    field: "privateArea",
    headerName: "전용면적",
    headerAlign: "center",
    align: "center",
    valueFormatter: getValueFormatteSquareMeter,
  },
  {
    field: "contractDate",
    headerName: "계약년월일",
  },
  {
    field: "price",
    headerName: "거래금액(만원)",
    headerAlign: "center",
    align: "right",
    flex: 0.2,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "floor",
    headerName: "층",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "contractStartEnd",
    headerName: "계약 기간",
    headerAlign: "center",
    align: "center",
    flex: 0.2,
  },
  {
    field: "contractType",
    headerName: "계약 구분",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "previousContractDeposit",
    headerName: "종전계약 보증금(만원)",
    headerAlign: "center",
    align: "right",
    valueFormatter: getValueFormattedToLocaleString,
    flex: 0.2,
  },
  {
    field: "previousContractMonthlyPrice",
    headerName: "종전계약 월세(만원)",
    headerAlign: "center",
    align: "right",
    valueFormatter: getValueFormattedToLocaleString,
    flex: 0.2,
  },
];

export const transactionMonthlyColumns: GridColDef[] = [
  {
    field: "sigunguName",
    headerName: "시군구",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
  },
  {
    field: "bunji",
    headerName: "번지",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "complexName",
    headerName: "단지명",
    headerAlign: "center",
    align: "center",
    flex: 0.2,
  },
  {
    field: "privateArea",
    headerName: "전용면적",
    headerAlign: "center",
    align: "center",
    valueFormatter: getValueFormatteSquareMeter,
  },
  {
    field: "contractDate",
    headerName: "계약년월일",
    headerAlign: "center",
    align: "center",
    flex: 0.2,
  },
  {
    field: "price",
    headerName: "거래금액(만원)",
    headerAlign: "center",
    align: "right",
  },
  {
    field: "monthlyPrice",
    headerName: "월세",
    headerAlign: "center",
    align: "right",
  },
  {
    field: "floor",
    headerName: "층",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "contractStartEnd",
    headerName: "계약 기간",
    headerAlign: "center",
    align: "center",
    flex: 0.2,
  },
  {
    field: "contractType",
    headerName: "계약 구분",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "previousContractDeposit",
    headerName: "종전계약 보증금(만원)",
    headerAlign: "center",
    align: "right",
    valueFormatter: getValueFormattedToLocaleString,
    flex: 0.2,
  },
  {
    field: "previousContractMonthlyPrice",
    headerName: "종전계약 월세(만원)",
    headerAlign: "center",
    align: "right",
    valueFormatter: getValueFormattedToLocaleString,
    flex: 0.2,
  },
];
