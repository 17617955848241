import { useState } from "react";
import { getTradeSalesSizeApi } from "../store/api/tradeSalesSizeApi";
import { transformRatioToNumber } from "../utils/numberUtils";
import {
  TradeSales,
  TradeSalesSizeRequest,
  TradeSalesSizeRequestUserInput,
} from "./../interface/TradeSales";

const DEFAULT_CUSTOM_INPUT_VALUES = {
  bothRate: 80,
  tradeMarketShare: 2,
  rentalMarketShare: 2,
  monthlyMarketShare: 2,
  undefineToNewRate: 0.66,
  wdbDiscountRate: 50,
};

function useTradeSales() {
  const [tradeSizeResult, setTradeSizeResult] = useState<TradeSales[]>([]);
  const [calculatedSalesRate, setCalculatedSalesRate] = useState(0);
  const [userInputValues, setUserInputValues] =
    useState<TradeSalesSizeRequestUserInput>(DEFAULT_CUSTOM_INPUT_VALUES);
  const [loading, setLoding] = useState(false);

  // Todo React Query 마이그레이션 예정
  const getTradeSalesSize = async (params: TradeSalesSizeRequest) => {
    setLoding(true);
    try {
      const response = await getTradeSalesSizeApi(params);
      setLoding(false);

      if (response.error) return;
      const { data } = response;
      setTradeSizeResult(data.results);
      setCalculatedSalesRate(data.salesRate);
    } catch (error) {}
  };

  const formattedUserInputValues = {
    ...userInputValues,
    bothRate: transformRatioToNumber(userInputValues.bothRate),
    monthlyMarketShare: transformRatioToNumber(
      userInputValues.monthlyMarketShare
    ),
    rentalMarketShare: transformRatioToNumber(
      userInputValues.rentalMarketShare
    ),
    tradeMarketShare: transformRatioToNumber(userInputValues.tradeMarketShare),
    wdbDiscountRate: transformRatioToNumber(userInputValues.wdbDiscountRate),
  };

  const getAutomaticallyTradeSalesSize = async (
    params: Pick<
      TradeSalesSizeRequest,
      "year" | "month" | "areaCode" | "searchType"
    >
  ) => {
    return getTradeSalesSize({
      ...params,
      ...formattedUserInputValues,
    });
  };

  return {
    tradeSizeResult,
    calculatedSalesRate,

    formattedUserInputValues,
    userInputValues,
    setUserInputValues,

    getTradeSalesSize,
    getAutomaticallyTradeSalesSize,
    loading,
  };
}

export default useTradeSales;
