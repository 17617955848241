import {
  faArrowRightArrowLeft,
  faCalendar,
  faChartPie,
  faCheck,
  faHouseChimneyUser,
  faMoneyBillTrendUp,
  faPeopleArrows,
  faSquarePollVertical,
  faUser,
  faWindowRestore,
  faUsersLine,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const productionMenu = [
  {
    listName: "공공데이터",
    icon: <FontAwesomeIcon icon={faPeopleArrows} />,
    isDropdown: true,
    dropdownContent: [
      {
        link: "/public-data/transaction",
        listName: "국토교통부 실거래 데이터",
        icon: <FontAwesomeIcon icon={faArrowRightArrowLeft} />,
      },
      {
        link: "/public-data/commission-size",
        listName: "실거래 기반 중개수수료 규모",
        icon: <FontAwesomeIcon icon={faChartPie} />,
      },
    ],
  },
  {
    listName: "우대빵 전체 지표",
    icon: <FontAwesomeIcon icon={faSquarePollVertical} />,
    isDropdown: true,
    dropdownContent: [
      {
        link: "/total/sales-holding",
        listName: "매물 보유율",
        icon: <FontAwesomeIcon icon={faHouseChimneyUser} />,
      },
      {
        listName: "손님 확보율",
        icon: <FontAwesomeIcon icon={faUser} />,
        link: "/total/guest",
      },
      {
        listName: "거래 비율",
        icon: <FontAwesomeIcon icon={faMoneyBillTrendUp} />,
        link: "/total/trade-rate",
      },
    ],
  },
  {
    listName: "우대빵 지점별 지표",
    icon: <FontAwesomeIcon icon={faWindowRestore} />,
    isDropdown: true,
    dropdownContent: [
      {
        link: "/branch/data",
        listName: "지점별 데이터",
        icon: <FontAwesomeIcon icon={faHouseChimneyUser} />,
      },
      {
        link: "/branch/manager",
        listName: "중개매니저 데이터",
        icon: <FontAwesomeIcon icon={faHouseChimneyUser} />,
      },
    ],
  },
];

export const devMenu = [
  {
    listName: "공공데이터",
    icon: <FontAwesomeIcon icon={faPeopleArrows} />,
    isDropdown: true,
    dropdownContent: [
      {
        link: "/public-data/transaction",
        listName: "국토교통부 실거래 데이터",
        icon: <FontAwesomeIcon icon={faArrowRightArrowLeft} />,
      },
      {
        link: "/public-data/transaction-count",
        listName: "국토교통부 실거래 데이터(건수)",
        icon: <FontAwesomeIcon icon={faArrowRightArrowLeft} />,
      },
      {
        link: "/public-data/commission-size",
        listName: "실거래 기반 중개수수료 규모",
        icon: <FontAwesomeIcon icon={faChartPie} />,
      },
    ],
  },

  {
    listName: "우대빵 전체 지표",
    icon: <FontAwesomeIcon icon={faSquarePollVertical} />,
    isDropdown: true,
    dropdownContent: [
      {
        link: "/total/sales-holding",
        listName: "매물 보유율",
        icon: <FontAwesomeIcon icon={faHouseChimneyUser} />,
      },
      {
        listName: "손님 확보율",
        icon: <FontAwesomeIcon icon={faUser} />,
        link: "/total/guest",
      },
      {
        listName: "거래 비율",
        icon: <FontAwesomeIcon icon={faMoneyBillTrendUp} />,
        link: "/total/trade-rate",
      },
    ],
  },
  {
    listName: "우대빵 지점별 지표",
    icon: <FontAwesomeIcon icon={faWindowRestore} />,
    isDropdown: true,
    dropdownContent: [
      {
        link: "/branch/data",
        listName: "지점별 데이터",
        icon: <FontAwesomeIcon icon={faHouseChimneyUser} />,
      },
      {
        link: "/branch/manager",
        listName: "중개매니저 데이터",
        icon: <FontAwesomeIcon icon={faUsersLine} />,
      },
    ],
  },

  {
    listName: "TEST",
    icon: <FontAwesomeIcon icon={faCheck} />,
    isDropdown: true,
    dropdownContent: [
      {
        link: "/branch/data",
        listName: "주요 일정",
        icon: <FontAwesomeIcon icon={faCalendar} />,
      },
    ],
  },
];
