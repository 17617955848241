import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { ADMIN_PAGE } from "../config";
import { useSessionStore } from "../hook/storeHook";
import { Background } from "./LoadingPage";

const ErroPageWrapper = styled(Background)`
  a {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 20px 0;
    svg {
      margin-left: 5px;
    }
  }
  a:hover {
    opacity: 0.8;
  }
`;

export function ErrorPage() {
  const { isLogin } = useSessionStore();

  return (
    <>
      {!isLogin && (
        <>
          <ErroPageWrapper>
            <div>우대빵 어드민 페이지에서 로그인을 확인해주세요.</div>
            <a href={`${ADMIN_PAGE}`} className="bold">
              어드민 홈페이지 바로가기
              <FontAwesomeIcon icon={faCircleArrowRight} />
            </a>
          </ErroPageWrapper>
        </>
      )}
    </>
  );
}
