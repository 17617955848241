import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { font } from "./font";

export default createGlobalStyle`
 ${normalize}
  * {
    box-sizing: border-box;
    font-family: "Pretendard-Regular";
   
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  body {
	  background-color: ${(props) => props.theme.bgColor};
    color: ${(props) => props.theme.color};
    padding: 0;
    margin: 0;
  }

  .text-center { text-align: center; }
  .text-right { text-align: right; }
  
  .text-size-12 { font-size: ${font.px12} }

  .margin-bottom-05 { margin-bottom: 0.5vh }

  .MuiButton-root {
    font-weight: 700 !important;
  }

  /* data grid */
  .MuiDataGrid-columnHeader {
    padding: 0 !important;
  }
  .MuiDataGrid-columnHeaderTitle {
    padding: 0 12px;
    font-weight: 600 !important;
  }
  .MuiDataGrid-columnHeaderTitleContainerContent {
    padding: 0 12px;
    font-weight: 600;
  }
  .MuiDataGrid-columnHeaderTitleContainerContent > p {
    margin: 0;
    line-height: 110%;
    text-align: center;
    font-weight: 600;
  }
  div [data-id="total"] {
    font-weight: 700;
  }
`;
