import { RootState } from "./index";
// useSelectorTyped.ts
import { useSelector, shallowEqual } from "react-redux";

/**
 * useSelector의 안티패턴(리랜더링이 많이 일어날 수 있음)을 방지하는 함수
 * @param fn RootState로 설정
 * @returns useSelector(fn, shallowEqual)
 * {@link} https://kyounghwan01.github.io/blog/React/redux/redux-toolkit/#%E1%84%8C%E1%85%A9%E1%86%BC%E1%84%92%E1%85%A1%E1%86%B8-%E1%84%8B%E1%85%A8%E1%84%8C%E1%85%A6
 */
export default function useSelectorTyped<T>(fn: (state: RootState) => T): T {
  return useSelector(fn, shallowEqual);
}
