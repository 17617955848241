import {
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AreaBreadCrumb from "../components/AreaBreadCrumb";
import { ContentBox } from "../components/ContentBox";
import { SearchBox } from "../components/SearchBox";
import { FlexWrapper } from "../components/atoms/layout/FlexWrapper";
import SelectGroup from "../components/select/SelectGroup";
import WdbSelect from "../components/select/WdbSelect";
import TransactionGrid from "../containers/transactionPage/components/TransactionGrid";
import {
  getFormattedTransactionMonthlyData,
  getFormattedTransactionRentalData,
  getFormattedTransactionTradeData,
} from "../containers/transactionPage/utils/tableResourceFormatter";
import useAreaController from "../hook/useAreaController";
import useRangeController from "../hook/useRangeController";
import useTradeTypeController from "../hook/useTradeTypeController";
import { SelectOption } from "../interface/CommonInterface";
import { TransactionResponse } from "../interface/Transaction";
import { SuccessWebResponse } from "../module/HTTPWebClient";
import {
  selectableSidoOptionList,
  tradeTypeOptionList,
} from "../static/optionList";
import { getTransactionApi } from "../store/api/transactionApi";
import { getSelectYearOptions } from "../utils";
import { getSelectableMonthList } from "../utils/dateUtils";
import { prefixZeroFomatter } from "../utils/numberUtils";

const [currentYear, currentMonth] = [
  new Date().getFullYear(),
  new Date().getMonth(),
];
const VALID_START_YEAR = 2014;
const VALID_END_YEAR = currentMonth ? currentYear : currentYear - 1;
const DEFAULT_PAGE_SIZE = 20;

function TransactionPage() {
  const { seletedTradeType, onChangeTradeType } = useTradeTypeController();
  const { selectedYear, selectedMonth, onChangeYear, onChangeMonth } =
    useRangeController(undefined, currentMonth);

  const {
    selectableSigunguList,
    selectedSido,
    selectedSigungu,

    onChangeSido,
    onChangeSigungu,

    resetSigungu,
    resetSido,
  } = useAreaController();

  const [totalCount, setTotalCount] = useState(0);
  const [nowPage, setNowPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [search, setSearch] = useState("");
  const [searchButtonClick, setSearchButtonClick] = useState(false);

  const results = useQueries({
    queries: [
      {
        queryKey: [
          "getTransactionData",
          seletedTradeType,
          selectedYear,
          selectedMonth,
          selectedSido,
          selectedSigungu,
          nowPage,
          totalCount,
          searchButtonClick,
        ],
        queryFn: async () => {
          const response = await getTransactionApi({
            search,
            page: nowPage,
            tradeType: seletedTradeType,
            contractYear: selectedYear.toString(),
            contractMonth: !!selectedMonth
              ? prefixZeroFomatter(selectedMonth).toString()
              : undefined,
            sidoCd: selectedSido === "전체" ? "" : selectedSido,
            sigunguCode: selectedSigungu === "전체" ? "" : selectedSigungu,
          });
          if (response.error) return;

          return response;
        },
        onSuccess(data: SuccessWebResponse<TransactionResponse> | undefined) {
          console.log("Sucess data : ", data);
          if (data) {
            setTotalCount(data.data.total);
            setTotalPage(Math.ceil(totalCount / DEFAULT_PAGE_SIZE));
          }
        },
        onError: (e: any) => {
          // 실패시 호출 (401, 404 같은 error가 아니라 정말 api 호출이 실패한 경우만 호출됩니다.)
          // 강제로 에러 발생시키려면 api단에서 throw Error 날립니다. (참조: https://react-query.tanstack.com/guides/query-functions#usage-with-fetch-and-other-clients-that-do-not-throw-by-default)
          console.log("Fail! : ", e.message);
        },
        refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
      },
    ],
  });

  const transactionData = results[0];

  const getFormattedTransactionData = () => {
    switch (seletedTradeType) {
      default:
        return [];
      case "TRADE":
        return getFormattedTransactionTradeData(transactionData.data?.data);
      case "RENTAL":
        return getFormattedTransactionRentalData(transactionData.data?.data);
      case "MONTHLY_RENTAL":
        return getFormattedTransactionMonthlyData(transactionData.data?.data);
    }
  };

  const onChangeSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(event.currentTarget.value);
  };

  useEffect(() => {
    setNowPage(1);
  }, [
    seletedTradeType,
    selectedYear,
    selectedMonth,
    selectedSido,
    selectedSigungu,
  ]);

  return (
    <TransactionWrapper>
      <SearchBox>
        <SelectGroup label="거래타입">
          <ToggleButtonGroup
            onChange={onChangeTradeType}
            value={seletedTradeType}
            exclusive
            color="primary"
          >
            {tradeTypeOptionList.map((item: SelectOption) => (
              <ToggleButton value={item.value}>{item.label}</ToggleButton>
            ))}
          </ToggleButtonGroup>
        </SelectGroup>

        <SelectGroup label={"기간"}>
          <WdbSelect
            label={"연도"}
            value={selectedYear}
            onChange={onChangeYear}
          >
            {getSelectYearOptions(VALID_START_YEAR, VALID_END_YEAR).map(
              (year) => (
                <MenuItem value={Number(year.value)}>{year.label}</MenuItem>
              )
            )}
          </WdbSelect>
          <WdbSelect
            label={"월"}
            value={selectedMonth}
            onChange={onChangeMonth}
          >
            {getSelectableMonthList(selectedYear).map((month) => (
              <MenuItem value={Number(month)}>
                {prefixZeroFomatter(month)}
              </MenuItem>
            ))}
          </WdbSelect>
        </SelectGroup>

        <SelectGroup label={"지역"}>
          <WdbSelect
            label={"시/도"}
            value={selectedSido}
            onChange={onChangeSido}
          >
            {selectableSidoOptionList.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </WdbSelect>
          <WdbSelect
            label={"시/군/구"}
            value={selectedSigungu}
            onChange={onChangeSigungu}
          >
            {selectableSigunguList.length > 0 ? (
              selectableSigunguList.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))
            ) : (
              <MenuItem value="전체">전체</MenuItem>
            )}
          </WdbSelect>
        </SelectGroup>
      </SearchBox>

      <ContentBox>
        <FlexWrapper justifyContent="space-between">
          <AreaBreadCrumb
            sidoSelect={selectableSidoOptionList.find(
              (option) => option.value === selectedSido
            )}
            sigunguSelect={selectableSigunguList.find(
              (option) => option.value === selectedSigungu
            )}
            onClickSidoXButton={resetSido}
            onClickSigunguXButton={resetSigungu}
          />
          <FlexWrapper>
            <FormControl>
              <TextField
                className="danji-search-bar"
                label="단지명 검색"
                placeholder="단지명을 검색해주세요."
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        onClick={() => {
                          setNowPage(1);
                          setSearchButtonClick(!searchButtonClick);
                        }}
                      >
                        검색
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onChange={onChangeSearch}
              />
            </FormControl>
            <Pagination
              count={totalPage}
              color="primary"
              page={nowPage}
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                setNowPage(page);
              }}
            />
          </FlexWrapper>
        </FlexWrapper>
        <TransactionGrid
          tradeGridData={getFormattedTransactionData()}
          tradeType={seletedTradeType}
          loading={transactionData.isLoading}
        />
      </ContentBox>
    </TransactionWrapper>
  );
}

export default TransactionPage;

const TransactionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .danji-search-bar {
    min-width: 250px;
  }

  .MuiOutlinedInput-root {
    padding-right: 0;
  }
`;
