import {
  SalesHoldingBjdongResponse,
  SalesHoldingBjdongResponseDTO,
  SalesHoldingColumn,
  SalesHoldingComplexResponse,
  SalesHoldingComplexResponseDTO,
  SalesHoldingDTO,
} from "./../../../interface/SalesHolding";
import { SalesHoldingResponse } from "../../../interface/SalesHolding";

export const getFormattedSalesHoldingData = (
  responseList: SalesHoldingBjdongResponse | undefined
): SalesHoldingColumn[] => {
  const result = responseList?.naverStats.map(
    (item: SalesHoldingBjdongResponseDTO, index: number) => {
      const naverStats = responseList.naverStats[index];
      const wdbStats = responseList.wdbStats[index];

      return {
        id: index,
        areaCode: item.areaCode,
        areaName: item.areaName,

        naverTradeCount: naverStats.count.tradeCount,
        naverRentalCount: naverStats.count.rentalCount,
        naverMonthlyCount: naverStats.count.monthlyCount,
        naverTotalCount: naverStats.count.totalCount,

        naverTradeGroupCount: naverStats.groupCount.tradeCount,
        naverRentalGroupCount: naverStats.groupCount.rentalCount,
        naverMonthlyGroupCount: naverStats.groupCount.monthlyCount,
        naverTotalGroupCount: naverStats.groupCount.totalCount,

        sameOfferAdRate: item.sameOfferAdRate,

        woodaeTradeCount: wdbStats.count.tradeCount,
        woodaeTradeRate: wdbStats.holdRate.tradeRate,
        woodaeRentalCount: wdbStats.count.rentalCount,
        woodaeRentalRate: wdbStats.holdRate.rentalRate,
        woodaeMonthlyCount: wdbStats.count.monthlyCount,
        woodaeMonthlyRate: wdbStats.holdRate.monthlyRate,
        woodaeTotalCount: wdbStats.count.totalCount,
        woodaeTotalRate: wdbStats.holdRate.totalRate,
      };
    }
  );

  return result ?? [];
};

export const getFormattedSalesHoldingComplexData = (
  responseList: SalesHoldingComplexResponse | undefined
): SalesHoldingColumn[] => {
  const result = responseList?.naverStats.map(
    (item: SalesHoldingComplexResponseDTO, index: number) => {
      const naverStats = responseList.naverStats[index];
      const wdbStats = responseList.wdbStats[index];

      return {
        id: index,
        areaCode: item.complex.complexId,
        areaName: item.complex.complexName,

        naverTradeCount: naverStats.count.tradeCount,
        naverRentalCount: naverStats.count.rentalCount,
        naverMonthlyCount: naverStats.count.monthlyCount,
        naverTotalCount: naverStats.count.totalCount,

        naverTradeGroupCount: naverStats.groupCount.tradeCount,
        naverRentalGroupCount: naverStats.groupCount.rentalCount,
        naverMonthlyGroupCount: naverStats.groupCount.monthlyCount,
        naverTotalGroupCount: naverStats.groupCount.totalCount,

        sameOfferAdRate: item.sameOfferAdRate,

        woodaeTradeCount: wdbStats.count.tradeCount,
        woodaeTradeRate: wdbStats.holdRate.tradeRate,
        woodaeRentalCount: wdbStats.count.rentalCount,
        woodaeRentalRate: wdbStats.holdRate.rentalRate,
        woodaeMonthlyCount: wdbStats.count.monthlyCount,
        woodaeMonthlyRate: wdbStats.holdRate.monthlyRate,
        woodaeTotalCount: wdbStats.count.totalCount,
        woodaeTotalRate: wdbStats.holdRate.totalRate,
      };
    }
  );

  return result ?? [];
};
