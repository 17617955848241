import { BrunchTotalData } from "../../../interface/Branch";
import {
  ManagerDataRequest,
  ManagerMonthlyDataColumn,
} from "../../../interface/Manager";
import {
  getEndOfMonth,
  getFormattedMonthAndDate,
  getFormattedYearMonth,
} from "../../../utils/dateUtils";
import { DynamicObject } from "./transpose";

const ROW_TITLE_LIST = [
  {
    id: "ratingCount",
    title: "급수(월평균)",
  },
  {
    id: "totalOfferCount",
    title: "관리매물(월평균)",
  },
  {
    id: "newBuyingCount",
    title: "손님1(월누적 신규접수손님)",
  },
  {
    id: "availableBuyingCount",
    title: "손님2(월평균 유효손님)",
  },
  {
    id: "visitCompletionCount",
    title: "임장(월누적)",
  },
  {
    id: "contractCompletionCount",
    title: "계약(월누적)",
  },
  {
    id: "buyingRate",
    title: "매물>손님 비율(손님1/관리매물)",
  },
  {
    id: "visitRate",
    title: "손님>임장 비율(임장/손님2)",
  },
  {
    id: "buyingContractRate",
    title: "손님>계약 비율(계약/손님2)",
  },
  {
    id: "offerContractRate",
    title: "매물>계약 비율(계약/관리매물)",
  },
  {
    id: "offerContractPer10",
    title: "매물10개당 계약 건수",
  },
  {
    id: "otherOfferContractPer10",
    title: "타부동산 매물10개당 계약건수",
  },
  {
    id: "offerContractPer10Percentile",
    title: "매물10개당 계약건수 백분위",
  },
  {
    id: "totalOfferContractCount",
    title: "관리매물 중 전체 거래건수",
  },
  {
    id: "contractRate",
    title: "거래비율",
  },
  // {
  //   id: "",
  //   title: "매물10개당 계약 건수(지점평균)", // 우선 포함 안함.
  // },
  {
    id: "commission",
    title: "매출",
  },
];

export function getFormattedManagerDataRow(
  year: number,
  gridData: ManagerDataRequest
) {
  const monthSet = new Set(
    gridData.managerMonthlyData.map((data) => data.month)
  );
  const formattedData: DynamicObject[] = [];

  ROW_TITLE_LIST.forEach((item, index) => {
    const row: DynamicObject = { ...item };

    if (index === 0) {
      row["title1"] = gridData.accountName;
    }

    gridData.managerMonthlyData.forEach((data: ManagerMonthlyDataColumn) => {
      const date = getFormattedYearMonth(data.month);
      row[date] = data[item.id as keyof ManagerMonthlyDataColumn];
    });

    Array.from({ length: 12 }).forEach((_, index) => {
      const month = index + 1;
      if (!monthSet.has(month)) {
        const date = getFormattedYearMonth(month);
        row[date] = "-";
      }
    });

    formattedData.push(row);
  });

  return formattedData;
}
