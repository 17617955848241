import { BrunchTotalData } from "../../../interface/Branch";
import {
  getEndOfMonth,
  getFormattedMonthAndDate,
} from "../../../utils/dateUtils";
import { DynamicObject } from "./transpose";

const ROW_TITLE_LIST = [
  {
    id: "buyingCount",
    title: "손님",
  },
  {
    id: "contractCount",
    title: "계약",
  },
  {
    id: "tradePrice",
    title: "거래금액",
  },
  {
    id: "registerCommission",
    title: "매출(가계약일 기준)",
  },
  {
    id: "perCommission",
    title: "건당 매출",
  },
  {
    id: "balanceCommission",
    title: "매출(잔금일 기준)",
  },
];

export function getFormattedBranchTotalDataRow(
  year: number,
  branchTotalData: BrunchTotalData[]
) {
  const monthSet = new Set(branchTotalData.map((data) => data.month)); // ✅ Set(5) {1, 2, 3, 4, 5}
  const formattedData: DynamicObject[] = [];

  ROW_TITLE_LIST.forEach((item) => {
    const row: DynamicObject = { ...item };

    branchTotalData.forEach((data: BrunchTotalData) => {
      const date = getFormattedMonthAndDate(getEndOfMonth(year, data.month));

      if (item.id.includes("Commission")) {
        row[date] = Math.round(data[item.id as keyof BrunchTotalData] * 1.1);
      } else {
        row[date] = data[item.id as keyof BrunchTotalData];
      }
    });

    Array.from({ length: 12 }).forEach((_, index) => {
      const month = index + 1;
      if (!monthSet.has(month)) {
        const date = getFormattedMonthAndDate(getEndOfMonth(year, month));
        row[date] = "-";
      }
    });

    formattedData.push(row);
  });

  return formattedData;
}
