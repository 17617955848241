import { TradeChartDetail } from "../../../interface/TradeRate";
import { ChartData, ChartDataSets } from "../../../interface/chartFormatter";
import { color } from "../../../styles/color";

export const getFormattedGuestMonthlyChartData = (
  responseList: TradeChartDetail[] | undefined
) => {
  const labels: string[] = [];
  const datasets: ChartDataSets[] = [];

  const tradeCountData: number[] = [];
  const rentaCountlData: number[] = [];
  const monthlyCountData: number[] = [];
  const totalCountData: number[] = [];

  const tradeRateData: number[] = [];
  const rentaRatelData: number[] = [];
  const monthlyRateData: number[] = [];
  const totalRateData: number[] = [];

  responseList &&
    responseList.map((item: TradeChartDetail) => {
      labels.push(item.period);

      tradeCountData.push(item.count.tradeCount);
      rentaCountlData.push(item.count.rentalCount);
      monthlyCountData.push(item.count.monthlyCount);
      totalCountData.push(item.count.totalCount);

      tradeRateData.push(item.rate.tradeRate);
      rentaRatelData.push(item.rate.rentalRate);
      monthlyRateData.push(item.rate.monthlyRate);
      totalRateData.push(item.rate.totalRate);
    });

  datasets.push(
    {
      label: "전체 우대빵거래건수",
      data: totalCountData,
      borderColor: "black",
      backgroundColor: "black",
      borderWidth: 2,
      yAxisID: "y",
    },
    {
      label: "매매 우대빵거래건수",
      data: tradeCountData,
      borderColor: color.trade,
      backgroundColor: color.trade,
      borderWidth: 2,
      yAxisID: "y",
    },
    {
      label: "전세 우대빵거래건수",
      data: rentaCountlData,
      borderColor: color.rental,
      backgroundColor: color.rental,
      borderWidth: 2,
      yAxisID: "y",
    },
    {
      label: "월세 우대빵거래건수",
      data: monthlyCountData,
      borderColor: color.monthly,
      backgroundColor: color.monthly,
      borderWidth: 2,
      yAxisID: "y",
    },
    {
      label: "전체 거래비율",
      data: totalRateData,
      borderColor: "black",
      backgroundColor: "black",
      borderDash: [5, 5],
      yAxisID: "y1",
    },
    {
      label: "매매 거래비율",
      data: tradeRateData,
      borderColor: color.trade,
      backgroundColor: color.trade,
      borderDash: [5, 5],
      yAxisID: "y1",
    },
    {
      label: "전세 거래비율",
      data: rentaRatelData,
      borderColor: color.rental,
      backgroundColor: color.rental,
      borderDash: [5, 5],
      yAxisID: "y1",
    },
    {
      label: "월세 거래비율",
      data: monthlyRateData,
      borderColor: color.monthly,
      backgroundColor: color.monthly,
      borderDash: [5, 5],
      yAxisID: "y1",
    }
  );

  const chartData: ChartData = { labels, datasets };

  return chartData;
};
