import { Route, Routes } from "react-router-dom";
import BrokerageCommissionSizePage from "../BrokerageCommissionSizePage";
import { TransactionCountPage } from "../TransactionCountPage";
import TransactionPage from "../TransactionPage";

function PublicDataRoute() {
  return (
    <Routes>
      <Route path="transaction" element={<TransactionPage />} />
      <Route path="transaction-count" element={<TransactionCountPage />} />
      <Route path="commission-size" element={<BrokerageCommissionSizePage />} />
    </Routes>
  );
}

export default PublicDataRoute;
