import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../assets/spinner.gif";
import { Error } from "../components/atoms/Error";
import { BASE_URL } from "../config";
import { useSessionStore } from "../hook/storeHook";
import { setCookieAccessToken } from "../store/cookies";

export const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffffb7;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function LoadingPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(0);
  const { isLogin } = useSessionStore();

  useEffect(() => {
    sessionStorage.setItem("isLogin", "false");
    // 주소창 토큰 받는 작업 search는 ?token=adsfasdf 이런식으로 나옴
    const token = location.search.split("=")[1];

    setCookieAccessToken(token);

    !token && !isLogin && navigate("/login-guide");

    (async () => {
      try {
        const response = await axios.get(`${BASE_URL}/account/my-info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: () => true,
        });
        setStatus(response.status);
        if (response.status === 200) {
          setError(true);
          sessionStorage.setItem("isLogin", "true");
          sessionStorage.setItem(
            "userInfo",
            JSON.stringify(response.data.data)
          );
          navigate("/");
        } else {
          setError(false);
          sessionStorage.setItem("isLogin", "false");
        }
      } catch (err: any) {
        console.log("err : ", err);
      }
    })();
  }, []);

  return (
    <>
      <Background>
        {error ? (
          <>
            <div>잠시만 기다려 주세요..</div>
            <img src={Spinner} alt="로딩중" width="5%" />
          </>
        ) : (
          <>
            <Error error={status} />
          </>
        )}
      </Background>
    </>
  );
}

export default LoadingPage;
