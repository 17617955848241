import { SearchType } from "./../../interface/SalesHolding";
import { API_DISTRICTS } from "./../../config";
import clientAxios from "./customAxios";
import { DistrictType } from "../../interface/District";

export async function districtFetch(
  districtType: DistrictType,
  totalCode: string
) {
  try {
    const data = await clientAxios.get(
      `${API_DISTRICTS}?districtType=${districtType}&totalCode=${totalCode}`
    );

    switch (data.status) {
      case 200:
        return {
          data,
          error: false,
          status: data.status,
        };
      default:
        return {
          data,
          error: true,
          status: data.status,
        };
    }
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function fetchData(props: { url: string }) {
  try {
    const data = await clientAxios.get(props.url);
    switch (data.status) {
      case 200:
        return {
          data,
          error: false,
          status: data.status,
        };
      default:
        return {
          data,
          error: true,
          status: data.status,
        };
    }
  } catch (error) {
    return {
      error: true,
    };
  }
}
