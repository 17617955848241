import { transformNumberToRatio } from '../../utils/numberUtils'
import WdbTooltip from './WdbTooltip'

interface UndefinedRateTooltipProps {
  rate: number
}
function UndefinedRateTooltip({rate}: UndefinedRateTooltipProps) {
  return (
    <WdbTooltip
      title={
        <>
          F ({transformNumberToRatio(rate)}%) : <br />
          거래 유형(신규/갱신)이 확인되지 않은
          <br />
          미확인 거래가 신규 거래일 확률
        </>
      }
    />
  )
}

export default UndefinedRateTooltip