import { color } from "./../../../styles/color";

import {
  RationTransitionCountsNaver,
  SalesHoldingChartResponse,
} from "./../../../interface/SalesHolding";
import { LegendItem } from "chart.js";
import { ChartData, ChartDataSets } from "../../../interface/chartFormatter";

export const getFormattedSalesHoldingWdbChartData = (
  responseList: SalesHoldingChartResponse | undefined
) => {
  const labels: string[] = [];
  const datasets: ChartDataSets[] = [];

  const wdbTradeData: number[] = [];
  const wdbRentalData: number[] = [];
  const wdbMonthlyData: number[] = [];
  const wdbTotalData: number[] = [];

  responseList &&
    responseList.naver.counts.map(
      (item: RationTransitionCountsNaver, index: number) => {
        const wdb = responseList.wdb.counts[index];

        labels.push(wdb.date);

        wdbTradeData.push(wdb.count.tradeRate);
        wdbRentalData.push(wdb.count.rentalRate);
        wdbMonthlyData.push(wdb.count.monthlyRate);
        wdbTotalData.push(wdb.count.totalRate);
      }
    );

  datasets.push(
    {
      label: responseList?.area.name + " 전체",
      data: wdbTotalData,
      borderColor: "black",
      backgroundColor: "black",
      borderWidth: 2,
    },
    {
      label: responseList?.area.name + " 매매",
      data: wdbTradeData,
      borderColor: color.trade,
      backgroundColor: color.trade,
      borderWidth: 2,
    },
    {
      label: responseList?.area.name + " 전세",
      data: wdbRentalData,
      borderColor: color.rental,
      backgroundColor: color.rental,
      borderWidth: 2,
    },
    {
      label: responseList?.area.name + " 월세",
      data: wdbMonthlyData,
      borderColor: color.monthly,
      backgroundColor: color.monthly,
      borderWidth: 2,
    }
  );

  const chartData: ChartData = { labels, datasets };

  return chartData;
};

export const getFormattedSalesHoldingNaverChartData = (
  responseList: SalesHoldingChartResponse | undefined
) => {
  const labels: string[] = [];
  const datasets: ChartDataSets[] = [];

  const naverTradeData: number[] = [];
  const naverRentalData: number[] = [];
  const naverMonthlyData: number[] = [];
  const naverTotalData: number[] = [];

  responseList &&
    responseList.naver.counts.map(
      (item: RationTransitionCountsNaver, index: number) => {
        const naver = responseList.naver.counts[index];

        labels.push(naver.date);

        naverTradeData.push(naver.count.tradeCount);
        naverRentalData.push(naver.count.rentalCount);
        naverMonthlyData.push(naver.count.monthlyCount);
        naverTotalData.push(naver.count.totalCount);
      }
    );

  datasets.push(
    {
      label: responseList?.area.name + " 전체",
      data: naverTotalData,
      borderColor: "black",
      backgroundColor: "black",
    },
    {
      label: responseList?.area.name + " 매매",
      data: naverTradeData,
      borderColor: color.trade,
      backgroundColor: color.trade,
    },
    {
      label: responseList?.area.name + " 전세",
      data: naverRentalData,
      borderColor: color.rental,
      backgroundColor: color.rental,
    },
    {
      label: responseList?.area.name + " 월세",
      data: naverMonthlyData,
      borderColor: color.monthly,
      backgroundColor: color.monthly,
    }
  );

  const chartData: ChartData = { labels, datasets };

  return chartData;
};

export const getChartColor = (labelName: string | undefined) => {
  let colorText = "";

  if (labelName?.includes("매매")) {
    colorText = color.trade;
  } else if (labelName?.includes("전세")) {
    colorText = color.rental;
  } else if (labelName?.includes("월세")) {
    colorText = color.monthly;
  } else if (labelName?.includes("전체")) {
    colorText = "black";
  }

  return colorText;
};

export const salesHoldingChartOptions = {
  plugins: {
    legend: {
      onClick: (e: MouseEvent, legendItem: LegendItem, legend: any) => {
        const index = legendItem.datasetIndex;
        const ci = legend.chart;
        if (ci.isDatasetVisible(index)) {
          ci.hide(index);
          legendItem.hidden = true;
        } else {
          ci.show(index);
          legendItem.hidden = false;
        }
      },
    },
  },
};
