import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Default } from "../../hook/mediaQuery";
import { useMenu } from "../../hook/useMenu";
import { color } from "../../styles/color";

export interface DropdownProps {
  listName: string;
  link: string;
  icon?: React.ReactNode;
}

export interface MenuProps {
  link?: string;
  listName?: string;
  icon?: React.ReactNode;
  children?: ReactNode;
  isDropdown?: boolean;
  dropdownContent?: DropdownProps[];
  display?: boolean;
}

export function MenuList(props: MenuProps) {
  const { setMenu } = useMenu();
  const [ishover, setIshover] = useState(false);

  // Dropdown 없어지는 애니메이션 적용 위해, 메뉴 누르면 꺼져서 굳이 넣지 않음.
  // isHover 유무로 보이는 것을 visibilityAnimation로 바꾸면 됨.
  const [visibilityAnimation, setVisibilityAnimation] = useState(false);
  // useEffect(() => {
  //   if (ishover) {
  //     setVisibilityAnimation(true);
  //   } else {
  //     setTimeout(() => {
  //       setVisibilityAnimation(false);
  //     }, 400);
  //   }
  // }, [ishover]);

  return (
    <MenuListWrapper>
      <li key={props.listName}>
        {props.isDropdown ? (
          <span
            className="menu-name"
            onClick={() => {
              setIshover(!ishover);
            }}
          >
            {props.icon}
            <Default>
              <span className="list-name">{props.listName}</span>
            </Default>
            {props.isDropdown ? (
              <FontAwesomeIcon icon={faSortDown} className="arrow" />
            ) : null}
          </span>
        ) : (
          <NavLink
            to={props.link as string}
            className={({ isActive }) => (isActive ? "current" : "")}
            onClick={() => {
              setMenu(false);
            }}
            onMouseEnter={() => {
              setIshover(true);
            }}
          >
            {props.icon}
            <span className="list-name">{props.listName}</span>
            {props.isDropdown ? (
              <FontAwesomeIcon icon={faSortDown} className="arrow" />
            ) : null}
          </NavLink>
        )}
      </li>

      <div
        className={
          ishover ? "slide-fade-in-dropdown" : "slide-fade-out-dropdown"
        }
      >
        {props.isDropdown && ishover && (
          <div className={`dropdown`}>
            {props.dropdownContent &&
              props.dropdownContent.map(
                (value: DropdownProps, index: number) => (
                  <NavLink
                    key={index}
                    to={value.link}
                    className={({ isActive }) => (isActive ? "current" : "")}
                    onClick={() => {
                      setMenu(false);
                    }}
                  >
                    {value.icon}
                    <span>{value.listName}</span>
                  </NavLink>
                )
              )}
          </div>
        )}
      </div>
    </MenuListWrapper>
  );
}

const MenuListWrapper = styled.div`
  position: relative;

  a {
    position: relative;
    span {
      margin-right: 10px;
    }
    .arrow {
      position: absolute;
    }
  }

  .menu-name {
    /* opacity: 0.6; */
    cursor: pointer;
    .list-name {
      margin-right: 10px;
    }
    .arrow {
      position: absolute;
    }
    &:hover {
      color: ${color.antColor.daybreakBlue.blue2};
    }
  }

  .dropdown {
    background: black;
    padding: 10px;
    font-size: 14px;
    min-width: 17vw;
    left: -2px;
    z-index: 1;
    border-radius: 3px;
    top: 50px;
    a {
      display: block;
      margin: 5px 0;
    }
    a:hover {
      color: ${color.antColor.daybreakBlue.blue2};
    }

    svg {
      width: 16px;
    }
  }

  /* fade in */

  @keyframes slide-fade-in-dropdown-animation {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }

  .slide-fade-in-dropdown {
    overflow: hidden;
  }

  .slide-fade-in-dropdown > .dropdown {
    animation: slide-fade-in-dropdown-animation 0.6s ease;
  }

  /* fade out */

  @keyframes slide-fade-out-dropdown-animation {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-100%);
    }
  }

  .slide-fade-out-dropdown {
    overflow: hidden;
  }

  .slide-fade-out-dropdown > .dropdown {
    animation: slide-fade-out-dropdown-animation 0.4s ease;
    animation-fill-mode: forwards;
  }
`;
