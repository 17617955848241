import { GridColDef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { COMMON_COLUMNS_OPTIONS } from "../../brokerageCommissionSize/utils/tableResourceFormatter";
import { getFormattedBranchTotalDataRow } from "../utils/branchTotalTableResourceFormatter";
import { GridCaption } from "../../../components/GridCaption";
import { BrunchTotalData } from "../../../interface/Branch";
import {
  getEndOfMonth,
  getFormattedMonthAndDate,
} from "../../../utils/dateUtils";
interface BranchTotalDataTableProps {
  year: number;
  branchTotalData: BrunchTotalData[];
  loading: boolean;
}
function BranchTotalDataTable({
  year,
  branchTotalData,
  loading,
}: BranchTotalDataTableProps) {
  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "",
      minWidth: 180,
      sortable: false,
      align: "left",
    },

    ...Array.from({ length: 12 }, (_, index) => {
      const month = index + 1;
      return {
        field: `${getFormattedMonthAndDate(getEndOfMonth(year, month))}`,
        flex: 1 / 12,
      };
    }),
  ];

  const formattedColumns: GridColDef[] = columns.map((item) => {
    const result: GridColDef = {
      ...item,
      ...COMMON_COLUMNS_OPTIONS,
      align: item.align ?? COMMON_COLUMNS_OPTIONS.align,
      headerAlign: item.headerAlign ?? COMMON_COLUMNS_OPTIONS.headerAlign,
    };
    return result;
  });

  return (
    <div>
      <GridCaption textAlign="right">(단위 : 원 (VAT포함))</GridCaption>
      <div style={{ height: "350px", width: "100%" }}>
        <DataGridPro
          experimentalFeatures={{ columnGrouping: true }}
          columns={formattedColumns}
          rows={getFormattedBranchTotalDataRow(year, branchTotalData)}
          rowHeight={40}
          disableSelectionOnClick
          loading={loading}
        />
      </div>
    </div>
  );
}

export default BranchTotalDataTable;
