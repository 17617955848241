import { IconButton, IconButtonProps } from "@mui/material";
import {
  GridRenderCellParams,
  useGridApiContext,
  useGridRootProps,
} from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import styled from "styled-components";
import {
  CompanyGroupRequest,
  RequiredRequest,
} from "../../../interface/CommonRequest";
import {
  TradeRateColumn,
  TradeRateCompanyGroup,
  TradeRateCompanyGroupColumn,
} from "../../../interface/TradeRate";
import DistrcitPopoverIcon from "../../salesHolding/components/DistrictPopoverIcon";

interface TreeProps extends GridRenderCellParams {
  getTree: (
    params: CompanyGroupRequest
  ) => Promise<TradeRateCompanyGroup[] | undefined>;
  startDate: string | null;
  endDate: string | null;
  gridData: TradeRateColumn[];
}

function TradeRateBranchOfficeTree({
  id,
  field,
  rowNode,
  row,
  getTree,
  startDate,
  endDate,
  gridData,
}: TreeProps) {
  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();

  const Icon = rowNode.childrenExpanded
    ? rootProps.components.TreeDataCollapseIcon
    : rootProps.components.TreeDataExpandIcon;

  const handleClick: IconButtonProps["onClick"] = async (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    if (rowNode.childrenExpanded || !row || row.childrenFetched) {
      return;
    }
    const data = await getTree({
      sigunguCode: row.sigunguCode,
      startDate: startDate,
      endDate: endDate,
    });

    const childrenRows: TradeRateCompanyGroupColumn[] = [];

    if (data && data.length > 0) {
      data.map((item: TradeRateCompanyGroup) => {
        childrenRows.push({
          id: row.sigunguCode + item.companyGroupNo,
          path: [row.sigunguName, item.companyGroupName],
          sigunguName: item.companyGroupName,
          wdbTradeCount: item.rateCount.tradeCount,
          wdbRentalCount: item.rateCount.rentalCount,
          wdbMonthlyCount: item.rateCount.monthlyCount,
          wdbTotalCount: item.rateCount.totalCount,
          assignDistrictName: item.assignDistrictName,
          assignDistrictCount: item.assignDistrictCount,
        });
      });
    }

    if (data && data.length <= 0) {
      childrenRows.push({
        id: row.sigunguCode + 0,
        path: [row.sigunguName, "지점 없음"],
        sigunguName: "지점 없음",
      });
    }

    apiRef.current.updateRows(childrenRows);

    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
  };

  const deps = row.path.length;
  const sigunguName = row.sigunguName;

  useEffect(() => {
    rowNode.childrenExpanded &&
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  }, [gridData]);

  return (
    <>
      {sigunguName !== "지점 없음" && sigunguName !== "합계" && deps < 2 && (
        <>
          <IconButton
            size="small"
            tabIndex={-1}
            onClick={handleClick}
            aria-label={
              rowNode.childrenExpanded
                ? apiRef.current.getLocaleText("treeDataCollapse")
                : apiRef.current.getLocaleText("treeDataExpand")
            }
          >
            <Icon fontSize="inherit" />
          </IconButton>

          <span>{sigunguName}</span>
        </>
      )}

      {sigunguName === "합계" && <div>{sigunguName}</div>}

      {deps >= 2 && <TreeWrapper>{sigunguName}</TreeWrapper>}
      {deps >= 2 && row.assignDistrictName && <DistrcitPopoverIcon {...row} />}
    </>
  );
}

const TreeWrapper = styled.div`
  margin-left: 1.5vw;
`;

export default TradeRateBranchOfficeTree;
