import styled from "styled-components";
import { Card } from "../atoms/Card";

const ErrorWrapper = styled.div`
  text-align: center;
  .guide-box {
    margin-bottom: 20px;
  }
  .error-title {
    color: red;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .admin-link {
    font-weight: 700;
  }
`;

export function Error(props: { error: number }) {
  return (
    <>
      {props.error !== 0 && (
        <ErrorWrapper>
          <Card flexDirection="column" width="30vw">
            <div className="guide-box">
              <div className="error-title">{props.error} Error!</div>
              <div>페이지 관리자에게 문의하세요.</div>
              <div>(어드민 페이지 로그인 확인)</div>
            </div>

            <a href="" className="admin-link">
              &gt;&gt; 어드민 페이지로 돌아가기 &lt;&lt;
            </a>
          </Card>
        </ErrorWrapper>
      )}
    </>
  );
}
