import { useState } from "react";
import { BuyingResponse, GuestGridColumn } from "../../../interface/Guest";

export const getFormattedGuestData = (
  responseDataList: BuyingResponse[] | undefined
): GuestGridColumn[] | [] => {
  let otherTradeCountTotal = 0;
  let otherRentalCountTotal = 0;
  let otherMonthlyCountTotal = 0;
  let othertotalCountTotal = 0;

  let otherBuyingTradeCountTotal = 0;
  let otherBuyingRentalCountTotal = 0;
  let otherBuyingMonthlyCountTotal = 0;
  let otherBuyingTotalCountTotal = 0;

  let wdbTradeCountTotal = 0;
  let wdbRentalCountTotal = 0;
  let wdbMonthlyCountTotal = 0;
  let wdbTotalCountTotal = 0;

  const result = responseDataList?.map((item: BuyingResponse) => {
    otherTradeCountTotal += item.other.tradeCount;
    otherRentalCountTotal += item.other.rentalCount;
    otherMonthlyCountTotal += item.other.monthlyCount;
    othertotalCountTotal += item.other.totalCount;

    otherBuyingTradeCountTotal += item.otherBuying.tradeCount;
    otherBuyingRentalCountTotal += item.otherBuying.rentalCount;
    otherBuyingMonthlyCountTotal += item.otherBuying.monthlyCount;
    otherBuyingTotalCountTotal += item.otherBuying.totalCount;

    wdbTradeCountTotal += item.wdb.tradeCount;
    wdbRentalCountTotal += item.wdb.rentalCount;
    wdbMonthlyCountTotal += item.wdb.monthlyCount;
    wdbTotalCountTotal += item.wdb.totalCount;

    return {
      id: item.sigunguCode,
      path: [item.sigunguName],
      sigunguCode: item.sigunguCode,
      sigunguName: item.sigunguName,

      otherTradeCount: item.other.tradeCount,
      otherRentalCount: item.other.rentalCount,
      otherMonthlyCount: item.other.monthlyCount,
      othertotalCount: item.other.totalCount,

      otherBuyingTradeCount: item.otherBuying.tradeCount,
      otherBuyingRentalCount: item.otherBuying.rentalCount,
      otherBuyingMonthlyCount: item.otherBuying.monthlyCount,
      otherBuyingTotalCount: item.otherBuying.totalCount,

      wdbTradeCount: item.wdb.tradeCount,
      wdbRentalCount: item.wdb.rentalCount,
      wdbMonthlyCount: item.wdb.monthlyCount,
      wdbTotalCount: item.wdb.totalCount,

      tradeRate: item.tradeRate,
      rentalRate: item.rentalRate,
      monthlyRentalRate: item.monthlyRentalRate,
      totalRate: item.totalRate,
    };
  });

  result?.push({
    id: "total",
    path: ["합계"],
    sigunguCode: "",
    sigunguName: "합계",

    otherTradeCount: otherTradeCountTotal,
    otherRentalCount: otherRentalCountTotal,
    otherMonthlyCount: otherMonthlyCountTotal,
    othertotalCount: othertotalCountTotal,

    otherBuyingTradeCount: otherBuyingTradeCountTotal,
    otherBuyingRentalCount: otherBuyingRentalCountTotal,
    otherBuyingMonthlyCount: otherBuyingMonthlyCountTotal,
    otherBuyingTotalCount: otherBuyingTotalCountTotal,

    wdbTradeCount: wdbTradeCountTotal,
    wdbRentalCount: wdbRentalCountTotal,
    wdbMonthlyCount: wdbMonthlyCountTotal,
    wdbTotalCount: wdbTotalCountTotal,

    tradeRate:
      Math.round(
        (wdbTradeCountTotal /
          (wdbTradeCountTotal + otherBuyingTradeCountTotal)) *
          100 *
          100
      ) / 100,
    rentalRate:
      Math.round(
        (wdbRentalCountTotal /
          (wdbRentalCountTotal + otherBuyingRentalCountTotal)) *
          100 *
          100
      ) / 100,
    monthlyRentalRate:
      Math.round(
        (wdbMonthlyCountTotal /
          (wdbMonthlyCountTotal + otherBuyingMonthlyCountTotal)) *
          100 *
          100
      ) / 100,
    totalRate:
      Math.round(
        (wdbTotalCountTotal /
          (wdbTotalCountTotal + otherBuyingTotalCountTotal)) *
          100 *
          100
      ) / 100,
  });

  return result ?? [];
};
