import {
  AssignedDistrictDto,
  BranchInfo,
  BranchSummaryDataRequest,
  BrunchTotalData,
} from "../../interface/Branch";
import { get, ResponseWrapper, WebResponse } from "../../module/HTTPWebClient";

export async function getBranchList(): Promise<WebResponse<BranchInfo[]>> {
  try {
    const url = `/company-groups`;
    const { data } = await get<ResponseWrapper<BranchInfo[]>>(url);

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

interface BranchSummaryAssignedDistrictDataResponse extends BranchInfo {
  assignDistrictData: AssignedDistrictDto[];
}
export async function getBranchAssignedDistrictSummaryData({
  companyGroupNo,
  year,
}: BranchSummaryDataRequest): Promise<
  WebResponse<BranchSummaryAssignedDistrictDataResponse>
> {
  try {
    const url = `/company-groups/summary`;
    const { data } = await get<
      ResponseWrapper<BranchSummaryAssignedDistrictDataResponse>
    >(url, {
      params: {
        companyGroupNo,
        year,
      },
    });
    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getBranchTotalSummaryData({
  companyGroupNo,
  year,
}: BranchSummaryDataRequest): Promise<WebResponse<BrunchTotalData[]>> {
  try {
    const url = `/company-groups/summary/total`;
    const { data } = await get<ResponseWrapper<BrunchTotalData[]>>(url, {
      params: {
        companyGroupNo,
        year,
      },
    });
    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}
