import React, { ChangeEvent, ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  MenuItem,
  TextField,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import SelectGroup from "../../../components/select/SelectGroup";
import WdbSelect from "../../../components/select/WdbSelect";
import IconInfo from "../../../components/IconInfo";
import { getSelectYearOptions } from "../../../utils";
import { selectableDateFilterOptionList } from "../../../static/optionList";
import { MamagerOfBranchRequest } from "../../../interface/Manager";
import { BranchInfo } from "../../../interface/Branch";
import "dayjs/locale/ko";

type SelectGroupContainerProps = {
  tabValue: string;
  currentYear: number;
  selectedYear: number;
  selectedValue: string;
  selectedBranchNo: number | undefined;
  selectedManagerNo: number | undefined;
  selectableBranchList: BranchInfo[];
  selectedManagerList: MamagerOfBranchRequest[];
  selectedDate: string | null;
  selectedRangePickerFilter: string;
  rangePickerValue: [Dayjs | null, Dayjs | null];
  toggleRadio: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onChangeDatePicker: any;
  onChangeManagerOfBranch: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => void;
  onChangeYear: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => void;
  onChangeAccount: (event: SelectChangeEvent<unknown>) => void;
  onChangeBranch: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => void;
  onChangeRangePickerFilter: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => void;
  onChangeRangePicker: (
    date: DateRange<Dayjs>,
    keyboardInputValue?: string
  ) => void;
};
export default function SelectGroupContainer({
  tabValue,
  currentYear,
  selectedYear,
  selectedValue,
  selectedBranchNo,
  selectedManagerNo,
  selectableBranchList,
  selectedManagerList,
  selectedRangePickerFilter,
  rangePickerValue,
  toggleRadio,
  selectedDate,
  onChangeDatePicker,
  onChangeManagerOfBranch,
  onChangeYear,
  onChangeAccount,
  onChangeBranch,
  onChangeRangePickerFilter,
  onChangeRangePicker,
}: SelectGroupContainerProps): ReactElement {
  return (
    <>
      {tabValue === "MONTH_TAB" && (
        <SelectGroup label="중개매니저 개인지표">
          <WdbSelect
            label={"연도"}
            value={selectedYear}
            onChange={onChangeYear}
            width={200}
          >
            {getSelectYearOptions(2023, currentYear).map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </WdbSelect>
          <WdbSelect
            label={"지점명"}
            value={selectedBranchNo}
            onChange={onChangeManagerOfBranch}
            width={200}
          >
            {selectableBranchList.map((branch: BranchInfo) => (
              <MenuItem
                key={branch.companyGroupNo}
                value={branch.companyGroupNo}
              >
                {branch.companyGroupNo === 0
                  ? `${branch.companyGroupName}`
                  : `${branch.companyGroupNo}.${branch.companyGroupName}`}
              </MenuItem>
            ))}
          </WdbSelect>
          <WdbSelect
            label={"중개매니저"}
            value={selectedManagerNo}
            onChange={onChangeAccount}
            width={200}
          >
            {selectedManagerList?.map((option: MamagerOfBranchRequest) => (
              <MenuItem key={option.accountInfoNo} value={option.accountInfoNo}>
                {option.accountName}
              </MenuItem>
            ))}
          </WdbSelect>
        </SelectGroup>
      )}
      {tabValue === "DATE_TAB" && (
        <SelectGroup
          label={
            <>
              {" "}
              <span>기간 </span>
              <IconInfo title="2023-06-06 ~ 어제까지 조회 가능" />
            </>
          }
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            <DatePicker
              label="기간 설정"
              value={selectedDate}
              onChange={onChangeDatePicker}
              renderInput={(params) => <TextField size="small" {...params} />}
              inputFormat={"YYYY-MM-DD"}
              mask={"____-__-__"}
              shouldDisableDate={(day: Dayjs) => {
                const startDate = dayjs("2022-12-25", "YYYY-MM-DD");
                const endDate = dayjs().subtract(1, "d");
                return day < startDate || day > endDate;
              }}
            />
          </LocalizationProvider>
          <WdbSelect
            label={"지점명"}
            value={selectedBranchNo}
            onChange={onChangeBranch}
            width={200}
          >
            {selectableBranchList.map((branch: BranchInfo) => (
              <MenuItem
                key={branch.companyGroupNo}
                value={branch.companyGroupNo}
              >
                {branch.companyGroupNo === 0
                  ? `${branch.companyGroupName}`
                  : `${branch.companyGroupNo}.${branch.companyGroupName}`}
              </MenuItem>
            ))}
          </WdbSelect>
        </SelectGroup>
      )}
      {tabValue === "PERIOD_TAB" && (
        <SelectGroup
          label={
            <>
              <span>기간 </span>
              <IconInfo title="2023-06-06 ~ 어제까지 조회 가능" />
            </>
          }
        >
          <WdbSelect
            label={"기간 설정"}
            value={selectedRangePickerFilter}
            onChange={onChangeRangePickerFilter}
            width={200}
          >
            {selectableDateFilterOptionList.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </WdbSelect>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "시작일", end: "종료일" }}
            adapterLocale={"ko"}
          >
            <DateRangePicker
              value={rangePickerValue}
              onChange={onChangeRangePicker}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    {...startProps}
                    size="small"
                    style={{ width: "200px" }}
                  />
                  <Box sx={{ mx: 2 }}> ~ </Box>
                  <TextField
                    {...endProps}
                    size="small"
                    style={{ width: "200px" }}
                  />
                </React.Fragment>
              )}
              shouldDisableDate={(day: Dayjs) => {
                const startDate = dayjs("2023-06-06", "YYYY-MM-DD");
                const endDate = dayjs().subtract(1, "d");
                return day < startDate || day > endDate;
              }}
            />
          </LocalizationProvider>
          <WdbSelect
            label={"지점명"}
            value={selectedBranchNo}
            onChange={onChangeManagerOfBranch}
            width={200}
          >
            {selectableBranchList.map((branch: BranchInfo) => (
              <MenuItem
                key={branch.companyGroupNo}
                value={branch.companyGroupNo}
              >
                {branch.companyGroupNo === 0
                  ? `${branch.companyGroupName}`
                  : `${branch.companyGroupNo}.${branch.companyGroupName}`}
              </MenuItem>
            ))}
          </WdbSelect>
          <WdbSelect
            label={"중개매니저"}
            value={selectedManagerNo}
            onChange={onChangeAccount}
            width={200}
          >
            {selectedManagerList?.map((option: MamagerOfBranchRequest) => (
              <MenuItem key={option.accountInfoNo} value={option.accountInfoNo}>
                {option.accountName}
              </MenuItem>
            ))}
          </WdbSelect>
          <Box sx={{ display: "flex" }}>
            <BoxWrap>
              <Radio
                checked={selectedValue === "일간별"}
                onChange={toggleRadio}
                value="일간별"
                name="radio-buttons"
              />
              <span>일간별</span>
            </BoxWrap>
            {/* <BoxWrap>
              <Radio
                checked={selectedValue === "주간별"}
                onChange={toggleRadio}
                value="주간별"
                name="radio-buttons"
              />
              <span>주간별</span>
            </BoxWrap> */}
          </Box>
        </SelectGroup>
      )}
    </>
  );
}

const BoxWrap = styled.div`
  display: flex;
  align-items: center;

  > span {
    width: 50px;
  }
`;
