import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

export interface IconInfoProps {
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  title?: string;
}

IconInfo.defaultProps = {
  placement: "bottom",
};

function IconInfo(props: IconInfoProps) {
  return (
    <>
      <Wrapper>
        <Tooltip
          placement={props.placement && props.placement}
          title={props.title}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.span`
  svg:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export default IconInfo;
