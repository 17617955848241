import {
  TransactionRequest,
  TransactionResponse,
} from "./../../interface/Transaction";
import { get, WebResponse } from "../../module/HTTPWebClient";
import { useEffect, useState } from "react";

export async function getTransactionApi({
  tradeType,
  contractYear,
  contractMonth,
  bjdongCd,
  page,
  search,
  sidoCd,
  sigunguCode,
  size,
}: TransactionRequest): Promise<WebResponse<TransactionResponse>> {
  try {
    const url = "/real-trade-prices";
    const data = await get<{
      data: TransactionResponse;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        tradeType,
        contractYear,
        contractMonth,
        bjdongCd,
        page,
        search: search ? search : null,
        sidoCd,
        sigunguCode: sigunguCode ? sigunguCode : null,
        size,
      },
    });

    return {
      data: data.data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}
