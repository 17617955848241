import React from "react";
import styled from "styled-components";
import { Alert } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridColDef } from "@mui/x-data-grid";

import { getFormattedMonth, getEndOfMonth } from "../../utils/dateUtils";
import { ManagerDataRequest } from "../../interface/Manager";
import { COMMON_COLUMNS_OPTIONS } from "../../containers/brokerageCommissionSize/utils/tableResourceFormatter";
import { getFormattedManagerDataRow } from "../../containers/branchSummaryPage/utils/getFormattedManagerDataRow";

type TabContentsProps = {
  year: number;
  gridData: ManagerDataRequest;
  loading: boolean;
  selectedManagerNo: number | undefined;
};

export default function TabContents({
  year,
  gridData,
  loading,
  selectedManagerNo,
}: TabContentsProps) {
  const columns: GridColDef[] = [
    {
      field: "title1",
      headerName: "이름",
      minWidth: 180,
      sortable: false,
      align: "center",
    },
    {
      field: "title",
      headerName: "",
      minWidth: 250,
      sortable: false,
      align: "center",
    },
    ...Array.from({ length: 12 }, (_, index) => {
      const month = index + 1;

      return {
        field: `${getFormattedMonth(getEndOfMonth(year, month))}`,
        flex: 1 / 12,
      };
    }),
  ];

  const formattedColumns: GridColDef[] = columns.map((item) => {
    const result: GridColDef = {
      ...item,
      ...COMMON_COLUMNS_OPTIONS,
      align: item.align ?? COMMON_COLUMNS_OPTIONS.align,
      headerAlign: item.headerAlign ?? COMMON_COLUMNS_OPTIONS.headerAlign,
    };
    return result;
  });

  return (
    <Warpper>
      {
        <>
          {gridData.accountName && selectedManagerNo ? (
            <GridWrapper>
              <DataGridPro
                experimentalFeatures={{ columnGrouping: true }}
                columns={formattedColumns}
                rows={getFormattedManagerDataRow(year, gridData)}
                rowHeight={40}
                disableSelectionOnClick
                loading={loading}
              />
            </GridWrapper>
          ) : (
            <Alert severity="error">중개매니저까지 선택해주세요.</Alert>
          )}
        </>
      }
    </Warpper>
  );
}

const Warpper = styled.div``;

const GridWrapper = styled.div`
  width: 100%;
  height: 80vh;

  div [data-field="title1"] {
    border-bottom: 4px solid #fff;
    border-right: 2px solid #f0f0f0;
  }

  div [data-field="title"] {
    border-right: 4px double #f0f0f0;
  }

  div [data-field="1월"],
  div [data-field="2월"],
  div [data-field="3월"],
  div [data-field="4월"],
  div [data-field="5월"],
  div [data-field="6월"],
  div [data-field="7월"],
  div [data-field="8월"],
  div [data-field="9월"],
  div [data-field="10월"],
  div [data-field="11월"] {
    border-right: 2px solid #f0f0f0;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #f0f0f0;
    }
  }
`;
