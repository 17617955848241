import * as React from "react";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      border: `1px solid ${theme.palette.divider}`,
      position: "relative",
      overflow: "hidden",
      width: "100%",
      height: 26,
      borderRadius: 2,
    },
    value: {
      position: "absolute",
      lineHeight: "24px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    bar: {
      height: "100%",
      "&.low": {
        backgroundColor: "#abf8ab9f",
      },
      "&.medium": {
        backgroundColor: "#abf8ab9f",
      },
      "&.high": {
        backgroundColor: "#abf8ab9f",
      },
    },
  };
});

interface ProgressBarProps {
  value: number;
}

function GridProgressBar(props: ProgressBarProps) {
  const { value } = props;
  const goalPercent = 1;
  const valueInPercent = value * (100 / goalPercent);
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.value}>{`${value.toLocaleString()} %`}</div>
      <div
        className={clsx(classes.bar, {
          low: value < 7,
          medium: value >= 7 && value <= 15,
          high: value > 15,
        })}
        style={{ maxWidth: `${value}%` }}
      />
    </div>
  );
}

export default GridProgressBar;
