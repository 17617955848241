import styled from "styled-components";
import { CssDefaultProps } from "../interface/CommonInterface";
import { FlexDirection } from "./atoms/layout/FlexWrapper";
import { font } from "../styles/font";

const SearchBoxWrapper = styled.div<SearchBoxProps>`
  font-size: ${font.px14};
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "auto")};
  background: ${(props) => props.theme.bgColorLight};
  color: ${(props) => props.theme.colorLight};
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

interface SearchBoxProps extends CssDefaultProps {
  children: React.ReactNode;
  flexDirection?: FlexDirection;
  minHeight?: string;
}

export function SearchBox(props: SearchBoxProps) {
  return (
    <SearchBoxWrapper minHeight={props.minHeight}>
      {props.children}
    </SearchBoxWrapper>
  );
}
