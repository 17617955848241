import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { GridRowModel } from "@mui/x-data-grid";
import { useState } from "react";
import WdbIcon from "../../../components/WdbIcon";
import DialogSimple from "./DialogSimple";

function CompanyGroupPopover(row: GridRowModel) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <WdbIcon icon={faInfoCircle} onClick={handleClick} />
      <DialogSimple row={row} open={open} onClose={handleClose} />
    </>
  );
}

export default CompanyGroupPopover;
