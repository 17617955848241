import { AxiosResponse } from "axios";
import {
  wdbTotalIndicatorsRequest,
  CompanyGroupRequest,
} from "./../../interface/CommonRequest";
import { API_BUYING_ACQUISITION_RATE } from "../../config";
import { RequiredRequest } from "../../interface/CommonRequest";
import {
  BuyingAcquisitionRateDto,
  BuyingChartRequest,
  BuyingChartResponse,
  BuyingCompanyGroup,
  BuyingResponse,
} from "../../interface/Guest";
import { get, WebResponse } from "../../module/HTTPWebClient";
import clientAxios from "./customAxios";

export async function getGuestApi({
  areaCd,
  startDate,
  endDate,
}: wdbTotalIndicatorsRequest): Promise<WebResponse<BuyingAcquisitionRateDto>> {
  try {
    const url = "/buyings/acquisition-rate";
    const { data } = await get<{
      data: BuyingAcquisitionRateDto;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        areaCd,
        startDate,
        endDate,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getGuestCompanyGroupApi({
  sigunguCode,
  startDate,
  endDate,
}: CompanyGroupRequest): Promise<WebResponse<BuyingCompanyGroup[]>> {
  try {
    const url = "/buyings/company-group";
    const { data } = await get<{
      data: BuyingCompanyGroup[];
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        sigunguCode,
        startDate,
        endDate,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getGuestChartApi({
  areaCd,
  endYearMonth,
  startYearMonth,
}: BuyingChartRequest): Promise<WebResponse<BuyingChartResponse>> {
  try {
    const url = `/buyings/acquisition-rate/chart`;
    const { data } = await get<{
      data: BuyingChartResponse;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        areaCd,
        endYearMonth,
        startYearMonth,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}
