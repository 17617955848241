import { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

interface SelectGroupProps {
  label: ReactNode;
  minWidth: string;
}
function SelectGroup({
  label,
  children,
  minWidth,
}: PropsWithChildren<SelectGroupProps>) {
  return (
    <SelectGroupWrapper minWidth={minWidth}>
      <LabelWrapper>{label}</LabelWrapper>
      {children}
    </SelectGroupWrapper>
  );
}

export default SelectGroup;

SelectGroup.defaultProps = {
  minWidth: "auto",
};

const SelectGroupWrapper = styled.div<Pick<SelectGroupProps, "minWidth">>`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: ${(props) => props.minWidth || "auto"};

  & .MuiFormControl-root {
    min-width: 140px;
  }

  & .MuiInputBase-root > * {
    font-size: 0.9rem;
  }
`;

const LabelWrapper = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  padding-right: 4px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 4px;
`;
