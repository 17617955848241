import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentBox } from "../components/ContentBox";
import IconInfo from "../components/IconInfo";
import { SearchBox } from "../components/SearchBox";
import SelectGroup from "../components/select/SelectGroup";
import WdbSelect from "../components/select/WdbSelect";
import SalesHoldingTabContents from "../containers/salesHolding/components/SalesHoldingTabContents";
import {
  getChartColor,
  getFormattedSalesHoldingNaverChartData,
  getFormattedSalesHoldingWdbChartData,
} from "../containers/salesHolding/utils/chartResourceFormatter";
import {
  getFormattedSalesHoldingComplexData,
  getFormattedSalesHoldingData,
} from "../containers/salesHolding/utils/tableResourceFormatter";
import useAreaController from "../hook/useAreaController";
import useDatePickerController from "../hook/useDatePickerController";
import useRangePickerController from "../hook/useRangePickerController";
import useSalesHolding from "../hook/useSalesHolding";
import {
  ChartTradeType,
  SelectOption,
  TradeType,
} from "../interface/CommonInterface";
import { SearchType } from "../interface/CommonRequest";
import {
  selectableDateFilterOptionList,
  selectableSidoOptionList,
} from "../static/optionList";
import { useQueries } from "@tanstack/react-query";
import {
  getSalesHoldingApi,
  getSalesHoldingComplexApi,
} from "../store/api/salesHoldingApi";
import { ChartDataSets } from "../interface/chartFormatter";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ko";

export type SalesHoldingTabType = "DATE_TAB" | "PERIOD_TAB";

const tabs: { label: string; value: SalesHoldingTabType }[] = [
  {
    label: "날짜조회",
    value: "DATE_TAB",
  },
  {
    label: "기간조회",
    value: "PERIOD_TAB",
  },
];

const INIT_TRADE_TYPE: { [tradeType in TradeType]: boolean } = {
  total: true,
  trade: true,
  rental: true,
  monthlyRental: true,
};

function SalesHoldingPage() {
  const {
    onChangeIncludingOfficetels,

    includingOfficetels,

    getSalesHoldingChartData,
    resultChartData,
    resultChartData2,
    wdbChartData,
    setWdbChartData,
    naverChartData,
    setNaverChartData,
  } = useSalesHolding();

  const {
    selectableSigunguList,
    selectableBjdongList,

    selectedSido,
    selectedSigungu,
    selectedBjdong,

    setSelectedSido,
    setSelectedSigungu,
    setSelectedBjdong,

    resetSigungu,
    resetBjdong,
    resetBjdongList,

    onChangeSido,
    onChangeSigungu,
    onChangeBjdong,
  } = useAreaController();

  const { datePickerValue, onChangeDatePicker, selectedDate } =
    useDatePickerController();

  const {
    selectedRangePickerFilter,
    onChangeRangePickerFilter,
    rangePickerValue,
    onChangeRangePicker,
    startDate,
    endDate,
  } = useRangePickerController(dayjs("2022-12-25", "YYYY-MM-DD"));

  const [tabValue, setTabValue] = useState<SalesHoldingTabType>("DATE_TAB");
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: SalesHoldingTabType
  ) => {
    setTabValue(newValue);
  };

  const [chartTradeTypeList, setChartTradeTypeList] =
    useState<ChartTradeType>(INIT_TRADE_TYPE);
  const [selectedAreaChips, setSelectedAreaChips] = useState<SelectOption[]>([
    {
      value: selectedSido,
      label: "전체",
    },
  ]);

  useEffect(() => {
    getSalesHoldingChartData(
      {
        searchStartDate: startDate,
        searchEndDate: endDate,
        areaCd: selectedAreaChips[0].value,
        includingOfficetels,
      },
      1
    );
  }, []);

  useEffect(() => {
    const wdbChart1 = getFormattedSalesHoldingWdbChartData(resultChartData);
    const naverChart1 = getFormattedSalesHoldingNaverChartData(resultChartData);

    const wdbChart2 = getFormattedSalesHoldingWdbChartData(resultChartData2);
    const naverChart2 =
      getFormattedSalesHoldingNaverChartData(resultChartData2);

    if (selectedAreaChips.length > 1) {
      const wdbTempChart = [...wdbChart1.datasets];
      const naverTempChart = [...naverChart1.datasets];
      wdbChart2.datasets.map((item: ChartDataSets) => {
        wdbTempChart.push({
          data: item.data,
          label: item.label,
          borderColor: getChartColor(item.label),
          backgroundColor: getChartColor(item.label),
          borderDash: [5, 5],
        });
      });

      naverChart2.datasets.map((item: ChartDataSets) => {
        naverTempChart.push({
          data: item.data,
          label: item.label,
          borderColor: getChartColor(item.label),
          backgroundColor: getChartColor(item.label),
          borderDash: [5, 5],
        });
      });

      setWdbChartData({
        ...wdbChart1,
        datasets: wdbTempChart,
      });
      setNaverChartData({
        ...naverChart1,
        datasets: naverTempChart,
      });
    } else {
      setWdbChartData(wdbChart1);
      setNaverChartData(naverChart1);
    }
  }, [resultChartData, resultChartData2]);

  useEffect(() => {
    const areaCd1 = selectedAreaChips[0].value;

    // 차트 2개까지 보이기 때문에 최대 케이스 2
    switch (selectedAreaChips.length) {
      default:
        getSalesHoldingChartData(
          {
            searchStartDate: startDate,
            searchEndDate: endDate,
            areaCd: areaCd1,
            includingOfficetels,
          },
          selectedAreaChips.length
        );
        break;
      case 2:
        const areaCd2 = selectedAreaChips[1].value;
        getSalesHoldingChartData(
          {
            searchStartDate: startDate,
            searchEndDate: endDate,
            areaCd: areaCd1,
            includingOfficetels,
          },
          1
        );
        getSalesHoldingChartData(
          {
            searchStartDate: startDate,
            searchEndDate: endDate,
            areaCd: areaCd2,
            includingOfficetels,
          },
          2
        );
        break;
    }
  }, [selectedAreaChips, rangePickerValue, includingOfficetels]);

  useEffect(() => {
    setSelectedAreaChips([
      {
        value: selectedSido,
        label: "전체",
      },
    ]);
  }, [selectableSigunguList]);

  const results = useQueries({
    queries: [
      {
        queryKey: [
          "getSalesHoldingData",
          selectedSido,
          selectedSigungu,
          selectedBjdong,
          datePickerValue,
          includingOfficetels,
        ],
        queryFn: async () => {
          let searchType: SearchType = "SIGUNGU";
          let areaCd = "";

          if (selectedSido === "전체" && selectedSigungu === "전체") {
            searchType = "SIDO";
          } else if (selectedSido !== "전체" && selectedSigungu === "전체") {
            searchType = "SIGUNGU";
            areaCd = selectedSido;
          } else if (selectedSigungu !== "전체" && selectedBjdong === "전체") {
            searchType = "BJDONG";
            areaCd = selectedSigungu;
          } else {
            searchType = "COMPLEX";
            areaCd = selectedSigungu + selectedBjdong;
          }

          const response = await getSalesHoldingApi({
            searchDate: selectedDate,
            searchType,
            areaCd,
            includingOfficetels,
          });
          if (response.error) return;
          return response;
        },
        refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
        retry: 0, // 실패시 재호출 몇번 할지
      },
      {
        queryKey: [
          "getSalesHoldingComplexData",
          selectedSido,
          selectedSigungu,
          selectedBjdong,
          datePickerValue,
          includingOfficetels,
        ],
        queryFn: async () => {
          let searchType: SearchType = "SIGUNGU";
          let areaCd = "";

          if (selectedSido === "전체" && selectedSigungu === "전체") {
            searchType = "SIDO";
          } else if (selectedSido !== "전체" && selectedSigungu === "전체") {
            searchType = "SIGUNGU";
            areaCd = selectedSido;
          } else if (selectedSigungu !== "전체" && selectedBjdong === "전체") {
            searchType = "BJDONG";
            areaCd = selectedSigungu;
          } else {
            searchType = "COMPLEX";
            areaCd = selectedSigungu + selectedBjdong;
          }

          const response = await getSalesHoldingComplexApi({
            searchDate: selectedDate,
            searchType,
            areaCd,
            includingOfficetels,
          });
          if (response.error) return;
          return response;
        },
        refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
        retry: 0, // 실패시 재호출 몇번 할지
      },
    ],
  });

  const salesHoldingData = results[0];
  const salesHoldingComplexData = results[1];
  return (
    <SalesHoldingWrapper>
      <SearchBox>
        <SelectGroup
          label={
            <>
              <span>기간 </span>
              <IconInfo title="2022-12-25 ~ 어제까지 조회 가능" />
            </>
          }
        >
          {tabValue === "DATE_TAB" && (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
              <DatePicker
                label="기간 설정"
                value={datePickerValue}
                onChange={onChangeDatePicker}
                renderInput={(params) => <TextField size="small" {...params} />}
                inputFormat={"YYYY-MM-DD"}
                mask={"____-__-__"} // warning 방지
                shouldDisableDate={(day: Dayjs) => {
                  const startDate = dayjs("2022-12-25", "YYYY-MM-DD");
                  const endDate = dayjs().subtract(1, "d");

                  return day < startDate || day > endDate;
                }}
              />
            </LocalizationProvider>
          )}
          {tabValue === "PERIOD_TAB" && (
            <>
              <WdbSelect
                label={"기간 설정"}
                value={selectedRangePickerFilter}
                onChange={onChangeRangePickerFilter}
              >
                {selectableDateFilterOptionList.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </WdbSelect>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: "시작일", end: "종료일" }}
                adapterLocale={"ko"}
              >
                <DateRangePicker
                  value={rangePickerValue}
                  onChange={onChangeRangePicker}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} size="small" />
                      <Box sx={{ mx: 2 }}> ~ </Box>
                      <TextField {...endProps} size="small" />
                    </React.Fragment>
                  )}
                  shouldDisableDate={(day: Dayjs) => {
                    const startDate = dayjs("2022-12-25", "YYYY-MM-DD");
                    const endDate = dayjs().subtract(1, "d");

                    return day < startDate || day > endDate;
                  }}
                />
              </LocalizationProvider>
            </>
          )}
        </SelectGroup>

        <SelectGroup label={<span>지역 </span>}>
          <WdbSelect
            label={"시/도"}
            value={selectedSido}
            onChange={onChangeSido}
          >
            {selectableSidoOptionList.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </WdbSelect>
          {tabValue === "DATE_TAB" && (
            <>
              <WdbSelect
                label={"시/군/구"}
                value={selectedSigungu}
                onChange={onChangeSigungu}
              >
                {selectableSigunguList.length > 0 ? (
                  selectableSigunguList.map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))
                ) : (
                  <MenuItem value="전체">전체</MenuItem>
                )}
              </WdbSelect>

              <WdbSelect
                label={"읍/면/동"}
                value={selectedBjdong}
                onChange={onChangeBjdong}
              >
                {selectableBjdongList.length > 0 ? (
                  selectableBjdongList.map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))
                ) : (
                  <MenuItem value="전체">전체</MenuItem>
                )}
              </WdbSelect>
            </>
          )}
        </SelectGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={includingOfficetels}
              size="small"
              onChange={onChangeIncludingOfficetels}
            />
          }
          label="오피스텔 포함"
        />
      </SearchBox>

      <ContentBox>
        <SalesHoldingTap value={tabValue} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </SalesHoldingTap>
        <SalesHoldingTabContents
          tabValue={tabValue}
          sidoSelect={selectableSidoOptionList.find(
            (option) => option.value === selectedSido
          )}
          sigunguSelect={selectableSigunguList.find(
            (option) => option.value === selectedSigungu
          )}
          bjdongSelect={selectableBjdongList.find(
            (option) => option.value === selectedBjdong
          )}
          onClickSigunguXButton={() => {
            resetSigungu();
            resetBjdongList();
          }}
          onClickBjdongXButton={resetBjdong}
          gridData={
            selectedBjdong === "전체"
              ? getFormattedSalesHoldingData(salesHoldingData.data?.data)
              : getFormattedSalesHoldingComplexData(
                  salesHoldingComplexData.data?.data
                )
          }
          naverScrapLastDate={salesHoldingData.data?.data.naverScrapLastDate}
          selectedDate={selectedDate}
          setSelectedSido={setSelectedSido}
          setSelectedSigungu={setSelectedSigungu}
          setSelectedBjdong={setSelectedBjdong}
          wdbChartData={wdbChartData}
          naverChartData={naverChartData}
          selectableSigunguList={selectableSigunguList}
          selectableBjdongList={selectableBjdongList}
          loading={salesHoldingData.isLoading}
          chartTradeTypeList={chartTradeTypeList}
          setChartTradeTypeList={setChartTradeTypeList}
          selectedAreaChips={selectedAreaChips}
          setSelectedAreaChips={setSelectedAreaChips}
        />
      </ContentBox>
    </SalesHoldingWrapper>
  );
}

export default SalesHoldingPage;

const SalesHoldingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SalesHoldingTap = styled(Tabs)({
  marginBottom: "15px",
});
