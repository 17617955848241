import { ListItem, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { productionMenu } from "../static/menu";

export function MainPage() {
  return (
    <MainWrapper>
      <Paper>
        <Title>공지사항</Title>
      </Paper>
      <Paper>
        <Title>메뉴 바로가기</Title>
        {productionMenu.map((item, index) => {
          return item.dropdownContent.map((menuItem) => (
            <ListItem>
              <NavLink to={menuItem.link as string} key={menuItem.listName}>
                {menuItem.listName}
              </NavLink>
            </ListItem>
          ));
        })}
      </Paper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  display: flex;
  gap: 24px;
  & .MuiPaper-root {
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    & li {
      border: 1px solid lightgray;
      border-radius: 8px;
      &:hover {
        border: 1px solid darkblue;
        & * {
          color: darkblue;
          font-weight: 700;
        }
      }
      & a {
        font-size: 1rem;
        width: 100%;
      }
    }
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
`;
