import {
  faBars,
  faMagnifyingGlass,
  faMoon,
  faSun,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Default, Desktop } from "../hook/mediaQuery";
import { useSessionStore } from "../hook/storeHook";
import { useDarkMode, useMenu } from "../hook/useMenu";
import { font } from "../styles/font";

const MainHeaderWrapper = styled.div<{ isMenu?: boolean }>`
  height: var(--header-height);
  border-bottom: 1px solid #ddd;
  padding: 20px;

  color: ${(props) => props.theme.color};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-title {
    font-size: ${font.px20};
  }

  input {
    border: none;
    border-radius: 10px;
    padding: 15px 10px;
    background: #dedede4d;
    font-size: 14px;
    padding-right: 45px;
  }
  input:focus {
    outline: none;
  }
  .header-title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 20px;
      cursor: pointer;
      width: 18px;
      height: 18px;
      font-size: 20px;
      border-radius: 50%;
      padding: 10px;
      &:hover {
        opacity: 0.8;
        background-color: #ddd;
      }
    }
  }
  .right-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    svg {
      &:hover {
        opacity: 0.9;
      }
      cursor: pointer;
    }
    button:hover {
      box-shadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;";
    }
    .icon-section {
      svg {
        margin: 0 10px;
      }
    }
  }
  .search-box {
    display: flex;
    align-items: center;
    svg {
      position: relative;
      right: 35px;
    }
  }
  .exit {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    svg {
      margin-right: 0.5vw;
    }
  }

  .user-name {
    font-size: ${font.px14};
    margin: 0 3vw;
  }

  .toggleSwitch {
    width: 3rem;
    height: 1.5rem;
    margin: 0 10px;
    display: block;
    position: relative;
    border-radius: 2rem;
    background-color: darkblue;
    box-shadow: 0 0 1rem 3px rgba(0 0 0 / 5%);
    opacity: 0.8;
    cursor: pointer;

    .moon-icon {
      position: absolute;
      top: 15%;
      right: 13%;
      color: white;
    }

    .sun-icon {
      position: absolute;
      top: 15%;
      left: 13%;
      color: white;
    }
  }

  /* 토글 버튼 */
  .toggleSwitch .toggleButton {
    /* 버튼은 토글보다 작아야함  */
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    left: 0.2rem;
    transform: translateY(-50%);
    border-radius: 50%;
    background: white;
  }

  .toggleSwitch.active {
    background: #fdb813;
  }

  .toggleSwitch.active .toggleButton {
    left: calc(100% - 1.2rem);
    background: #fff;
  }

  .toggleSwitch,
  .toggleButton {
    transition: all 0.2s ease-in;
  }
`;

export const useMainHeader = () => {
  const location = useLocation();
  const headerTitle = () => {
    switch (location.pathname) {
      case "/":
        return "데이터 센터";

      case "/public-data/transaction":
        return "국토교통부 실거래 데이터";
      case "/public-data/transaction-count":
        return "국토교통부 실거래 데이터(건수)";
      case "/public-data/commission-size":
        return "실거래 기반 중개수수료 규모 - 아파트";

      case "/total/sales-holding":
        return "매물 보유율";
      case "/total/guest":
        return "손님 확보율";
      case "/total/trade-rate":
        return "거래 비율";

      case "/branch/data":
        return "지점별 데이터";
      case "/branch/manager":
        return "중개매니저 데이터";
      case "/branch/total-summary":
        return "지점 전체 요약 지표";

      case "/dashboard":
        return "dashboard";
      case "/trading":
        return "거래량";
      default:
        return "제목 없음";
    }
  };

  const title = headerTitle();

  return { headerTitle, title };
};

export function MainHeader() {
  const { title } = useMainHeader();
  const { isMenu, setMenu } = useMenu();
  const { isDarkMode, setDarkMode } = useDarkMode();

  const { userInfo } = useSessionStore();
  const navigate = useNavigate();
  const [endState, setEndState] = useState("종료");

  const [toggleActive, setToggleActive] = useState("");

  return (
    <>
      <MainHeaderWrapper isMenu={isMenu}>
        <div className="header-title">
          {isMenu ? (
            <FontAwesomeIcon
              icon={faX}
              color="#eceff1"
              onClick={() => {
                setMenu(false);
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => {
                setMenu(true);
              }}
            />
          )}

          <span className="header-title">{title}</span>
        </div>

        <div className="right-section">
          <Desktop>
            <div className="search-box">
              <input type="text" placeholder="search.." />
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
          </Desktop>
          <Default>
            <div>
              <input type="checkbox" id="toggle" hidden />

              <label
                htmlFor="toggle"
                className={"toggleSwitch " + toggleActive}
                onClick={() => {
                  toggleActive === ""
                    ? (setToggleActive("active"), setDarkMode(true))
                    : (setToggleActive(""), setDarkMode(false));
                }}
              >
                <span className="toggleButton"></span>

                {toggleActive === "" ? (
                  <FontAwesomeIcon icon={faMoon} className="moon-icon" />
                ) : (
                  <FontAwesomeIcon icon={faSun} className="sun-icon" />
                )}
              </label>
            </div>
          </Default>

          <span className="user-name">{userInfo.name} 님</span>

          <span
            className="exit"
            onClick={() => {
              sessionStorage.clear();
              navigate("/login-guide");
            }}
          >
            {endState}
          </span>
        </div>
      </MainHeaderWrapper>
    </>
  );
}
