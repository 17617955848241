import { GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid";
import WdbTooltip from "../../../components/tooltip/WdbTooltip";
import { getValueFormattedPercent } from "../../../utils/gridUtils";
import GroupHeaderChip from "../../../components/table/GroupHeaderChip";
import { color } from "../../../styles/color";

export const tradeRateColums: GridColDef[] = [
  {
    field: "sigunguName",
  },
  {
    field: "wdbTradeCount",
    headerName: "매매",
    headerAlign: "center",
    headerClassName: "wdb-trade-count",
    cellClassName: "wdb-trade-count",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
  },
  {
    field: "wdbRentalCount",
    headerName: "전세",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
  },
  {
    field: "wdbMonthlyCount",
    headerName: "월세",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
  },
  {
    field: "wdbTotalCount",
    headerName: "전체",
    headerAlign: "center",
    headerClassName: "wdb-total-count",
    cellClassName: "wdb-total-count",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <p>
        전체 <br />
      </p>
    ),
  },

  {
    field: "otherTradeCount",
    headerName: "매매",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
  },
  {
    field: "otherRentalCount",
    headerName: "전세",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
  },
  {
    field: "otherMonthlyCount",
    headerName: "월세",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
  },
  {
    field: "otherTotalCount",
    headerName: "전체",
    headerAlign: "center",
    headerClassName: "other-total-rate",
    cellClassName: "other-total-rate",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <p>
        전체 <br />
      </p>
    ),
  },

  {
    field: "tradeRate",
    headerName: "매매",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    valueFormatter: getValueFormattedPercent,
  },
  {
    field: "rentalRate",
    headerName: "전세",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    valueFormatter: getValueFormattedPercent,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
  },
  {
    field: "monthlyRentalRate",
    headerName: "월세",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    valueFormatter: getValueFormattedPercent,
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
  },
  {
    field: "totalRate",
    headerName: "전체",
    headerAlign: "center",
    headerClassName: "total-rate",
    cellClassName: "total-rate",
    align: "right",
    flex: 0.3,
    disableColumnMenu: true,
    valueFormatter: getValueFormattedPercent,
    renderHeader: () => (
      <p>
        전체 <br />
      </p>
    ),
  },
];

export const tradeRateColumnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "우대빵 보유매물 중 우대빵 거래건수",
    headerAlign: "center",
    headerClassName: "header-wdb-count",
    children: [
      { field: "wdbTradeCount" },
      { field: "wdbRentalCount" },
      { field: "wdbMonthlyCount" },
      { field: "wdbTotalCount" },
    ],
  },
  {
    groupId: "우대빵 보유매물 중 타부동산 거래건수",
    headerAlign: "center",
    headerClassName: "header-other-count",
    children: [
      { field: "otherTradeCount" },
      { field: "otherRentalCount" },
      { field: "otherMonthlyCount" },
      { field: "otherTotalCount" },
    ],
  },
  {
    groupId: "거래 비율",
    headerAlign: "center",
    headerClassName: "header-rate",
    description: "우대빵 거래건수 / (우대빵 거래건수+타부동산 거래건수)",
    renderHeaderGroup: () => (
      <p>
        거래 비율{" "}
        <WdbTooltip
          title={"우대빵계약건수 / (우대빵계약건수 + 타부동산 계약건수)"}
          fontSize={"0.8rem"}
        />
      </p>
    ),
    children: [
      { field: "tradeRate" },
      { field: "rentalRate" },
      { field: "monthlyRentalRate" },
      { field: "totalRate" },
    ],
  },
];
