import { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyleProps } from "../interface/Style";
import { font } from "../styles/font";

export function GridCaption(props: PropsWithChildren<StyleProps>) {
  return (
    <>
      <Wrapper textAlign={props.textAlign} fontSize={props.fontSize}>
        {props.children}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div<StyleProps>`
  margin: 4px 0;
  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.fontSize};
`;

GridCaption.defaultProps = {
  textAlign: "left",
  fontSize: font.px12,
};
