import { TradeTypeValue } from "./../interface/Transaction";
import { useState } from "react";

function useTradeTypeController() {
  const [seletedTradeType, setSeletedSeletedTradeType] =
    useState<TradeTypeValue>("TRADE");

  const onChangeTradeType = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: TradeTypeValue
  ) => {
    setSeletedSeletedTradeType(value);
  };

  return {
    seletedTradeType,
    onChangeTradeType,
  };
}

export default useTradeTypeController;
