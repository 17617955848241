import { IconButton, IconButtonProps } from "@mui/material";
import {
  GridRenderCellParams,
  useGridApiContext,
  useGridRootProps,
} from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import styled from "styled-components";
import { CompanyGroupRequest } from "../../../interface/CommonRequest";
import {
  BuyingCompanyGroup,
  BuyingCompanyGroupColumn,
  GuestGridColumn,
} from "../../../interface/Guest";
import CompanyGroupPopover from "./CompanyGroupPopover";

interface TreeProps extends GridRenderCellParams {
  getTree: ({
    startDate,
    endDate,
    sigunguCode,
  }: CompanyGroupRequest) => Promise<any>;
  startDate: string | null;
  endDate: string | null;
  gridData: GuestGridColumn[];
}

function GuestBranchOfficeTree({
  id,
  field,
  rowNode,
  row,
  getTree,
  startDate,
  endDate,
  gridData,
}: TreeProps) {
  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();

  const Icon = rowNode.childrenExpanded
    ? rootProps.components.TreeDataCollapseIcon
    : rootProps.components.TreeDataExpandIcon;

  const handleClick: IconButtonProps["onClick"] = async (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    if (rowNode.childrenExpanded || !row || row.childrenFetched) {
      return;
    }
    const data = await getTree({
      sigunguCode: row.sigunguCode,
      startDate,
      endDate,
    });

    const childrenRows: BuyingCompanyGroupColumn[] = [];

    if (data.length > 0) {
      data.map((item: BuyingCompanyGroup) => {
        childrenRows.push({
          id: row.sigunguCode + item.companyGroupNo,
          path: [row.sigunguName, item.companyGroupName],
          sigunguName: item.companyGroupName,
          wdbTradeCount: item.rateCount.tradeCount,
          wdbRentalCount: item.rateCount.rentalCount,
          wdbMonthlyCount: item.rateCount.monthlyCount,
          wdbTotalCount: item.rateCount.totalCount,
          assignDistrictName: item.assignDistrictName,
          assignDistrictCount: item.assignDistrictCount,
        });
      });
    }

    if (data.length <= 0) {
      childrenRows.push({
        id: row.sigunguCode + 0,
        path: [row.sigunguName, "지점 없음"],
        sigunguName: "지점 없음",
      });
    }

    apiRef.current.updateRows(childrenRows);

    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
  };

  const deps = row.path.length;

  useEffect(() => {
    rowNode.childrenExpanded &&
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  }, [gridData]);

  return (
    <>
      {row.sigunguName !== "지점 없음" &&
        row.sigunguName !== "합계" &&
        row.path.length < 2 && (
          <>
            <IconButton
              size="small"
              tabIndex={-1}
              onClick={handleClick}
              aria-label={
                rowNode.childrenExpanded
                  ? apiRef.current.getLocaleText("treeDataCollapse")
                  : apiRef.current.getLocaleText("treeDataExpand")
              }
            >
              <Icon fontSize="inherit" />
            </IconButton>
            <span>{row.sigunguName}</span>
          </>
        )}

      {row.sigunguName === "합계" && <div>{row.sigunguName}</div>}

      {deps >= 2 && (
        <>
          <TreeWrapper>{row.sigunguName}</TreeWrapper>
        </>
      )}

      {deps >= 2 && row.assignDistrictName && <CompanyGroupPopover {...row} />}
    </>
  );
}

const TreeWrapper = styled.div`
  margin-left: 1.5vw;
`;

export default GuestBranchOfficeTree;
