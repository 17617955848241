import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { GridRowModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AssignDistrictName } from "../../../interface/Guest";

export interface DialogSimpleProps {
  open: boolean;
  row: GridRowModel;
  onClose: (value: string) => void;
}

function DialogSimple(props: DialogSimpleProps) {
  const { onClose, row, open } = props;

  const handleClose = () => {
    onClose(row as unknown as string);
  };

  const [main, setMain] = useState<AssignDistrictName[]>([]);
  const [etc, setEtc] = useState<AssignDistrictName[]>([]);

  useEffect(() => {
    const mainArr: AssignDistrictName[] = [];
    const etcArr: AssignDistrictName[] = [];
    row.assignDistrictName.map((item: AssignDistrictName) =>
      item.main ? mainArr.push(item) : etcArr.push(item)
    );

    setMain(mainArr);
    setEtc(etcArr);
  }, [row]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          {row.sigunguName} 관할구역
        </Typography>
        {!main.length && !etc.length && <Typography>관할 구역 없음</Typography>}

        {main.length > 0 && (
          <Typography>
            - 대표지역 :{" "}
            {main.map((item: AssignDistrictName) => item.districtName)}
          </Typography>
        )}

        {etc.length > 0 && (
          <Typography>
            - 기타 {etc.length}
            지역 :{" "}
            {etc.length > 0
              ? etc.map((item: AssignDistrictName, index: number) => {
                  if (index !== etc.length - 1) {
                    return `${item.districtName}, `;
                  } else {
                    return `${item.districtName}`;
                  }
                })
              : "관할 구역 없음"}
          </Typography>
        )}
      </Wrapper>
    </Dialog>
  );
}

export default DialogSimple;

const Wrapper = styled.div`
  padding: 24px;
`;
