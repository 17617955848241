import { RawAxiosRequestConfig } from "axios";
import clientAxios from "../store/api/customAxios";

export async function get<T>(url: string, config?: RawAxiosRequestConfig) {
  return clientAxios.get<T>(url, config);
}

export async function post<T>(
  url: string,
  data?: any,
  config?: RawAxiosRequestConfig
) {
  return clientAxios.post<T>(url, data, config);
}

export async function put<T>(
  url: string,
  data?: any,
  config?: RawAxiosRequestConfig
) {
  return clientAxios.put<T>(url, data, config);
}

export async function patch<T>(
  url: string,
  data?: any,
  config?: RawAxiosRequestConfig
) {
  return clientAxios.patch<T>(url, data, config);
}

export async function del<T>(url: string, config?: RawAxiosRequestConfig) {
  return clientAxios.delete<T>(url, config);
}

export interface SuccessWebResponse<T> {
  data: T;
  error: false;
}
interface ErrorWebResponse {
  error: true;
}
export type WebResponse<T> = SuccessWebResponse<T> | ErrorWebResponse;

export interface ResponseWrapper<T> {
  data: T;
  message: string;
  resultCode: string;
}
