export type DynamicObject = { [x: string]: any };
export function transpose(rows: DynamicObject[]) {
  const keys = Object.keys(rows[0]);

  return keys.reduce((accumulator, key) => {
    accumulator[key] = rows.map((object, index) => {
      const value = object[key];
      if (typeof value !== "object") return value;

      const subKeys = Object.keys(value);
      subKeys.forEach((subKey) => {
        const matchedSubKey = accumulator[key + subKey];
        accumulator[key + subKey] = matchedSubKey
          ? [...matchedSubKey, value[subKey]]
          : [value[subKey]];
      });
    });

    return accumulator;
  }, {} as { [x: string]: any[] });
}
