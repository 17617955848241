import { FormControl, InputLabel, Select, SelectProps } from "@mui/material";

interface WdbSelectProps extends SelectProps {
  label: string;
  width?: number;
}
function WdbSelect({
  label,
  width,
  value,
  onChange,
  children,
  className,
}: WdbSelectProps) {
  return (
    <FormControl fullWidth size="small" className={className}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
        style={{ width: `${width}px` }}
      >
        {children}
      </Select>
    </FormControl>
  );
}

export default WdbSelect;
