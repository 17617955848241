export type SearchType = "SIDO" | "SIGUNGU" | "BJDONG" | "COMPLEX";
export const SearchType = {
  SIDO: "SIDO",
  SIGUNGU: "SIGUNGU",
  BJDONG: "BJDONG",
  COMPLEX: "COMPLEX",
} as const;

export interface RequiredRequest {
  areaCode: string;
  searchType: SearchType;
}

export interface DateRequest {
  startDate: string | null;
  endDate: string | null;
}

export interface CompanyGroupRequest extends DateRequest {
  sigunguCode: string;
}

export interface wdbTotalIndicatorsRequest extends DateRequest {
  areaCd: string;
}
