import styled from "styled-components";
import { CssDefaultProps } from "../../interface/CommonInterface";
import { FlexDirection } from "./layout/FlexWrapper";

const CardWrapper = styled.div<CardProps>`
  min-width: 14vw;
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: var(--mode-black);
  background: white;
  padding: 20px;
  border-radius: 8px;

  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--font-14px);

  .card-category {
    color: #9a9a9a;
    font-size: var(--font-16px);
    line-height: 1.4em;
  }
  .card-title {
    font-size: var(--font-28px);
  }
`;

interface CardProps extends CssDefaultProps {
  children: React.ReactNode;
  flexDirection?: FlexDirection;
  minHeight?: string;
}

Card.defaultProps = {
  flexDirection: "column",
  margin: "15px 0",
  minHeight: "15vh",
};

/**
 *
 * @param {string} minWidth 카드의 최소 넓이
 * @param {string} minHeight 카드의 최소 높이
 * @param {React.ReactNode} children 자식 요소
 * @classname {card-title} 제목(진한 검정 28px)
 * @classname {card-category} 카테고리(회색 16px)
 * @returns Cardcomponent
 */
export function Card(props: CardProps) {
  return (
    <CardWrapper
      width={props.width}
      height={props.height}
      flexDirection={props.flexDirection}
      margin={props.margin}
      className={props.className}
      minHeight={props.minHeight}
    >
      {props.children}
    </CardWrapper>
  );
}
