import { Tooltip } from "@mui/material";
import { ReactNode } from "react";
import styled from "styled-components";

interface WdbTooltipProps {
  title: ReactNode;
  fontSize: string;
}
function WdbTooltip({ title, fontSize }: WdbTooltipProps) {
  return (
    <TooltipCustom
      title={<TooltipContent fontSize={fontSize}>{title}</TooltipContent>}
      arrow
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="circle-info"
        className="svg-inline--fa fa-circle-info "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
        ></path>
      </svg>
    </TooltipCustom>
  );
}

export default WdbTooltip;

WdbTooltip.defaultProps = {
  fontSize: "0.9rem",
};

const TooltipCustom = styled(Tooltip)`
  path:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const TooltipContent = styled.span<Pick<WdbTooltipProps, "fontSize">>`
  color: #fff;
  font-size: ${(props) => props.fontSize};
`;
