import React, { ReactElement } from "react";
import { TradeSalesSizeResponse } from "../../../interface/TradeSales";
import { BrokerageCommissionTab } from "../../../pages/BrokerageCommissionSizePage";
import {
  getFormattedAnalyzedRental,
  getFormattedTotalBrokerageCommissionSize,
  getFormattedTradeSalesCountAndPrice,
  getFormattedWdbCommission,
} from "../utils/tableResourceFormatter";
import AnalyzedRentalTable from "./table/AnalyzedRentalTable";
import TotalBrokerageCommissionSizeTable from "./table/TotalBrokerageCommissionSizeTable";
import TradeSalesCountAndPriceTable from "./table/TradeSalesCountAndPriceTable";
import WdbBrokerageCommissionSizeTable from "./table/WdbBrokerageCommissionSizeTable";

export interface TradeSalesTabContentsProps
  extends Pick<TradeSalesSizeResponse, "results"> {
  value: BrokerageCommissionTab;
  userInputValuesController: ReactElement;
  undefineRateTooltip: ReactElement;
  loading: boolean;
}

function BrokerageCommissionTabContent({
  value,
  results,
  userInputValuesController,
  undefineRateTooltip,
  loading,
}: TradeSalesTabContentsProps) {
  switch (value) {
    case BrokerageCommissionTab.TRADING_SIZE:
      return (
        <TradeSalesCountAndPriceTable
          gridData={getFormattedTradeSalesCountAndPrice(results)}
          undefineRateTooltip={undefineRateTooltip}
          loading={loading}
        />
      );

    case BrokerageCommissionTab.TOTAL_COMMISSION_SIZE:
      return (
        <TotalBrokerageCommissionSizeTable
          gridData={getFormattedTotalBrokerageCommissionSize(results)}
          loading={loading}
        />
      );

    case BrokerageCommissionTab.WDB_COMMISSION:
      return (
        <>
          {userInputValuesController}
          <WdbBrokerageCommissionSizeTable
            gridData={getFormattedWdbCommission(results)}
            loading={loading}
          />
        </>
      );
    case BrokerageCommissionTab.ANALYZE_TRADING_TYPE:
      return (
        <AnalyzedRentalTable
          gridData={getFormattedAnalyzedRental(results)}
          undefineRateTooltip={undefineRateTooltip}
          loading={loading}
        />
      );
    default:
      return (
        <TradeSalesCountAndPriceTable
          gridData={getFormattedTradeSalesCountAndPrice(results)}
          undefineRateTooltip={undefineRateTooltip}
          loading={loading}
        />
      );
  }
}

export default React.memo(BrokerageCommissionTabContent);
