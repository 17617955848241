import { TradeRate, TradeRateColumn } from "../../../interface/TradeRate";

export const getTradeRateData = (
  responseDataList: TradeRate[] | undefined
): TradeRateColumn[] => {
  let wdbTradeTotal = 0;
  let wdbRentalCountTotal = 0;
  let wdbMonthlyCountTotal = 0;
  let wdbTotalCountTotal = 0;

  let otherTradeCountTotal = 0;
  let otherRentalCountTotal = 0;
  let otherMonthlyCountTotal = 0;
  let otherTotalCountTotal = 0;

  let tradeRateAverage = 0;
  let rentalRateAverage = 0;
  let monthlyRentalRateAverage = 0;
  let totalRateAverage = 0;

  const result = responseDataList?.map((item: TradeRate) => {
    wdbTradeTotal += item.wdb.tradeCount;
    wdbRentalCountTotal += item.wdb.rentalCount;
    wdbMonthlyCountTotal += item.wdb.monthlyCount;
    wdbTotalCountTotal += item.wdb.totalCount;

    otherTradeCountTotal += item.other.tradeCount;
    otherRentalCountTotal += item.other.rentalCount;
    otherMonthlyCountTotal += item.other.monthlyCount;
    otherTotalCountTotal += item.other.totalCount;

    tradeRateAverage += item.tradeRate;
    rentalRateAverage += item.rentalRate;
    monthlyRentalRateAverage += item.monthlyRentalRate;
    totalRateAverage += item.totalRate;

    return {
      id: item.sigunguCode,
      path: [item.sigunguName],

      sigunguName: item.sigunguName,
      sigunguCode: item.sigunguCode,

      wdbTradeCount: item.wdb.tradeCount,
      wdbRentalCount: item.wdb.rentalCount,
      wdbMonthlyCount: item.wdb.monthlyCount,
      wdbTotalCount: item.wdb.totalCount,

      otherTradeCount: item.other.tradeCount,
      otherRentalCount: item.other.rentalCount,
      otherMonthlyCount: item.other.monthlyCount,
      otherTotalCount: item.other.totalCount,

      tradeRate: item.tradeRate,
      rentalRate: item.rentalRate,
      monthlyRentalRate: item.monthlyRentalRate,
      totalRate: item.totalRate,
    };
  });

  result?.push({
    id: "total",
    path: ["합계"],

    sigunguName: "합계",
    sigunguCode: "",

    wdbTradeCount: wdbTradeTotal,
    wdbRentalCount: wdbRentalCountTotal,
    wdbMonthlyCount: wdbMonthlyCountTotal,
    wdbTotalCount: wdbTotalCountTotal,

    otherTradeCount: otherTradeCountTotal,
    otherRentalCount: otherRentalCountTotal,
    otherMonthlyCount: otherMonthlyCountTotal,
    otherTotalCount: otherTotalCountTotal,

    tradeRate:
      Math.round(
        (wdbTradeTotal / (wdbTradeTotal + otherTradeCountTotal)) * 100 * 100
      ) / 100,
    rentalRate:
      Math.round(
        (wdbRentalCountTotal / (wdbRentalCountTotal + otherRentalCountTotal)) *
          100 *
          100
      ) / 100,
    monthlyRentalRate:
      Math.round(
        (wdbMonthlyCountTotal /
          (wdbMonthlyCountTotal + otherMonthlyCountTotal)) *
          100 *
          100
      ) / 100,
    totalRate:
      Math.round(
        (wdbTotalCountTotal / (wdbTotalCountTotal + otherTotalCountTotal)) *
          100 *
          100
      ) / 100,
  });

  return result ?? [];
};
