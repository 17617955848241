import { PropsWithChildren } from "react";
import styled from "styled-components";

function WdbPaperContainer({ children }: PropsWithChildren<unknown>) {
  return <PaperWrapper>{children}</PaperWrapper>;
}

export default WdbPaperContainer;

const PaperWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px;
  border-radius: 10px;
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.bgColorLight};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;
