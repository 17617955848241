import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TransactionRedux {
  transactionTradeType: string /** true일 때 메뉴창 열린 상태 */;
}

const initialState: TransactionRedux = {
  transactionTradeType: "TRADE",
};

export const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState,
  reducers: {
    settingTransactionTradeType: (state, action: PayloadAction<string>) => {
      state.transactionTradeType = action.payload;
    },
  },
});

export const { settingTransactionTradeType } = transactionSlice.actions;

export default transactionSlice.reducer;
