import { MenuItem, SelectChangeEvent, Tab, Tabs } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from "styled-components";

import { getSelectYearOptions } from "../../utils";
import { ContentBox } from "../../components/ContentBox";
import SelectGroup from "../../components/select/SelectGroup";
import WdbSelect from "../../components/select/WdbSelect";
import WdbPaperContainer from "../../components/WdbPaperContainer";
import AssignedDistrictTabContent from "../../containers/branchSummaryPage/components/AssignedDistrictTabContent";
import BranchTotalDataTable from "../../containers/branchSummaryPage/components/TotalDataTable";
import useRangeController from "../../hook/useRangeController";
import {
  AssignedDistrictDto,
  BranchInfo,
  BrunchTotalData,
} from "../../interface/Branch";
import {
  getBranchAssignedDistrictSummaryData,
  getBranchList,
  getBranchTotalSummaryData,
} from "../../store/api/branchDataApi";

const currentYear = new Date().getFullYear();
const TOTAL_TAB_NAME = "전체 합계";

function BranchDataPage() {
  const { selectedYear, onChangeYear } = useRangeController(currentYear);
  const [selectableBranchList, setSelectableBranchList] = useState<
    BranchInfo[]
  >([]);
  const [selectedBranchNo, setSelectedBranchNo] = useState<number>(1);
  const [assignedDistrictSummaryData, setAssignedDistrictSummaryData] =
    useState<AssignedDistrictDto[]>([]);
  const [selectedAssignedDistrict, setSelectedAssignedDistrict] =
    useState(TOTAL_TAB_NAME);
  const [totalSummaryData, setTotalSummaryData] = useState<BrunchTotalData[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const result = await getBranchList();
        setLoading(false);
        if (result.error) return;
        setSelectableBranchList(result.data);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (!selectedBranchNo || !selectedYear) return;
    const params = {
      companyGroupNo: selectedBranchNo,
      year: selectedYear,
    };

    (async () => {
      setLoading(true);
      try {
        const districtSummaryResult =
          await getBranchAssignedDistrictSummaryData(params);

        if (districtSummaryResult.error) return;
        setAssignedDistrictSummaryData(
          districtSummaryResult.data.assignDistrictData
        );

        const totalSummaryResult = await getBranchTotalSummaryData(params);
        if (totalSummaryResult.error) return;

        setLoading(false);
        setTotalSummaryData(totalSummaryResult.data);
      } catch (error) {}
    })();
  }, [selectedBranchNo, selectedYear]);

  useEffect(() => {
    setSelectedAssignedDistrict(TOTAL_TAB_NAME);
  }, [selectedBranchNo]);

  const onChangeBranch = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    setSelectedBranchNo(event.target.value as number);
  };

  const onChangeAssignedDistrict = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setSelectedAssignedDistrict(newValue);
  };

  return (
    <BranchDataPageWrapper>
      <WdbPaperContainer>
        <SelectGroup label="지점별 데이터">
          <WdbSelect
            label={"연도"}
            value={selectedYear}
            onChange={onChangeYear}
          >
            {getSelectYearOptions(2023, currentYear).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </WdbSelect>
          <WdbSelect
            label={"지점명"}
            value={selectedBranchNo}
            onChange={onChangeBranch}
            width={200}
          >
            {selectableBranchList.map((branch) => (
              <MenuItem
                key={branch.companyGroupNo}
                value={branch.companyGroupNo}
              >
                {branch.companyGroupNo}.{branch.companyGroupName}
              </MenuItem>
            ))}
          </WdbSelect>
        </SelectGroup>
      </WdbPaperContainer>
      <ContentBox>
        {!!assignedDistrictSummaryData.length ? (
          <Tabs
            value={selectedAssignedDistrict}
            onChange={onChangeAssignedDistrict}
          >
            <Tab
              label={TOTAL_TAB_NAME}
              value={TOTAL_TAB_NAME}
              className={"totalTab"}
            />
            {assignedDistrictSummaryData.map(({ districtName }) => (
              <Tab
                key={districtName}
                label={districtName}
                value={districtName}
              />
            ))}
          </Tabs>
        ) : (
          <NoDataWrapper>데이터가 존재하지 않습니다</NoDataWrapper>
        )}
        {!!totalSummaryData.length && (
          <Show isShown={selectedAssignedDistrict === TOTAL_TAB_NAME}>
            <BranchTotalDataTable
              year={selectedYear}
              branchTotalData={totalSummaryData}
              loading={loading}
            />
          </Show>
        )}
        {assignedDistrictSummaryData.map((item, index) => (
          <Show
            isShown={selectedAssignedDistrict === item.districtName}
            key={index}
          >
            <AssignedDistrictTabContent {...item} year={selectedYear} />
          </Show>
        ))}
      </ContentBox>
    </BranchDataPageWrapper>
  );
}

export default BranchDataPage;
interface ShowProps {
  isShown: boolean;
}
function Show({ isShown, children }: PropsWithChildren<ShowProps>) {
  if (!isShown) return null;
  return <>{children}</>;
}

const BranchDataPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & .totalTab {
    font-weight: 700;
    color: black;
  }
  & div [data-field="title"] {
    border-right: 4px double #f0f0f0;
  }
  & .MuiDataGrid-cell,
  .MuiDataGrid-columnHeader {
    border-right: 1px solid #f0f0f0;
  }
  & .MuiDataGrid-columnHeader[data-field="groupTitle"] {
    border-right: none;
  }
`;

const NoDataWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: gray;
  font-size: 1rem;
`;
