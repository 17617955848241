import { Chip, Divider, Stack } from "@mui/material";
import styled from "styled-components";
import { SelectOption } from "../../../interface/CommonInterface";
import { color } from "../../../styles/color";

export interface AreaChipsProps {
  selectableSigunguList: SelectOption[];
  selectableBjdongList: SelectOption[];
  sidoSelect: SelectOption | undefined;
  sigunguSelect: SelectOption | undefined;
  bjdongSelect: SelectOption | undefined;
  setArea: React.Dispatch<React.SetStateAction<string>>;
  resetSigungu: (() => void) | undefined;
  resetBjdong: (() => void) | undefined;
  selectedAreaChips: SelectOption[];
  setSelectedAreaChips: React.Dispatch<React.SetStateAction<SelectOption[]>>;
}

function AreaChips({
  selectableSigunguList,
  selectableBjdongList,
  sidoSelect,
  sigunguSelect,
  bjdongSelect,
  setArea,
  resetSigungu,
  resetBjdong,
  selectedAreaChips,
  setSelectedAreaChips,
}: AreaChipsProps) {
  const handleChipClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const selectedChips = [...selectedAreaChips];

    const sameChips = selectedAreaChips.filter(
      (chip: SelectOption) =>
        chip.value === event.currentTarget.tabIndex.toString()
    );

    sameChips.length > 0
      ? alert("중복된 지역 선택입니다.")
      : selectedChips.unshift({
          value: event.currentTarget.tabIndex.toString(),
          label: event.currentTarget.innerText,
        });

    if (selectedChips.length > 2) {
      selectedChips.pop();
    }

    setSelectedAreaChips(selectedChips);
  };

  return (
    <Wrapper>
      <Stack
        flexDirection={"row"}
        flexWrap="wrap"
        columnGap="10px"
        rowGap="5px"
        alignItems={"flex-end"}
      >
        {(!bjdongSelect?.value || sigunguSelect?.value !== "전체") &&
          selectableSigunguList.map((item: SelectOption) => (
            <Chip
              tabIndex={
                item.value === "전체"
                  ? Number(sidoSelect?.value)
                  : Number(item.value)
              }
              label={item.label}
              size="small"
              onClick={handleChipClick}
            />
          ))}

        {/* 법정동 단위 Chips 보류
        {selectableBjdongList.length >= 2 &&
          bjdongSelect?.value === "전체" &&
          selectableBjdongList.map((item: SelectOption) => (
            <Chip
              tabIndex={Number(item.value)}
              label={item.label}
              size="small"
              onClick={handleChipClick}
              onDelete={resetBjdong}
            />
          ))} */}
      </Stack>
    </Wrapper>
  );
}

export default AreaChips;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .label-total {
    background-color: black;
    color: white;
    svg {
      color: white;
    }
  }

  .label-selected {
    background-color: #999999ff;
    color: white;
    svg {
      color: white;
    }
    &:hover {
      background-color: #999999ff;
      color: white;
      opacity: 0.8;
      svg {
        color: white;
      }
    }
  }
`;
