import { useSessionStore } from "../hook/storeHook";
import { Main } from "./Main";
import { SideMenu } from "./SideMenu";
import { ErrorPage } from "../pages/ErrorPage";

export function Layout() {
  const { isLogin } = useSessionStore();

  return (
    <>
      {isLogin ? (
        <>
          <SideMenu />
          <Main />
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
}
