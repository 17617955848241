import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { SelectOption } from "../interface/CommonInterface";
import { DistrictType } from "../interface/District";
import { getDistrictOptions } from "./commonHook";

const DEFAULT_SIDO = "11"; //서울 시도 코드
const DEFAULT_SIGUNGU = "전체";
const DEFAULT_BJDONG = "전체";
function useAreaController() {
  const [selectedSido, setSelectedSido] = useState(DEFAULT_SIDO);
  const [selectedSigungu, setSelectedSigungu] = useState(DEFAULT_SIGUNGU);
  const [selectedBjdong, setSelectedBjdong] = useState(DEFAULT_BJDONG);

  const [selectableSigunguList, setSelectableSigunguList] = useState<
    SelectOption[]
  >([]);
  const [selectableBjdongList, setSelectableBjdongList] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    if (selectedSido === "전체") {
      resetSigungu();
      resetSigunguList();
      return;
    }

    (async () => {
      resetSigungu();
      setSelectableSigunguList(
        await getDistrictOptions(DistrictType.SIGUNGU, selectedSido)
      );
    })();
  }, [selectedSido]);

  useEffect(() => {
    if (selectedSigungu === "전체") {
      resetBjdong();
      resetBjdongList();
      return;
    }

    (async () => {
      resetBjdong();
      setSelectableBjdongList(
        await getDistrictOptions(DistrictType.BJDONG, selectedSigungu)
      );
    })();
  }, [selectedSigungu]);

  const onChangeSido = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    setSelectedSido(event.target.value as string);
  };

  const onChangeSigungu = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    setSelectedSigungu(event.target.value as string);
  };

  const onChangeBjdong = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    setSelectedBjdong(event.target.value as string);
  };

  const resetSido = () => {
    setSelectedSido("전체");
  };
  const resetSigungu = () => {
    setSelectedSigungu(DEFAULT_SIGUNGU);
  };
  const resetBjdong = () => {
    setSelectedBjdong(DEFAULT_BJDONG);
  };

  const resetSigunguList = () => {
    setSelectableSigunguList([]);
  };
  const resetBjdongList = () => {
    setSelectableBjdongList([]);
  };

  return {
    selectableSigunguList,
    selectableBjdongList,

    selectedSido,
    selectedSigungu,
    selectedBjdong,

    setSelectedSido,
    setSelectedSigungu,
    setSelectedBjdong,

    resetSido,
    resetSigungu,
    resetBjdong,
    resetBjdongList,

    onChangeSido,
    onChangeSigungu,
    onChangeBjdong,
  };
}

export default useAreaController;
