import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export interface WdbIconProps {
  onClick: () => void;
  icon: IconProp;
}

function WdbIcon({ onClick, icon }: WdbIconProps) {
  return (
    <>
      <WdbIconWrapper>
        <FontAwesomeIcon icon={icon} onClick={onClick} />
      </WdbIconWrapper>
    </>
  );
}

export default WdbIcon;

const WdbIconWrapper = styled.span`
  font-size: 14px;
  svg:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin: 0 0.2vw;
`;
