import "./App.css";

import { LicenseInfo } from "@mui/x-license-pro";
import { Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "./hook/useMenu";
import { Layout } from "./layout/Layout";

import PublicDataRoute from "./pages/publicData/PublicDataRoute";
import SalesHoldingPage from "./pages/SalesHoldingPage";
import TradeRatePage from "./pages/TradeRatePage";
import BranchDataPage from "./pages/branch/BranchDataPage";
import ManagerDataPage from "./pages/branch/ManagerDataPage";
import LoadingPage from "./pages/LoadingPage";
import { DashBoardPage } from "./pages/DashBoardPage";
import { ErrorPage } from "./pages/ErrorPage";
import { GuestPage } from "./pages/GuestPage";
import { MainPage } from "./pages/MainPage";

import GlobalStyles from "./styles/GlobalStyles";
import { darkTheme, lightTheme } from "./styles/theme";

LicenseInfo.setLicenseKey(
  "2829b2c84918eccfe64009881e6f79bbTz01Nzg2NyxFPTE3MDU1NjU3ODA2MzUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const { isDarkMode } = useDarkMode();

  if (process.env.NODE_ENV === "production") {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function no_console() {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.warn = function no_console() {};
  }

  return (
    <div>
      <ThemeProvider theme={!isDarkMode ? lightTheme : darkTheme}>
        <GlobalStyles />

        <Routes>
          <Route path="/loading" element={<LoadingPage />} />
          <Route path="/login-guide" element={<ErrorPage />} />

          <Route element={<Layout />}>
            <Route path="/" element={<MainPage />} />
            <Route path="/public-data/*" element={<PublicDataRoute />} />

            <Route path="/total">
              <Route path="sales-holding" element={<SalesHoldingPage />} />
              <Route path="guest" element={<GuestPage />} />
              <Route path="trade-rate" element={<TradeRatePage />} />
            </Route>

            <Route path="/branch">
              <Route path="data" element={<BranchDataPage />} />
              <Route path="manager" element={<ManagerDataPage />} />
            </Route>

            <Route path="/dashboard" element={<DashBoardPage />} />

            <Route path="/*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
