import {
  DataGridPro,
  GridColDef,
  GridColumnGroupingModel,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import styled from "styled-components";
import GroupHeaderChip from "../../../components/table/GroupHeaderChip";
import WdbTooltip from "../../../components/tooltip/WdbTooltip";
import {
  CompanyGroupRequest,
  RequiredRequest,
} from "../../../interface/CommonRequest";
import {
  TradeRateColumn,
  TradeRateCompanyGroup,
} from "../../../interface/TradeRate";
import { color } from "../../../styles/color";
import { getValueFormattedPercent } from "../../../utils/gridUtils";
import TradeRateBranchOfficeTree from "./TradeRateBranchOfficeTree";
import {
  tradeRateColumnGroupingModel,
  tradeRateColums,
} from "../static/column";

export interface TradeRateGridProps {
  gridData: TradeRateColumn[];
  getTradeCompanyGroupData: (
    params: CompanyGroupRequest
  ) => Promise<TradeRateCompanyGroup[] | undefined>;
  startDate: string | null;
  endDate: string | null;
  loading: boolean;
}

function TradeRateGrid({
  gridData,
  getTradeCompanyGroupData,
  startDate,
  endDate,
  loading,
}: TradeRateGridProps) {
  const gridRef = useGridApiRef();
  return (
    <Wrapper>
      <DataGridPro
        sx={{ mt: 2 }}
        apiRef={gridRef}
        experimentalFeatures={{ columnGrouping: true }}
        rows={gridData}
        columns={tradeRateColums}
        loading={loading}
        disableSelectionOnClick
        columnGroupingModel={tradeRateColumnGroupingModel}
        columnVisibilityModel={{
          sigunguName: false,
          sigunguCode: false,
        }}
        groupingColDef={{
          renderHeader: () => (
            <p>
              지역
              <br />
              (손님이 원하는 기준)
            </p>
          ),
          renderCell: (params: GridRenderCellParams) => (
            <>
              <TradeRateBranchOfficeTree
                {...params}
                getTree={getTradeCompanyGroupData}
                startDate={startDate}
                endDate={endDate}
                gridData={gridData}
              />
            </>
          ),
          headerAlign: "center",
          flex: 1,
        }}
        treeData
        getTreeDataPath={(row) => row.path}
        rowHeight={40}
        hideFooter={true}
      />
    </Wrapper>
  );
}

export default TradeRateGrid;

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;

  .header-wdb-count {
    border-left: 4px double #f0f0f0;
    border-right: 4px double #f0f0f0;
  }
  .header-other-count {
    border-right: 4px double #f0f0f0;
  }

  .wdb-trade-count {
    border-left: 4px double #f0f0f0;
  }

  .wdb-total-count,
  .other-total-rate {
    border-right: 4px double #f0f0f0;
    background-color: ${color.grid.background.tradeCell};
  }

  .total-rate {
    background-color: ${color.grid.background.tradeCell};
  }

  /* .MuiDataGrid-row--lastVisible > div:first-child {
    justify-content: center;
  } */

  & div [data-id="total"] {
    font-weight: bold;
  }
`;
