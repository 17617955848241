import {
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import GridProgressBar from "../../components/GridProgressBar";
import GroupHeaderChip from "../../components/table/GroupHeaderChip";
import { color } from "../../styles/color";
import { getValueFormattedToLocaleString } from "../../utils/gridUtils";

export const salesHoldingDateColumn: GridColDef[] = [
  {
    field: "areaCode",
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "areaName",
    headerName: "지역명",
    headerAlign: "center",
    flex: 0.4,
    align: "center",
  },
  {
    field: "naverTradeCount",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "naverRentalCount",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "naverMonthlyCount",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "naverTotalCount",
    headerName: "합계",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },

  {
    field: "naverTradeGroupCount",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "naverRentalGroupCount",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "naverMonthlyGroupCount",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "naverTotalGroupCount",
    headerName: "합계",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },

  {
    field: "sameOfferAdRate",
    headerName: "동일매물 광고배율",
    renderHeader: () => (
      <p>
        동일매물
        <br />
        광고배율
      </p>
    ),
    headerAlign: "center",
    align: "right",
    getApplyQuickFilterFn: () => {
      return null;
    },
  },

  {
    field: "woodaeTradeCount",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "woodaeTradeRate",
    headerName: "보유비율",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    renderCell(params: GridCellParams) {
      return <GridProgressBar value={params.value} />;
    },
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "woodaeRentalCount",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "woodaeRentalRate",
    headerName: "보유비율",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    renderCell(params: GridCellParams) {
      return <GridProgressBar value={params.value} />;
    },
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "woodaeMonthlyCount",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "woodaeMonthlyRate",
    headerName: "보유비율",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    renderCell(params: GridCellParams) {
      return <GridProgressBar value={params.value} />;
    },
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "woodaeTotalCount",
    headerName: "합계",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
  {
    field: "woodaeTotalRate",
    headerName: "보유비율",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    renderCell(params: GridCellParams) {
      return <GridProgressBar value={params.value} />;
    },
    getApplyQuickFilterFn: () => {
      return null;
    },
  },
];

export const salesHoldingDateColumnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: " ",
    headerClassName: "header-area-name",
    children: [
      {
        field: "areaName",
      },
    ],
  },
  {
    groupId: "네이버 전체",
    headerAlign: "center",
    headerClassName: "header-naver-count",
    children: [
      { field: "naverTradeCount" },
      { field: "naverRentalCount" },
      { field: "naverMonthlyCount" },
      { field: "naverTotalCount" },
    ],
  },
  {
    groupId: "동일매물묶음 적용",
    headerAlign: "center",
    headerClassName: "header-naver-group-count",
    children: [
      { field: "naverTradeGroupCount" },
      { field: "naverRentalGroupCount" },
      { field: "naverMonthlyGroupCount" },
      { field: "naverTotalGroupCount" },
    ],
  },
  {
    groupId: "  ",
    headerClassName: "header-same-offer-adrate",
    children: [
      {
        field: "sameOfferAdRate",
      },
    ],
  },
  {
    groupId: "우대빵 보유매물 (보유비율은 동일매물묶음 대비)",
    headerAlign: "center",
    headerClassName: "header-wdb-count",
    children: [
      { field: "woodaeTradeCount" },
      { field: "woodaeTradeRate" },
      { field: "woodaeRentalCount" },
      { field: "woodaeRentalRate" },
      { field: "woodaeMonthlyCount" },
      { field: "woodaeMonthlyRate" },
      { field: "woodaeTotalCount" },
      { field: "woodaeTotalRate" },
    ],
  },
];
