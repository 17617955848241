import React from "react";
import styled from "styled-components";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { ManagerDataTabType } from "../../pages/branch/ManagerDataPage";
import { ManagerDataColumn } from "../../interface/Manager";
import { GridColDef } from "@mui/x-data-grid";
import { getValueFormattedToLocaleString } from "../../utils/gridUtils";

type TabContentsProps = {
  tabValue: ManagerDataTabType;
  gridData: ManagerDataColumn[];
  loading: boolean;
};

export const getCellValueTotal = (dataGrid: object[]) => {
  const total: any = {
    id: "total",
    name: "합계",
  };
  dataGrid.forEach((data) => {
    Object.entries(data).forEach(([key, value]) => {
      const prevValue = total[key] || 0;
      if (key === "ratingCount") {
        return;
      }
      if (typeof value === "number") {
        total[key] = prevValue + value;
        return;
      }
      return;
    });
  });
  return total;
};

export default function TabContents({
  tabValue,
  gridData,
  loading,
}: TabContentsProps) {
  return (
    <Warpper>
      {tabValue === "MONTH_TAB" && <></>}
      {tabValue === "DATE_TAB" && (
        <>
          {gridData.length ? (
            <GridWrapper>
              <DataGridPro
                experimentalFeatures={{ columnGrouping: true }}
                rows={[
                  ...rowsListFormatted(gridData),
                  getCellValueTotal(gridData),
                ]}
                columns={[
                  {
                    field: "name",
                    headerName: "이름 / 소속",
                    width: 150,
                    renderCell: (params) => {
                      return (
                        <>
                          {params?.row?.companyGroupName ? (
                            <CellWrapper>
                              {params.row.name} / {params.row.companyGroupName}
                            </CellWrapper>
                          ) : (
                            <CellWrapper>{params.row.name}</CellWrapper>
                          )}
                        </>
                      );
                    },
                  },
                  ...managerDateColumn,
                ]}
                disableSelectionOnClick
                columnVisibilityModel={{
                  areaCode: false,
                }}
                rowHeight={40}
                loading={loading}
              />
            </GridWrapper>
          ) : (
            <NoDataWrapper>데이터가 존재하지 않습니다.</NoDataWrapper>
          )}
        </>
      )}
    </Warpper>
  );
}

export const rowsListFormatted = (responseList: ManagerDataColumn[]) => {
  const result = responseList.map((item: ManagerDataColumn, index: number) => {
    return {
      id: index,
      name: item.name,
      companyGroupName: item.companyGroupName,
      ratingCount: item.ratingCount,
      newOfferCount: item.newOfferCount,
      totalOfferCount: item.totalOfferCount,
      adRegistrationCount: item.adRegistrationCount,
      adRenewCount: item.adRenewCount,
      newBuyingCount: item.newBuyingCount,
      availableBuyingCount: item.availableBuyingCount,
      buyingHoldOutCount: item.buyingHoldOutCount,
      buyingPerOffer: item.buyingPerOffer,
      visitReservationCount: item.visitReservationCount,
      visitCompletionCount: item.visitCompletionCount,
      buyingAlertTalkCount: item.buyingAlertTalkCount,
      offerAlertTalkCount: item.offerAlertTalkCount,
      contractCompletionCount: item.contractCompletionCount,
      otherContractCount: item.otherContractCount,
      commission: item.commission,
      createdDate: item.createdDate,
    };
  });

  return result ?? [];
};

const Warpper = styled.div``;

const GridWrapper = styled.div`
  width: 100%;
  height: 80vh;

  div [data-field="ratingCount"] {
    border-left: 4px double #f0f0f0;
  }

  div [data-field="adRenewCount"],
  div [data-field="offerAlertTalkCount"],
  div [data-field="buyingPerOffer"] {
    border-right: 4px double #f0f0f0;
  }
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NoDataWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: gray;
  font-size: 1rem;
`;

const Secondary = styled.span`
  font-weight: 500;
`;

export const managerDateColumn: GridColDef[] = [
  {
    field: "ratingCount",
    headerName: "급수",
    headerAlign: "center",
    align: "right",
    flex: 0.2,
    valueFormatter: getValueFormattedToLocaleString, // todo : 진짜 필요한걸까? 확인 필요.
  },
  {
    field: "newOfferCount",
    headerName: "신규 배정된 매물수",
    renderHeader: () => (
      <p>
        신규 배정된
        <br />
        매물수
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "totalOfferCount",
    headerName: "관리 매물",
    renderHeader: () => (
      <p>
        관리 매물 <br />
        <Secondary>(동시진행포함전체)</Secondary>
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "adRegistrationCount",
    headerName: "광고 등록 건수",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "adRenewCount",
    headerName: "광고 갱신 건수",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "newBuyingCount",
    headerName: "신규 접수된 손님 수",
    renderHeader: () => (
      <p>
        신규 접수된
        <br />
        손님 수
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "availableBuyingCount",
    headerName: "유효 손님 수",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "buyingHoldOutCount",
    headerName: "손님 보류/아웃 처리 수",
    renderHeader: () => (
      <p>
        손님 보류 /<br />
        아웃 처리 수
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "buyingPerOffer",
    headerName: "매물당 손님(%)",
    renderHeader: () => (
      <p>
        매물당 손님
        <br />
        <Secondary>(%)</Secondary>
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "visitReservationCount",
    headerName: "임장 등록 수",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "visitCompletionCount",
    headerName: "임장 완료 수",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "buyingAlertTalkCount",
    headerName: "손님 매물전송 알림톡 건수",
    renderHeader: () => (
      <p>
        손님 매물전송
        <br />
        알림톡 건수
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "offerAlertTalkCount",
    headerName: "매물전송 이외 알림톡 건수",
    renderHeader: () => (
      <p>
        매물전송 이외
        <br />
        알림톡 전송 수
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "contractCompletionCount",
    headerName: "가계약 완료 건수",
    renderHeader: () => (
      <p>
        가계약
        <br />
        완료 건수
      </p>
    ),
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
  {
    field: "commission",
    headerName: "매출",
    headerAlign: "center",
    align: "right",
    flex: 0.3,
    valueFormatter: getValueFormattedToLocaleString,
  },
];
