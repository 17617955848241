import styled from "styled-components";
import { CssDefaultProps } from "../interface/CommonInterface";
import { font } from "../styles/font";

interface ContentBoxProps extends CssDefaultProps {
  children: React.ReactNode;
}
export function ContentBox(props: ContentBoxProps) {
  return (
    <ContentBoxWrapper>{props.children}</ContentBoxWrapper>
  );
}

const ContentBoxWrapper = styled.div`
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: ${(props) => props.theme.bgColorLight};
  color: ${(props) => props.theme.colorLight};
  margin-bottom: 20px;
  .chart-title {
    font-size: ${font.px20};
    margin: 1vh 1vw 2vh;
    text-align: center;
    color: ${(props) => props.theme.colorLight};
  }
  .chart-sub-title {
    font-size: ${font.px16};
    margin: 1vh 0 0;
    color: ${(props) => props.theme.colorLight};
  }
  .collapse {
    margin-right: 0.4vw;
  }
`;
