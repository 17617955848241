import { color } from "../../../styles/color";

import { ChartData, ChartDataSets } from "../../../interface/chartFormatter";

export const getFormattedManagerOfferPropertyChartData = (
  responseList: any | undefined
) => {
  const labels: string[] = [];
  const datasets: ChartDataSets[] = [];

  const newOfferCountData: number[] = [];
  const totalOfferCountData: number[] = [];
  const adRegistrationCountData: number[] = [];
  const adRenewCount: number[] = [];
  responseList &&
    responseList?.data.map((item: any, index: number) => {
      labels.push(item.createdDate);

      adRegistrationCountData.push(item.offerProperty.adRegistrationCount);
      adRenewCount.push(item.offerProperty.adRenewCount);
      newOfferCountData.push(item.offerProperty.newOfferCount);
      totalOfferCountData.push(item.offerProperty.totalOfferCount);
    });

  datasets.push(
    {
      label: " 신규 배정된 매물 수",
      data: newOfferCountData,
      borderColor: "black",
      backgroundColor: "black",
      borderWidth: 2,
    },
    {
      label: " 배정된 전체 매물 수",
      data: totalOfferCountData,
      borderColor: color.trade,
      backgroundColor: color.trade,
      borderWidth: 2,
    },
    {
      label: " 광고 등록 건수",
      data: adRegistrationCountData,
      borderColor: color.rental,
      backgroundColor: color.rental,
      borderWidth: 2,
    },
    {
      label: " 광고 갱신 건수",
      data: adRenewCount,
      borderColor: color.monthly,
      backgroundColor: color.monthly,
      borderWidth: 2,
    }
  );

  const managerChartData: ChartData = { labels, datasets };
  return managerChartData;
};

export const getFormattedManagerbuyingPropertyChartData = (
  responseList: any | undefined
) => {
  const labels: string[] = [];
  const datasets: ChartDataSets[] = [];

  const newBuyingCountData: number[] = [];
  const availableBuyingCountData: number[] = [];
  const buyingHoldOutCountData: number[] = [];
  const offerAlertTalkCountData: number[] = [];

  responseList &&
    responseList?.data.map((item: any, index: number) => {
      labels.push(item.createdDate);

      newBuyingCountData.push(item.buyingProperty.newBuyingCount);
      availableBuyingCountData.push(item.buyingProperty.availableBuyingCount);
      buyingHoldOutCountData.push(item.buyingProperty.buyingHoldOutCount);
      offerAlertTalkCountData.push(item.buyingProperty.offerAlertTalkCount);
    });

  datasets.push(
    {
      label: " 신규 배정된 손님 수",
      data: newBuyingCountData,
      borderColor: "black",
      backgroundColor: "black",
      borderWidth: 2,
    },
    {
      label: " 배정된 전체 유효 손님 수",
      data: availableBuyingCountData,
      borderColor: color.trade,
      backgroundColor: color.trade,
      borderWidth: 2,
    },
    {
      label: " 손님 보류/아웃 처리 수",
      data: buyingHoldOutCountData,
      borderColor: color.rental,
      backgroundColor: color.rental,
      borderWidth: 2,
    },
    {
      label: " 매물 리스트 알림톡 전송 수",
      data: offerAlertTalkCountData,
      borderColor: color.monthly,
      backgroundColor: color.monthly,
      borderWidth: 2,
    }
  );

  const managerChartData: ChartData = { labels, datasets };

  return managerChartData;
};

export const getFormattedManagerVisitPropertyChartData = (
  responseList: any | undefined
) => {
  const labels: string[] = [];
  const datasets: ChartDataSets[] = [];

  const contractCompletionCountData: number[] = [];
  const visitCompletionCountData: number[] = [];
  const visitReservationCountData: number[] = [];

  responseList &&
    responseList?.data.map((item: any, index: number) => {
      labels.push(item.createdDate);

      contractCompletionCountData.push(
        item.visitProperty.contractCompletionCount
      );
      visitCompletionCountData.push(item.visitProperty.visitCompletionCount);
      visitReservationCountData.push(item.visitProperty.visitReservationCount);
    });

  datasets.push(
    {
      label: " 임장 등록 수",
      data: visitReservationCountData,
      borderColor: "black",
      backgroundColor: "black",
      borderWidth: 2,
    },
    {
      label: " 임장 완료 수",
      data: visitCompletionCountData,
      borderColor: color.trade,
      backgroundColor: color.trade,
      borderWidth: 2,
    },
    {
      label: " 가계약 완료 건수",
      data: contractCompletionCountData,
      borderColor: color.rental,
      backgroundColor: color.rental,
      borderWidth: 2,
    }
  );

  const managerChartData: ChartData = { labels, datasets };

  return managerChartData;
};
