export const font = {
  /* px -> rem (default: 16px) */
  px10: "0.625rem",
  px12: "0.75rem",
  px14: "0.875rem",
  px16: "1rem",
  px18: "1.125rem",
  px20: "1.25rem",
  px22: "1.375rem",
  px24: "1.5rem",
  px28: "1.75rem",
};
