export const color = {
  darkMode: {
    white: "#ffffff",
    light95: "#eceff1",
    light90: "#cfd8dc",
    light80: "#b0bec5",
    light70: "#90a4ae",

    black30: "#455a64",
    black20: "#37474f",
    black10: "#263238",
    black: "#222222",
  },
  antColor: {
    dustRed: {
      red1: "#fff1f0",
      red2: "#ffccc7",
      red3: "#ffa39e",
      red4: "#ff7875",
      red5: "#ff4d4f",
      red6: "#f5222d",
      red7: "#cf1322",
      red8: "#a8071a",
      red9: "#820014",
      red10: "#5c0011",
    },
    sunriseYellow: {
      yellow1: "#feffe6",
      yellow2: "#ffffb8",
      yellow3: "#fffb8f",
      yellow4: "#fff566",
      yellow5: "#ffec3d",
      yellow6: "#fadb14",
      yellow7: "#d4b106",
      yellow8: "##ad8b00",
      yellow9: "#876800",
      yellow10: "#614700",
    },
    daybreakBlue: {
      blue1: "#e6f4ff",
      blue2: "#bae0ff",
      blue3: "#91caff",
      blue4: "#69b1ff",
      blue5: "#4096ff",
      blue6: "#1677ff",
      blue7: "#0958d9",
      blue8: "#003eb3",
      blue9: "#002c8c",
      blue10: "#001d66",
    },
  },
  chart: {
    progress: {
      lightGreenToOrange: ["#d9fdb5", "#13dc3b", "#ea9d81", "#ff7300"],
    },
    blueToYellow: [
      "#115f9a",
      "#1984c5",
      "#22a7f0",
      "#48b5c4",
      "#76c68f",
      "#a6d75b",
      "#c9e52f",
      "#d0ee11",
      "#d0f400",
    ],
  },
  grid: {
    border: {
      default: "#ddd",
      black: "#1A120B",
    },
    background: {
      sumBlue50: "#dcf4fe",
      sumBlue100: "#B8E8FC",
      sumBlue200: "#a1dcff",
      tradeHeader: "#e79ea7",
      tradeCell: "rgba(195,13,35,0.1)",
      rentalHeader: "#82f177",
      rentalCell: "rgba(36,182,22,0.1)",
      monthlyHeader: "#bda08a",
      monthlyCell: "rgba(145,96,60,0.1)",
    },
  },
  naverGreen: "#2DB400",
  woodaeRed: "#C30D23",
  trade: "#C30D23",
  rental: "#24B616",
  monthly: "#91603C",
  pastel: {
    lightYellow: "#FDFCE5",
    yellow: "#FAF4B7",
    lightBeige: "#FFF5EB",
    pink: "#F0DBDB",
    skyblue: "#CDFCF6",
    lightGrey: "#D9E4DD",
  },
  chip: {
    selected: {
      background: "#8f8e8e",
      color: "white",
    },
  },
};
