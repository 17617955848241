export interface UserInfo {
  companyGroupNo: number;
  companyNo: number;
  email: string;
  grade: string;
  id: number;
  name: string;
}

export function useSessionStore() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sessionUser = sessionStorage.getItem("userInfo");
  const userInfo: UserInfo =
    sessionUser && sessionUser ? JSON.parse(sessionUser) : "{}";
  const isLogin = sessionStorage.getItem("isLogin") === "true";

  return { userInfo, isLogin };
}
