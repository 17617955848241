import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LegendItem,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { useEffect, useRef } from "react";
import { Chart } from "react-chartjs-2";
import styled from "styled-components";
import { tradeTypeInputs } from "../../../containers/guest/hooks/useGuest";
import { GuestWrapper } from "../../../pages/GuestPage";
ChartJS.register(
  LineController,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export interface GuestChartProps {
  monthlyChartData: ChartData;
  dailyChartData: ChartData;
  areaName: string | undefined;
  chartInputs: tradeTypeInputs;
}

function GuestChart({
  monthlyChartData,
  dailyChartData,
  areaName,
  chartInputs,
}: GuestChartProps) {
  const chartMonthlyRef = useRef<ChartJS<"line", number[], string>>(null);
  const chartDailyRef = useRef<ChartJS<"line", number[], string>>(null);

  const { total, trade, rental, monthlyRental } = chartInputs;

  useEffect(() => {
    const legendItemsMonthly = chartMonthlyRef.current?.legend?.legendItems;
    const monthlyChart = chartMonthlyRef.current?.legend?.chart;

    const legendItemsDaily = chartDailyRef.current?.legend?.legendItems;
    const dailyChart = chartDailyRef.current?.legend?.chart;

    if (legendItemsMonthly && legendItemsDaily) {
      const totalCountItem = legendItemsMonthly[0];
      const totalRateItem = legendItemsMonthly[4];
      const totalDailyCountItem = legendItemsDaily[0];

      const tradeCountItem = legendItemsMonthly[1];
      const tradeRateItem = legendItemsMonthly[5];
      const tradeDailyCountItem = legendItemsDaily[1];

      const rentalCountItem = legendItemsMonthly[2];
      const rentalRateItem = legendItemsMonthly[6];
      const rentalDailyCountItem = legendItemsDaily[2];

      const monthlyRentalCountItem = legendItemsMonthly[3];
      const monthlyRentalRateItem = legendItemsMonthly[7];
      const monthlyRentalDailyCountItem = legendItemsDaily[3];

      const handleMonthlyChartShow = (
        item1: LegendItem,
        item2?: LegendItem
      ) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          monthlyChart?.show(index1);
          item1.hidden = false;
          monthlyChart?.show(index2);
          item2.hidden = false;
        } else if (index1 !== undefined) {
          monthlyChart?.show(index1);
          item1.hidden = false;
        }
      };

      const handleMonthlyChartHidden = (
        item1: LegendItem,
        item2?: LegendItem
      ) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          monthlyChart?.hide(index1);
          item1.hidden = true;
          monthlyChart?.hide(index2);
          item2.hidden = true;
        } else if (index1 !== undefined) {
          monthlyChart?.hide(index1);
          item1.hidden = true;
        }
      };

      const handleDailyChartShow = (item1: LegendItem, item2?: LegendItem) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          dailyChart?.show(index1);
          item1.hidden = false;
          dailyChart?.show(index2);
          item2.hidden = false;
        } else if (index1 !== undefined) {
          dailyChart?.show(index1);
          item1.hidden = false;
        }
      };

      const handleDailyChartHidden = (
        item1: LegendItem,
        item2?: LegendItem
      ) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          dailyChart?.hide(index1);
          item1.hidden = true;
          dailyChart?.hide(index2);
          item2.hidden = true;
        } else if (index1 !== undefined) {
          dailyChart?.hide(index1);
          item1.hidden = true;
        }
      };

      if (legendItemsMonthly?.length > 4) {
        if (total) {
          handleMonthlyChartShow(totalCountItem, totalRateItem);
          handleDailyChartShow(totalDailyCountItem);
        } else {
          handleMonthlyChartHidden(totalCountItem, totalRateItem);
          handleDailyChartHidden(totalDailyCountItem);
        }

        if (trade) {
          handleMonthlyChartShow(tradeCountItem, tradeRateItem);
          handleDailyChartShow(tradeDailyCountItem);
        } else {
          handleMonthlyChartHidden(tradeCountItem, tradeRateItem);
          handleDailyChartHidden(tradeDailyCountItem);
        }

        if (rental) {
          handleMonthlyChartShow(rentalCountItem, rentalRateItem);
          handleDailyChartShow(rentalDailyCountItem);
        } else {
          handleMonthlyChartHidden(rentalCountItem, rentalRateItem);
          handleDailyChartHidden(rentalDailyCountItem);
        }

        if (monthlyRental) {
          handleMonthlyChartShow(monthlyRentalCountItem, monthlyRentalRateItem);
          handleDailyChartShow(monthlyRentalDailyCountItem);
        } else {
          handleMonthlyChartHidden(
            monthlyRentalCountItem,
            monthlyRentalRateItem
          );
          handleDailyChartHidden(monthlyRentalDailyCountItem);
        }
      }
    }
  }, [chartInputs]);
  return (
    <GuestWrapper>
      <ChartTitle>
        {areaName} <span>월간</span> 우대빵 손님 등록수 & 손님 확보 비율
      </ChartTitle>

      <ChartWrapper>
        <Chart
          ref={chartMonthlyRef}
          type="line"
          data={monthlyChartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: {
                type: "linear",
                display: true,
                position: "left",
                title: {
                  display: true,
                  text: "손님 등록 수",
                  padding: {
                    top: 0,
                    bottom: 10,
                    y: 0,
                  },
                },
              },
              y1: {
                type: "linear",
                display: true,
                position: "right",
                title: {
                  display: true,
                  text: "손님 확보 비율",
                  padding: {
                    top: 0,
                    bottom: 10,
                    y: 0,
                  },
                },
                ticks: {
                  callback(tickValue, index, ticks) {
                    if (typeof tickValue === "string") {
                      return `${tickValue}%`;
                    } else {
                      return `${tickValue.toFixed(1)}%`;
                    }
                  },
                },
                // grid line settings
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
              },
            },
          }}
        />
      </ChartWrapper>

      <ChartTitle>
        {areaName} <span>일간</span> 우대빵 손님 등록수
      </ChartTitle>

      <ChartWrapper>
        <Chart
          ref={chartDailyRef}
          type="line"
          data={dailyChartData}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </ChartWrapper>
    </GuestWrapper>
  );
}

export default GuestChart;

const ChartWrapper = styled.div`
  width: 100%;
  height: 50vh;
`;

const ChartTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin: 16px 0 0;
  span {
    color: red;
  }
`;
