import { SelectOption } from "./interface/CommonInterface";

/**
 * AntSelect YearOption 함수
 * @param recentYear 현재년도부터 최근 recentYear 사이의 년도(default: 최근 5년)
 * @returns recentYearSelectOpiton 현재년도~(현재년도-recentYear)까지의 selectOption
 */
export function getSelectYearOptions(startYear?: number, endYear?: number) {
  const now = new Date();
  const validYear = endYear || now.getFullYear();

  const recentYearSelectOpiton: SelectOption[] = [];

  if (!startYear) {
    for (let i = 2014; i <= validYear; i++) {
      recentYearSelectOpiton.push({
        value: i.toString(),
        label: i.toString() + "년",
      });
    }
  } else {
    for (let i = startYear; i <= validYear; i++) {
      recentYearSelectOpiton.push({
        value: i.toString(),
        label: i.toString() + "년",
      });
    }
  }

  return recentYearSelectOpiton.reverse();
}

/**
 * AntSelect MonthOption 함수
 * @param maxMonth 1월~maxMonth월(default: 1월부터 현재 월까지)
 * @returns monthSelectOption 1월~maxMonth월까지 selectOption
 */
export function getSelectMonthOptions(maxMonth?: number | string) {
  const now = new Date();
  const nowMonth = now.getMonth() + 1;

  const monthSelectOption: SelectOption[] = [];

  if (!maxMonth) {
    for (let i = 1; i <= nowMonth; i++) {
      if (i < 10) {
        monthSelectOption.push({
          value: `0${i.toString()}`,
          label: `0${i.toString()}월`,
        });
      } else {
        monthSelectOption.push({
          value: `${i.toString()}`,
          label: `${i.toString()}월`,
        });
      }
    }
  } else {
    for (let i = 1; i <= maxMonth; i++) {
      if (i < 10) {
        monthSelectOption.push({
          value: `0${i.toString()}`,
          label: `0${i.toString()}월`,
        });
      } else {
        monthSelectOption.push({
          value: `${i.toString()}`,
          label: `${i.toString()}월`,
        });
      }
    }
  }

  return monthSelectOption;
}

export function getNowYear(recentData: number) {
  const now = new Date();
  const year = now.getFullYear();
  const yearArr = [];

  const month = now.getMonth() + 1;
  const monthArr = [];
  const nowMonthArr = [];

  for (let i = 0; i < recentData; i++) {
    yearArr.push((year - i).toString());
  }

  for (let i = 1; i <= 12; i++) {
    i < 10 ? monthArr.push("0" + i.toString()) : monthArr.push(i.toString());
  }

  for (let i = 1; i <= month; i++) {
    nowMonthArr.push(i.toString() + "월");
  }

  return { yearArr, monthArr, nowMonthArr };
}

export function getDate() {
  const now = new Date();
  const nowDate = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();

  const month = now.getMonth() + 1;
  const nowMonthBefore1Month = [];

  for (let i = 1; i <= month; i++) {
    if (i > 1) {
      i < 11
        ? nowMonthBefore1Month.push("0" + (i - 1).toString())
        : nowMonthBefore1Month.push((i - 1).toString());
    }
  }

  const nowMonthBeforeMonth = (beforeMonthInput: number) => {
    const beforeDate = new Date();

    beforeDate.setMonth(now.getMonth() + 1 - beforeMonthInput);
    const beforeMonth = beforeDate.getMonth();

    if (beforeMonth > 1 && beforeMonth < 10) {
      return `0${beforeMonth}`;
    } else {
      return beforeMonth.toString();
    }
  };

  return { now, nowDate, nowMonthBefore1Month, nowMonthBeforeMonth };
}

export function getFomatter() {
  const numberFormatter = new Intl.NumberFormat(navigator.language, {
    notation: "compact",
    compactDisplay: "short",
  });

  return { numberFormatter };
}

export function getThousandsComma(value: number | string) {
  if (typeof value === "string") {
    return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } else if (typeof value === "number") {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}

export function getMonthPrefixZero(monthValue: string) {
  return ("0" + monthValue).slice(-2);
}
