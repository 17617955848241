export type DistrictType = "BJDONG" | "SIGUNGU";
export const DistrictType = {
  BJDONG: "BJDONG",
  SIGUNGU: "SIGUNGU",
} as const;

export interface DistrictResponse {
  totalCd: string;
  sido: DistrictAreaInfo;
  sigungu: DistrictAreaInfo;
  bjdong: DistrictAreaInfo;
}

export interface DistrictAreaInfo {
  code: string;
  name: string;
}
