import { useState } from "react";
import {
  wdbTotalIndicatorsRequest,
  CompanyGroupRequest,
  RequiredRequest,
} from "../../../interface/CommonRequest";
import { TradeRate, TradeRateRequest } from "../../../interface/TradeRate";
import {
  getTradeRateApi,
  getTradeRateCompanyGroupApi,
} from "../../../store/api/tradeRateApi";

function useTradeRate() {
  const getTradeCompanyGroupData = async (params: CompanyGroupRequest) => {
    const response = await getTradeRateCompanyGroupApi(params);
    if (response.error) return;

    return response.data;
  };

  return {
    getTradeCompanyGroupData,
  };
}

export default useTradeRate;
