import { color } from "./color";
import { DefaultTheme } from "styled-components";

export const darkTheme: DefaultTheme = {
  colorLight: color.darkMode.white,
  color: color.darkMode.light95,
  colorSecond: color.darkMode.light70,
  bgColorLight: color.darkMode.black20,
  bgColor: color.darkMode.black10,
  bgColorSecond: color.darkMode.black,
};

export const lightTheme: DefaultTheme = {
  colorLight: color.darkMode.black,
  color: color.darkMode.black10,
  colorSecond: color.darkMode.black20,
  bgColorLight: color.darkMode.white,
  bgColor: color.darkMode.light95,
  bgColorSecond: color.darkMode.light90,
};
