import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { BASE_URL } from "../../config";
import { getCookieToken } from "../cookies";

const clientAxios: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    access_token: getCookieToken(),
  },
  validateStatus: function (status) {
    return status < 500;
  },
});

clientAxios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await getCookieToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

clientAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default clientAxios;
