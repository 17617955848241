import { Divider, MenuItem, Tab, Tabs } from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import AreaBreadCrumb from "../components/AreaBreadCrumb";
import { ContentBox } from "../components/ContentBox";
import RequiredCondition from "../components/RequiredCondition";
import WdbPaperContainer from "../components/WdbPaperContainer";
import TotalCommisionInputs from "../components/brokerageCommissionSize/content/tab/TotalCommisionInputs";
import SelectGroup from "../components/select/SelectGroup";
import WdbSelect from "../components/select/WdbSelect";
import UndefinedRateTooltip from "../components/tooltip/UndefinedRateTooltip";
import BrokerageCommissionTabContent from "../containers/brokerageCommissionSize/components/BrokerageCommissionTabContent";
import useAreaController from "../hook/useAreaController";
import useRangeController from "../hook/useRangeController";
import useTradeSales from "../hook/useTradeSales";
import { SearchType } from "../interface/CommonRequest";
import { selectableSidoOptionList } from "../static/optionList";
import { getSelectYearOptions } from "../utils";
import { getSelectableMonthList } from "../utils/dateUtils";
import { prefixZeroFomatter } from "../utils/numberUtils";

export type BrokerageCommissionTab =
  | "TRADING_SIZE"
  | "TOTAL_COMMISSION_SIZE"
  | "WDB_COMMISSION"
  | "ANALYZE_TRADING_TYPE";
export const BrokerageCommissionTab = {
  TRADING_SIZE: "TRADING_SIZE",
  TOTAL_COMMISSION_SIZE: "TOTAL_COMMISSION_SIZE",
  WDB_COMMISSION: "WDB_COMMISSION",
  ANALYZE_TRADING_TYPE: "ANALYZE_TRADING_TYPE",
} as const;

const tabs: { label: string; value: BrokerageCommissionTab }[] = [
  { label: "거래건수/거래금액", value: BrokerageCommissionTab.TRADING_SIZE },
  {
    label: "전체 중개수수료 규모",
    value: BrokerageCommissionTab.TOTAL_COMMISSION_SIZE,
  },
  { label: "우대빵 중개수수료", value: BrokerageCommissionTab.WDB_COMMISSION },
  {
    label: "전월세 신규/갱신 분석",
    value: BrokerageCommissionTab.ANALYZE_TRADING_TYPE,
  },
];
const [currentYear, currentMonth] = [
  new Date().getFullYear(),
  new Date().getMonth(),
];
const VALID_START_YEAR = 2014;
const [VALID_END_YEAR, VALID_END_MONTH] = [
  currentMonth ? currentYear : currentYear - 1,
  currentMonth || 12,
];

function BrokerageCommissionSizePage() {
  const { selectedYear, selectedMonth, onChangeYear, onChangeMonth } =
    useRangeController(VALID_END_YEAR, 0);
  const {
    selectableSigunguList,
    selectedSido,
    selectedSigungu,

    onChangeSido,
    onChangeSigungu,
    resetSigungu,
  } = useAreaController();
  const {
    formattedUserInputValues,
    userInputValues,
    setUserInputValues,
    tradeSizeResult,
    calculatedSalesRate,

    getTradeSalesSize,
    getAutomaticallyTradeSalesSize,
    loading,
  } = useTradeSales();

  const [tabValue, setTabValue] = useState<BrokerageCommissionTab>(
    BrokerageCommissionTab.TRADING_SIZE
  );

  const shownRequiredCondition = selectedSido === "전체";
  const isNotSelectedSigungu = useMemo(() => {
    return selectedSigungu === "전체";
  }, [selectedSigungu]);

  const formattedParams = {
    year: selectedYear,
    month: !!selectedMonth ? selectedMonth : undefined,
    areaCode: isNotSelectedSigungu ? selectedSido : selectedSigungu,
    searchType: isNotSelectedSigungu ? SearchType.SIGUNGU : SearchType.BJDONG,
  };

  useEffect(() => {
    getAutomaticallyTradeSalesSize(formattedParams);
  }, [selectedYear, selectedMonth, selectedSido, selectedSigungu]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: BrokerageCommissionTab
  ) => {
    setTabValue(newValue);
  };

  const onSubmitUserInputValues = () => {
    getTradeSalesSize({
      ...formattedParams,
      ...formattedUserInputValues,
    });
  };

  return (
    <BrokerageCommissionSizePageWrapper>
      <WdbPaperContainer>
        <SelectGroup label={"기간"}>
          <WdbSelect
            label={"연도"}
            value={selectedYear}
            onChange={onChangeYear}
          >
            {getSelectYearOptions(VALID_START_YEAR, VALID_END_YEAR).map(
              (year) => (
                <MenuItem value={Number(year.value)}>{year.label}</MenuItem>
              )
            )}
          </WdbSelect>
          <WdbSelect
            label={"월"}
            value={selectedMonth}
            onChange={onChangeMonth}
          >
            <MenuItem value={0}>{"전체"}</MenuItem>
            {getSelectableMonthList(selectedYear, VALID_END_MONTH).map(
              (month) => (
                <MenuItem value={Number(month)}>
                  {prefixZeroFomatter(month)}
                </MenuItem>
              )
            )}
          </WdbSelect>
        </SelectGroup>
        <SelectGroup label={"지역"}>
          <WdbSelect
            label={"시/도"}
            value={selectedSido}
            onChange={onChangeSido}
          >
            {selectableSidoOptionList.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </WdbSelect>
          <WdbSelect
            label={"시/군/구"}
            value={selectedSigungu}
            onChange={onChangeSigungu}
          >
            {selectableSigunguList.length > 0 ? (
              selectableSigunguList.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))
            ) : (
              <MenuItem value="전체">전체</MenuItem>
            )}
          </WdbSelect>
        </SelectGroup>
      </WdbPaperContainer>

      <RequiredCondition
        shown={shownRequiredCondition}
        text={"시/도를 선택해주세요."}
      />

      {!shownRequiredCondition && (
        <ContentBox>
          <AreaBreadCrumb
            sidoSelect={selectableSidoOptionList.find(
              (option) => option.value === selectedSido
            )}
            sigunguSelect={selectableSigunguList.find(
              (option) => option.value === selectedSigungu
            )}
            onClickSigunguXButton={resetSigungu}
          />
          <Divider sx={{ mt: 3, mb: 2 }} />
          <Tabs value={tabValue} onChange={handleChange}>
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          {/* Todo hook 분리 후 컴포넌트 분리해야함. */}
          <BrokerageCommissionTabContent
            value={tabValue}
            results={tradeSizeResult}
            userInputValuesController={
              <TotalCommisionInputs
                inputs={userInputValues}
                setInputs={setUserInputValues}
                salesRate={calculatedSalesRate}
                onClick={onSubmitUserInputValues}
              />
            }
            undefineRateTooltip={
              <UndefinedRateTooltip rate={userInputValues.undefineToNewRate} />
            }
            loading={loading}
          />
        </ContentBox>
      )}
    </BrokerageCommissionSizePageWrapper>
  );
}

export default BrokerageCommissionSizePage;

const BrokerageCommissionSizePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & .MuiTabs-root {
    margin-bottom: 16px;
  }
`;
