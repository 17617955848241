import { GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { ReactElement } from "react";
import styled from "styled-components";
import { GridCaption } from "../../../../components/GridCaption";
import GroupHeaderChip from "../../../../components/table/GroupHeaderChip";
import { TradeSalesTotalColumn } from "../../../../interface/TradeSales";
import { color } from "../../../../styles/color";
import {
  COMMON_COLUMNS_OPTIONS,
  getCellValueTotal,
} from "../../utils/tableResourceFormatter";

function AnalyzedRentalTable({
  gridData,
  undefineRateTooltip,
  loading,
}: {
  gridData: TradeSalesTotalColumn[];
  undefineRateTooltip: ReactElement;
  loading: boolean;
}) {
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "전세",
      headerAlign: "center",
      headerClassName: "rental-header",
      renderHeaderGroup: () => (
        <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
      ),
      children: [
        {
          groupId: "신규",
          headerAlign: "center",
          children: [
            {
              field: "rentalDepositNewPrice",
            },
          ],
        },
        {
          groupId: "갱신",
          headerAlign: "center",
          children: [
            {
              field: "rentalDepositReNewPrice",
            },
          ],
        },
        {
          groupId: "미확인",
          headerAlign: "center",
          children: [
            {
              field: "rentalDepositUndefinePrice",
            },
          ],
        },
        {
          groupId: "최소(신규)",
          renderHeaderGroup: () => (
            <p>
              최소
              <br />
              <Secondary>(신규 거래)</Secondary>
            </p>
          ),
          headerAlign: "center",
          children: [
            {
              field: "rentalDepositMinPrice",
            },
          ],
        },
        {
          groupId: `최대(신규+미확인*F)`,
          renderHeaderGroup: () => (
            <p>
              최대 {undefineRateTooltip}
              <br />
              <Secondary>(신규+(미확인*F))</Secondary>
            </p>
          ),
          headerAlign: "center",
          children: [
            {
              field: "rentalTradeMaxPrice",
            },
          ],
        },
      ],
    },
    {
      groupId: "월세",
      headerAlign: "center",
      headerClassName: "monthly-header",
      renderHeaderGroup: () => (
        <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
      ),
      children: [
        {
          groupId: "신규",
          headerAlign: "center",
          children: [
            {
              field: "monthlyNewPrice",
            },
            {
              field: "monthlyDepositNewPrice",
            },
            {
              field: "monthlyTradeNewPrice",
            },
          ],
        },
        {
          groupId: "갱신",
          headerAlign: "center",
          children: [
            {
              field: "monthlyReNewPrice",
            },
            {
              field: "monthlyDepositReNewPrice",
            },
            {
              field: "monthlyTradeReNewPrice",
            },
          ],
        },
        {
          groupId: "미확인",
          headerAlign: "center",
          children: [
            {
              field: "monthlyUndefinePrice",
            },
            {
              field: "monthlyDepositUndefinePrice",
            },
            {
              field: "monthlyTradeUndefinePrice",
            },
          ],
        },
        {
          groupId: "전체 합계",
          headerAlign: "center",
          children: [
            {
              field: "monthlyTotalPrice",
            },
            {
              field: "monthlyDepositTotalPrice",
            },
            {
              field: "monthlyTradeTotalPrice",
            },
          ],
        },
        {
          groupId: "최소(신규)",
          renderHeaderGroup: () => (
            <p>
              최소
              <br />
              <Secondary>(신규 거래)</Secondary>
            </p>
          ),
          headerAlign: "center",
          children: [
            {
              field: "monthlyMinPrice",
            },
            {
              field: "monthlyDepositMinPrice",
            },
            {
              field: "monthlyTradeMinPrice",
            },
          ],
        },
        {
          groupId: `최대(신규+미확인*F)`,
          renderHeaderGroup: () => (
            <p>
              최대 {undefineRateTooltip}
              <br />
              <Secondary>(신규+(미확인*F))</Secondary>
            </p>
          ),
          headerAlign: "center",
          children: [
            {
              field: "monthlyMonthlyMaxPrice",
            },
            {
              field: "monthlyDepositMaxPrice",
            },
            {
              field: "monthlyRentalTradeMaxPrice",
            },
          ],
        },
      ],
    },
  ];

  const columns = [
    { field: "areaName", headerName: "구분", minWidth: 150, sortable: false },
    {
      field: "rentalDepositNewPrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "rentalDepositReNewPrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "rentalDepositUndefinePrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "rentalDepositMinPrice",
      headerName: "거래금액",
      flex: 1,
    },
    {
      field: "rentalTradeMaxPrice",
      headerName: "거래금액",
      flex: 1,
    },

    {
      field: "monthlyNewPrice",
      headerName: "월세",
      flex: 0.6,
    },
    {
      field: "monthlyDepositNewPrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "monthlyTradeNewPrice",
      headerName: "거래금액",
      flex: 1,
    },
    {
      field: "monthlyReNewPrice",
      headerName: "월세",
      flex: 0.6,
    },
    {
      field: "monthlyDepositReNewPrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "monthlyTradeReNewPrice",
      headerName: "거래금액",
      flex: 1,
    },
    {
      field: "monthlyUndefinePrice",
      headerName: "월세",
      headerAlign: "right",
      align: "right",
      flex: 0.6,
    },
    {
      field: "monthlyDepositUndefinePrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "monthlyTradeUndefinePrice",
      headerName: "거래금액",
      flex: 1,
    },
    {
      field: "monthlyTotalPrice",
      headerName: "월세",
      flex: 0.6,
    },
    {
      field: "monthlyDepositTotalPrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "monthlyTradeTotalPrice",
      headerName: "거래금액",
      flex: 1,
    },
    {
      field: "monthlyMinPrice",
      headerName: "월세",
      flex: 0.6,
    },
    {
      field: "monthlyDepositMinPrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "monthlyTradeMinPrice",
      headerName: "거래금액",
      flex: 1,
    },
    {
      field: "monthlyMonthlyMaxPrice",
      headerName: "월세",
      flex: 0.6,
    },
    {
      field: "monthlyDepositMaxPrice",
      headerName: "보증금",
      flex: 0.8,
    },
    {
      field: "monthlyRentalTradeMaxPrice",
      headerName: "거래금액",
      flex: 1,
    },
  ];

  const formattedColumns: GridColDef[] = columns.map((item) => {
    const result: GridColDef = {
      ...item,
      ...COMMON_COLUMNS_OPTIONS,
    };
    return result;
  });

  return (
    <>
      <Wrapper>
        <GridCaption textAlign="right">(단위 : 만원)</GridCaption>
        <div style={{ height: "80vh", width: "100%" }}>
          <DataGridPro
            experimentalFeatures={{ columnGrouping: true }}
            columns={formattedColumns}
            rows={[...gridData, getCellValueTotal(gridData)]}
            rowHeight={40}
            disableSelectionOnClick
            columnGroupingModel={columnGroupingModel}
            loading={loading}
          />
        </div>
      </Wrapper>
    </>
  );
}

export default AnalyzedRentalTable;

const Wrapper = styled.div`
  & .rental-header {
    border-left: 4px double #f0f0f0;
    border-right: 4px double #f0f0f0;
  }
  & div [data-fields="|-rentalDepositNewPrice-|"],
  div [data-field="rentalDepositNewPrice"] {
    border-left: 4px double #f0f0f0;
  }
  & div [data-fields="|-rentalTradeMaxPrice-|"],
  div [data-field="rentalTradeMaxPrice"] {
    border-right: 4px double #f0f0f0;
  }
  & div [data-field="rentalDepositMinPrice"],
  div [data-field="rentalTradeMaxPrice"] {
    background: ${color.grid.background.rentalCell};
  }
  & div [data-field="monthlyTradeMinPrice"],
  div [data-field="monthlyRentalTradeMaxPrice"] {
    border-right: 4px solid #f0f0f0;
    background: ${color.grid.background.monthlyCell};
  }
  & div [data-field="monthlyTradeNewPrice"],
  div [data-field="monthlyTradeReNewPrice"],
  div [data-field="monthlyTradeUndefinePrice"],
  div [data-field="monthlyTradeTotalPrice"],
  div [data-field="monthlyTradeMinPrice"],
  div
    [data-fields="|-monthlyMinPrice-|-monthlyDepositMinPrice-|-monthlyTradeMinPrice-|"],
  div
    [data-fields="|-monthlyNewPrice-|-monthlyDepositNewPrice-|-monthlyTradeNewPrice-|"],
  div
    [data-fields="|-monthlyReNewPrice-|-monthlyDepositReNewPrice-|-monthlyTradeReNewPrice-|"],
  div
    [data-fields="|-monthlyUndefinePrice-|-monthlyDepositUndefinePrice-|-monthlyTradeUndefinePrice-|"],
  div
    [data-fields="|-monthlyTotalPrice-|-monthlyDepositTotalPrice-|-monthlyTradeTotalPrice-|"] {
    border-right: 2px solid #f0f0f0;
  }
  & div [data-id="total"] {
    border-top: 4px double #f0f0f0;
  }
`;

const Secondary = styled.span`
  font-weight: 500;
`;
