import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { GridRowModel } from "@mui/x-data-grid";
import { useState } from "react";
import WdbIcon from "../../../components/WdbIcon";
import DistrictDialog from "./DistrictDialog";

function DistrcitPopoverIcon(row: GridRowModel) {
  const [open, setOpen] = useState(false);

  const handleOpen = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <>
      <WdbIcon icon={faInfoCircle} onClick={() => handleOpen(true)} />
      <DistrictDialog row={row} open={open} onClose={() => handleOpen(false)} />
    </>
  );
}

export default DistrcitPopoverIcon;
