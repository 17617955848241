import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";
import { ContentBox } from "./ContentBox";
import { color } from "../styles/color";

interface RequiredConditionProps {
  shown: boolean;
  text: string;
}
function RequiredCondition({ shown, text }: RequiredConditionProps) {
  if (!shown) return null;
  return (
    <ContentBox>
      <RequiredConditionWrapper>
        <ErrorIcon color="disabled" />
        {text}
      </RequiredConditionWrapper>
    </ContentBox>
  );
}

export default RequiredCondition;

const RequiredConditionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 50px 0;
  font-size: 1.2rem;
`;
