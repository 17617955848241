import { Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridColumnGroupingModel,
} from "@mui/x-data-grid-pro";
import styled from "styled-components";
import { GridCaption } from "../../../../components/GridCaption";
import GroupHeaderChip from "../../../../components/table/GroupHeaderChip";
import WdbTooltip from "../../../../components/tooltip/WdbTooltip";
import { TradeSalesMediationCommisionSalesColumn } from "../../../../interface/TradeSales";
import { color } from "../../../../styles/color";
import {
  COMMON_COLUMNS_OPTIONS,
  getCellValueTotal,
} from "../../utils/tableResourceFormatter";

function WdbBrokerageCommissionSizeTable({
  gridData,
  loading,
}: {
  gridData: TradeSalesMediationCommisionSalesColumn[];
  loading: boolean;
}) {
  return (
    <Wrapper>
      <GridCaption textAlign="right">(단위 : 만원)</GridCaption>
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGridPro
          experimentalFeatures={{ columnGrouping: true }}
          columns={formattedColumns}
          rows={[...gridData, getCellValueTotal(gridData)]}
          rowHeight={40}
          disableSelectionOnClick
          columnGroupingModel={columnGroupingModel}
          loading={loading}
        />
      </div>
    </Wrapper>
  );
}

export default WdbBrokerageCommissionSizeTable;

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "[우대빵 거래 점유율] 적용 시 매출(양타)",
    renderHeaderGroup: () => (
      <p>
        <RedText>[우대빵 거래 점유율]</RedText> 적용시 매출 (양타)
      </p>
    ),
    headerAlign: "center",
    headerClassName: "both-standard-sales-header",
    children: [
      {
        field: "tradeBothStandardSales",
      },
      {
        field: "rentalBothStandardSales",
      },
      {
        field: "monthlyBothStandardSales",
      },
    ],
  },
  {
    groupId: "[우대빵 거래 점유율]과 [매출 비율] 적용 시 매출(양타)",
    renderHeaderGroup: () => (
      <p>
        <RedText>[우대빵 거래 점유율]</RedText>과 <RedText>[매출 비율]</RedText>{" "}
        적용시 매출 (양타)
      </p>
    ),
    headerAlign: "center",
    headerClassName: "apply-both-rate-sales-header",
    children: [
      {
        field: "tradeApplyBothRateSales",
      },
      {
        field: "rentalApplyBothRateSales",
      },
      {
        field: "monthlyApplyBothRateSales",
      },
    ],
  },
  {
    groupId: "매출 금액",
    headerAlign: "center",
    headerClassName: "total-header",
    children: [
      {
        field: "discountTotalSales",
      },
      {
        field: "totalSales",
      },
    ],
  },
];

const columns: GridColDef[] = [
  { field: "areaName", headerName: "구분", minWidth: 150, sortable: false },
  {
    field: "tradeBothStandardSales",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),

    flex: 1,
  },
  {
    field: "rentalBothStandardSales",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),

    flex: 1,
  },
  {
    field: "monthlyBothStandardSales",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),

    flex: 1,
  },
  {
    field: "tradeApplyBothRateSales",
    headerName: "매매",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),

    flex: 1,
  },
  {
    field: "rentalApplyBothRateSales",
    headerName: "전세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),

    flex: 1,
  },
  {
    field: "monthlyApplyBothRateSales",
    headerName: "월세",
    renderHeader: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),

    flex: 1,
  },
  {
    field: "totalSales",
    headerName: "합계",
    renderHeader: () => (
      <p>
        합계{" "}
        <WdbTooltip
          title={
            <>
              [우대빵 거래 점유율]과 [매출 비율]을 <br />
              적용한 매출 (양타)의 총 합계
              <br />
              <br />
              (매매 + 전세 + 월세)
            </>
          }
        />
      </p>
    ),
    flex: 1.1,
  },
  {
    field: "discountTotalSales",
    headerName: "할인율 적용 시",

    flex: 1.1,
  },
];

const formattedColumns: GridColDef[] = columns.map((item) => {
  const result: GridColDef = {
    ...item,
    ...COMMON_COLUMNS_OPTIONS,
  };
  return result;
});

const Wrapper = styled.div`
  & .both-standard-sales-header {
    border-left: 4px double #f0f0f0;
    border-right: 4px double #f0f0f0;
  }
  & .apply-both-rate-sales-header {
    border-right: 4px double #f0f0f0;
  }
  & div [data-field="tradeBothStandardSales"] {
    border-left: 4px double #f0f0f0;
  }
  & div [data-field="monthlyBothStandardSales"] {
    border-right: 4px double #f0f0f0;
  }
  & div [data-field="monthlyApplyBothRateSales"] {
    border-right: 4px double #f0f0f0;
  }
  & div [data-field="discountTotalSales"] {
    background: ${color.grid.background.tradeCell};
  }
  & div [data-id="total"] {
    border-top: 4px double #f0f0f0;
  }
`;

const RedText = styled.span`
  color: ${color.woodaeRed};
  font-weight: 400;
`;
