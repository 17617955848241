import { useState } from "react";
import { getSalesHoldingChartApi } from "../store/api/salesHoldingApi";
import { ChartData } from "../interface/chartFormatter";
import {
  getFormattedSalesHoldingNaverChartData,
  getFormattedSalesHoldingWdbChartData,
} from "./../containers/salesHolding/utils/chartResourceFormatter";
import {
  SalesHoldingChartRequest,
  SalesHoldingChartResponse,
} from "./../interface/SalesHolding";

function useSalesHolding() {
  const [includingOfficetels, setIncludingOfficetels] = useState(false);

  const [resultChartData, setResultChartData] =
    useState<SalesHoldingChartResponse>();
  const [resultChartData2, setResultChartData2] =
    useState<SalesHoldingChartResponse>();

  const [wdbChartData, setWdbChartData] = useState<ChartData>(
    getFormattedSalesHoldingWdbChartData(resultChartData)
  );

  const [naverChartData, setNaverChartData] = useState<ChartData>(
    getFormattedSalesHoldingNaverChartData(resultChartData)
  );

  const onChangeIncludingOfficetels = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIncludingOfficetels(event.target.checked);
  };

  const getSalesHoldingChartData = async (
    params: SalesHoldingChartRequest,
    dataLength: number
  ) => {
    const response = await getSalesHoldingChartApi(params);
    if (response.error) return;
    const { data } = response;

    switch (dataLength) {
      default:
        break;
      case 1:
        setResultChartData(data);
        break;
      case 2:
        setResultChartData2(data);
        break;
    }
  };

  return {
    includingOfficetels,

    onChangeIncludingOfficetels,

    getSalesHoldingChartData,
    resultChartData,
    resultChartData2,

    wdbChartData,
    setWdbChartData,
    naverChartData,
    setNaverChartData,
  };
}

export default useSalesHolding;
