import { useQuery } from "@tanstack/react-query";

import clientAxios from "../store/api/customAxios";

export function TransactionCountPage() {
  const { isLoading, isError, data, error } = useQuery(
    ["district"],
    () =>
      clientAxios.get(
        "https://dev.api.data.wdbadmin.co.kr/api/districts?districtType=SIDO"
      ),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        console.log("######## ReactQuery : ", data);
      },
      onError: (e) => {
        console.log("### ReactQuery Error : ", e);
      },
    }
  );

  return (
    <>
      <div>실거래 데이터 (건수) 페이지{data?.data.data.sido}</div>
    </>
  );
}
