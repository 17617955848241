import { ColumnDefinition } from "react-tabulator";
import { getThousandsComma } from "../../utils";
import { GridColDef } from "@mui/x-data-grid";
import {
  getValueFormattedPercent,
  getValueFormattedWon,
} from "../../utils/gridUtils";

export const commisionColumn: GridColDef[] = [
  {
    field: "거래금액",
    headerName: "거래금액",
    headerAlign: "left",
    align: "left",
    flex: 0.5,
  },
  {
    field: "상한요율",
    headerName: "상한요율",
    headerAlign: "left",
    align: "right",
    flex: 0.25,
    valueFormatter: getValueFormattedPercent,
  },
  {
    field: "한도액",
    headerName: "한도액",
    headerAlign: "left",
    align: "right",
    flex: 0.25,
    valueFormatter: getValueFormattedWon,
  },
];
