export const ADMIN_PAGE = `${process.env.REACT_APP_ADMIN_PAGE}`;

/* API 설정 */

export const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export const API_MYINFO = `/account/my-info`; // 유저 정보 API
export const API_DISTRICTS = `/districts`; // 지역 정보 API

export const API_REAL_TRADE_PRICES = `/real-trade-prices`; // 실거래가 API

export const API_OFFER_STAT = "/offers/stat"; // 지역별 보유 매물현황 API
export const API_OFFERS_TRADE_RATE = "/offers/trade-rate"; // 지역별 보유 매물 거래 비율 API
export const API_OFFERS_TRADE_RATE_CHART = "/offers/trade-rate/chart"; // 특정 지역 보유 매물 거래 흐름 차트 API
export const API_OFFERS_COMPANY_GROUP = "/offers/trade/company-group"; // 지점별 보유 매물 거래 건수 API

export const API_BUYING_ACQUISITION_RATE = "/buyings/acquisition-rate"; // 손님확보비율 API
export const API_BUYING_COMPANY_GROUP = "/buyings/company-group"; // 지점별 손님확보비율 API

export const API_TRADE_SALES_STAT = "/trade-sales/stat"; // 지역별 매출 규모 API

export const API_MANAGERS_DATE_STAT = "/managers/date"; // 중개매니저 날짜조회 API
