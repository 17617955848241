import React from "react";
import styled from "styled-components";
import { useMenu } from "../hook/useMenu";

type Props = {
  onOverlay: string;
  isMenu: boolean;
};
export default function Overlay({ onOverlay, isMenu }: Props) {
  const { setMenu } = useMenu();
  const handleOverlayClick = () => {
    setMenu(!isMenu);
  };

  return <OverlayWrap className={onOverlay} onClick={handleOverlayClick} />;
}

const OverlayWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: hidden;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;

  &.overlay-on {
    opacity: 1;
    visibility: visible;
  }
`;
