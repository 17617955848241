import { GridCellParams, GridValueFormatterParams } from "@mui/x-data-grid";
import GridProgressBar from "../components/GridProgressBar";

export const getValueFormattedPercent = (params: GridValueFormatterParams) => {
  switch (params.value) {
    default:
      return `${params.value}%`;
    case "-":
      return params.value;
    case undefined: // 0일 경우가 있어 따로 분리
    case null:
      return "";
  }
};

export const getValueFormattedToLocaleString = (
  params: GridValueFormatterParams
) => {
  switch (params.value) {
    default:
      return params.value.toLocaleString();
    case undefined: // 0일 경우가 있어 따로 분리
    case null:
      return "";
  }
};

export const getValueFormatteSquareMeter = (
  params: GridValueFormatterParams
) => {
  return `${params.value}㎡`;
};

export const getValueFormattedWon = (params: GridValueFormatterParams) => {
  switch (params.value) {
    default:
      return `${params.value.toLocaleString()}원`;
    case 0:
      return "-";
  }
};
