import { Box } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        m: 1,
      }}
    >
      <GridToolbarQuickFilter placeholder="지역명을 입력하세요." />
    </Box>
  );
}

export default QuickSearchToolbar;
