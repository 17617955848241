import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Checkbox, Chip, FormControlLabel, Stack } from "@mui/material";
import { useMemo } from "react";
import styled from "styled-components";
import { SalesHodlingContentsType } from "../containers/salesHolding/components/SalesHoldingTabContents";
import { SelectOption } from "../interface/CommonInterface";
import { color } from "../styles/color";
import { FlexWrapper } from "./atoms/layout/FlexWrapper";

function TradeTypeChips({
  chartTradeTypeList,
  setChartTradeTypeList,
  selectedAreaChips,
  setSelectedAreaChips,
  sidoSelect,
  isManager,
}: Pick<
  SalesHodlingContentsType,
  | "chartTradeTypeList"
  | "setChartTradeTypeList"
  | "selectedAreaChips"
  | "setSelectedAreaChips"
  | "sidoSelect"
> & { isManager?: boolean }) {
  const { total, trade, rental, monthlyRental } = chartTradeTypeList;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartTradeTypeList({
      ...chartTradeTypeList,
      [event.target.name]: event.target.checked,
    });
  };
  const checkBoxColor = useMemo(
    () => ({
      color: color.woodaeRed,
      "&.Mui-checked": {
        color: color.woodaeRed,
      },
    }),
    []
  );

  const handleSelectedAreaChipClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const selectedChips = selectedAreaChips.filter(
      (chip: SelectOption) =>
        chip.value !== event.currentTarget.tabIndex.toString()
    );

    selectedChips.length > 0
      ? setSelectedAreaChips(selectedChips)
      : alert("지역은 한 군데 이상 선택되어야 합니다.");
  };
  return (
    <FlexWrapper>
      {isManager === false ? (
        <></>
      ) : (
        <>
          <FlexWrapper gap="0">
            <Topic>
              <DoneIcon fontSize="small" />
              거래타입 :
            </Topic>
            <Wrapper
              flexDirection={"row"}
              flexWrap="wrap"
              columnGap="10px"
              rowGap="5px"
              alignItems={"center"}
              justifyContent="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={total}
                    onChange={handleChange}
                    name="total"
                    size="small"
                    sx={checkBoxColor}
                  />
                }
                label="전체"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={trade}
                    onChange={handleChange}
                    name={"trade"}
                    size="small"
                    sx={checkBoxColor}
                  />
                }
                label={"매매"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rental}
                    onChange={handleChange}
                    name={"rental"}
                    size="small"
                    sx={checkBoxColor}
                  />
                }
                label={"전세"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={monthlyRental}
                    onChange={handleChange}
                    name={"monthlyRental"}
                    size="small"
                    sx={checkBoxColor}
                  />
                }
                label={"월세"}
              />
            </Wrapper>
          </FlexWrapper>
          <FlexWrapper gap="0">
            <Topic>
              <FmdGoodIcon fontSize="small" />
              선택지역 :
            </Topic>
            <SelectedAreaBox
              flexDirection={"row"}
              gap="5px"
              alignItems={"center"}
              justifyContent="center"
            >
              {selectedAreaChips.length > 0 ? (
                selectedAreaChips.map((item: SelectOption) => (
                  <Chip
                    tabIndex={
                      item.value === "전체"
                        ? Number(sidoSelect?.value)
                        : Number(item.value)
                    }
                    label={
                      <FlexWrapper flexDirection="row" gap="3px">
                        <span>{item.label}</span>
                        <CancelIcon />
                      </FlexWrapper>
                    }
                    size="small"
                    onClick={handleSelectedAreaChipClick}
                    className="selected-chip"
                  />
                ))
              ) : (
                <span>지역을 골라주세요.</span>
              )}
            </SelectedAreaBox>
          </FlexWrapper>
        </>
      )}
    </FlexWrapper>
  );
}

export default TradeTypeChips;

const Wrapper = styled(Stack)`
  padding: 0;
  padding-left: 16px;
  svg {
    color: ${color.woodaeRed};
  }
  border-radius: 5px;
  background-color: white;
`;

const SelectedAreaBox = styled(Stack)`
  padding: 8px;
  svg {
    color: ${color.woodaeRed};
  }

  border-radius: 5px;
  background-color: white;
  .selected-chip {
    &:hover {
      background-color: ${color.chip.selected.background};
      opacity: 0.8;
    }

    span {
      color: ${color.chip.selected.color};
    }

    svg {
      color: white;
      font-size: 1rem;
    }
    background-color: ${color.chip.selected.background};
  }
`;

const Topic = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  svg {
    margin-right: 5px;
  }
`;
