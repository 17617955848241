import { API_MANAGERS_DATE_STAT } from "./../../config";
import { get, WebResponse } from "../../module/HTTPWebClient";
import clientAxios from "./customAxios";

import {
  MamagerRequest,
  ManagerDataColumn,
  MamagerMonthlyRequest,
  MamagerOfBranchRequest,
  ManagerDataRequest,
  ManagerChartRequest,
  ManagerChartResponse,
} from "../../interface/Manager";

export async function getManagerDailyChartData({
  accountInfoNo,
  companyGroupNo,
  startDate,
  endDate,
}: ManagerChartRequest): Promise<WebResponse<ManagerChartResponse[]>> {
  try {
    const data = await clientAxios.get(
      `managers/chart?accountInfoNo=${accountInfoNo}&companyGroupNo=${companyGroupNo}&endDate=${endDate}&startDate=${startDate}`
    );

    return {
      data: data.data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getManagerMonthlyData({
  accountInfoNo,
  companyGroupNo,
  year,
}: MamagerMonthlyRequest): Promise<WebResponse<ManagerDataRequest>> {
  try {
    const data = await clientAxios.get(
      `managers/monthly?accountInfoNo=${accountInfoNo}&companyGroupNo=${companyGroupNo}&year=${year}`
    );

    return {
      data: data.data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getManagerOfBranchList(
  companyGroupNo: number | undefined
): Promise<WebResponse<MamagerOfBranchRequest[]>> {
  try {
    const url = `/managers/${companyGroupNo}`;
    const { data } = await get<{
      data: MamagerOfBranchRequest[];
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        companyGroupNo,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getManagerDataApi({
  companyGroupNo,
  searchDate,
}: MamagerRequest): Promise<WebResponse<ManagerDataColumn[]>> {
  try {
    const url = `${API_MANAGERS_DATE_STAT}`;
    const { data } = await get<{
      data: ManagerDataColumn[];
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        companyGroupNo,
        searchDate,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}
