import {
  DataGridPro,
  GridColDef,
  GridColumnGroupingModel,
} from "@mui/x-data-grid-pro";
import { ReactElement } from "react";
import styled from "styled-components";
import { GridCaption } from "../../../../components/GridCaption";
import GroupHeaderChip from "../../../../components/table/GroupHeaderChip";
import { TradeSalesTradeCountPriceColumn } from "../../../../interface/TradeSales";
import { color } from "../../../../styles/color";
import {
  COMMON_COLUMNS_OPTIONS,
  getCellValueTotal,
} from "../../utils/tableResourceFormatter";

function TradeSalesCountAndPriceTable({
  gridData,
  undefineRateTooltip,
  loading,
}: {
  gridData: TradeSalesTradeCountPriceColumn[];
  undefineRateTooltip: ReactElement;
  loading: boolean;
}) {
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "매매",
      headerAlign: "center",
      headerClassName: "trade-header",
      renderHeaderGroup: () => (
        <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
      ),
      children: [
        {
          field: "tradeTotalCount",
        },
        {
          field: "tradeTotalPrice",
        },
      ],
    },
    {
      groupId: "전세",
      headerAlign: "center",
      headerClassName: "rental-header",
      renderHeaderGroup: () => (
        <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
      ),
      children: [
        {
          field: "rentalTotalCount",
        },
        {
          field: "rentalTradeTotalPrice",
        },
        {
          field: "rentalDepositMinPrice",
        },
        {
          field: "rentalTradeMaxPrice",
        },
      ],
    },
    {
      groupId: "월세",
      headerAlign: "center",
      headerClassName: "monthly-header",
      renderHeaderGroup: () => (
        <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
      ),
      children: [
        {
          field: "monthlyTotalCount",
        },
        {
          field: "monthlyTradeTotalPrice",
        },
        {
          field: "monthlyTradeMinPrice",
        },
        {
          field: "monthlyTradeMaxPrice",
        },
      ],
    },
  ];

  const columns: GridColDef[] = [
    { field: "areaName", headerName: "구분", minWidth: 150, sortable: false },
    {
      field: "tradeTotalCount",
      headerName: "거래건수", // 매매거래건수

      headerClassName: "trade-cell",
      cellClassName: "trade-cell",
      flex: 1,
    },
    {
      field: "tradeTotalPrice",
      headerName: "거래금액", // 매매거래금액

      headerClassName: "trade-cell",
      cellClassName: "trade-cell",
      flex: 1.4,
    },
    {
      field: "rentalTotalCount",
      headerName: "거래건수", // 전세전체합계거래건수

      headerClassName: "rental-cell",
      cellClassName: "rental-cell",
      flex: 1,
    },
    {
      field: "rentalTradeTotalPrice",
      headerName: "전체 거래금액", // 전세전체합계거래금액
      headerClassName: "rental-cell",
      cellClassName: "rental-cell",
      flex: 1.4,
    },

    {
      field: "rentalDepositMinPrice",
      headerName: "최소 거래금액", // 전세최소(신규)거래금액
      renderHeader: () => (
        <p>
          최소 거래금액
          <br />
          <Secondary>(신규 거래)</Secondary>
        </p>
      ),

      minWidth: 150,
      flex: 1.4,
    },
    {
      field: "rentalTradeMaxPrice",
      headerName: "최대 거래금액", // 전세최대(신규+미확인)거래금액
      renderHeader: () => (
        <p>
          최대 거래금액 {undefineRateTooltip}
          <br />
          <Secondary>(신규+(미확인*F))</Secondary>
        </p>
      ),

      minWidth: 150,
      flex: 1.4,
    },
    {
      field: "monthlyTotalCount",
      headerName: "거래건수", // 월세전체합계거래건수
      headerClassName: "monthly-cell",
      cellClassName: "monthly-cell",
      flex: 1,
    },
    {
      field: "monthlyTradeTotalPrice",
      headerName: "전체 거래금액", // 월세전체합계거래금액
      headerClassName: "monthly-cell",
      cellClassName: "monthly-cell",
      flex: 1.4,
    },
    {
      field: "monthlyTradeMinPrice",
      headerName: "최소 거래금액", // 최소(신규)거래금액합계
      renderHeader: () => (
        <p>
          최소 거래금액
          <br />
          <Secondary>(신규 거래)</Secondary>
        </p>
      ),

      flex: 1.6,
    },
    {
      field: "monthlyTradeMaxPrice",
      headerName: "최대 거래금액", // 최대(신규+미확인)거래금액합계
      renderHeader: () => (
        <p>
          최대 거래금액 {undefineRateTooltip}
          <br />
          <Secondary>(신규+(미확인*F))</Secondary>
        </p>
      ),

      flex: 1.6,
    },
  ];

  const formattedColumns: GridColDef[] = columns.map((item) => {
    const result: GridColDef = {
      ...item,
      ...COMMON_COLUMNS_OPTIONS,
    };
    return result;
  });

  return (
    <>
      <Wrapper>
        <GridCaption textAlign="right">(단위 : 만원)</GridCaption>
        <div style={{ height: "80vh", width: "100%" }}>
          <DataGridPro
            experimentalFeatures={{ columnGrouping: true }}
            rows={[...gridData, getCellValueTotal(gridData)]}
            rowHeight={40}
            columns={formattedColumns}
            disableSelectionOnClick
            columnGroupingModel={columnGroupingModel}
            loading={loading}
          />
        </div>
      </Wrapper>
    </>
  );
}

export default TradeSalesCountAndPriceTable;

const Wrapper = styled.div`
  .trade-header {
    border-left: 4px double #f0f0f0;
    border-right: 4px double #f0f0f0;
  }

  & .rental-header {
    border-right: 4px double #f0f0f0;
  }

  & div [data-field="tradeTotalCount"] {
    border-left: 4px double #f0f0f0;
  }
  & div [data-field="tradeTotalPrice"] {
    border-right: 4px double #f0f0f0;
    background: ${color.grid.background.tradeCell};
  }
  & div [data-fields="|-rentalTradeMaxPrice-|"] {
    border-right: 4px double #f0f0f0;
  }
  & div [data-field="rentalTradeMaxPrice"] {
    border-right: 4px double #f0f0f0;
  }
  & div [data-field="rentalTradeTotalPrice"] {
    background: ${color.grid.background.rentalCell};
  }
  & div [data-field="monthlyTradeTotalPrice"] {
    background: ${color.grid.background.monthlyCell};
  }
  & div [data-id="total"] {
    border-top: 4px double #f0f0f0;
  }
`;

const Secondary = styled.span`
  font-weight: 500;
`;
