import {
  API_OFFERS_TRADE_RATE_CHART,
  API_OFFERS_COMPANY_GROUP,
  API_OFFERS_TRADE_RATE,
} from "./../../config";
import {
  TradeRateChartRequest,
  TradeChartResponse,
  TradeRate,
  TradeRateCompanyGroup,
} from "../../interface/TradeRate";
import { get, WebResponse } from "../../module/HTTPWebClient";
import {
  wdbTotalIndicatorsRequest,
  CompanyGroupRequest,
  RequiredRequest,
} from "../../interface/CommonRequest";
import { AxiosResponse } from "axios";

export async function getTradeRateApi({
  areaCd,
  startDate,
  endDate,
}: wdbTotalIndicatorsRequest): Promise<WebResponse<TradeRate[]>> {
  try {
    const url = `${API_OFFERS_TRADE_RATE}`;
    const { data } = await get<{
      data: TradeRate[];
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        areaCd,
        startDate,
        endDate,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getTradeRateCompanyGroupApi({
  sigunguCode,
  startDate,
  endDate,
}: CompanyGroupRequest): Promise<WebResponse<TradeRateCompanyGroup[]>> {
  try {
    const url = `${API_OFFERS_COMPANY_GROUP}`;
    const { data } = await get<{
      data: TradeRateCompanyGroup[];
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        sigunguCode,
        startDate,
        endDate,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}

export async function getTradeChartApi({
  areaCd,
  endYearMonth,
  startYearMonth,
}: TradeRateChartRequest): Promise<WebResponse<TradeChartResponse>> {
  try {
    const url = `${API_OFFERS_TRADE_RATE_CHART}`;
    const { data } = await get<{
      data: TradeChartResponse;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        areaCd,
        endYearMonth,
        startYearMonth,
      },
    });
    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}
