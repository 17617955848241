import { PropsWithChildren } from "react";
import styled from "styled-components";

export interface Flex {
  justifyContent: JustifyContent;
  alignItem: AlignItem;
  flexDirection: FlexDirection;
  gap: string;
}

export type JustifyContent =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";
export type AlignItem = "center" | "flex-start" | "flex-end";
export type FlexDirection = "row" | "column";

FlexWrapper.defaultProps = {
  justifyContent: "center",
  alignItem: "center",
  flexDirection: "row",
  gap: "8px",
};

export function FlexWrapper(props: PropsWithChildren<Flex>) {
  return (
    <Wrapper
      justifyContent={props.justifyContent}
      alignItem={props.alignItem}
      flexDirection={props.flexDirection}
      gap={props.gap}
    >
      {props.children}
    </Wrapper>
  );
}

const Wrapper = styled.div<Flex>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItem};
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: wrap;
  gap: ${(props) => props.gap};
`;
