import { GridColDef } from "@mui/x-data-grid-pro";
import { BranchAssignedDistrictSummaryDto } from "../../../interface/Branch";
import {
  getEndOfMonth,
  getFormattedMonthAndDate,
} from "../../../utils/dateUtils";
import { transformNumberToRatio } from "../../../utils/numberUtils";
import { DynamicObject, transpose } from "./transpose";

interface Row {
  id: string;
  groupTitle: string;
  titleList: TitleObject[];
}

interface TitleObject {
  label: string;
  id: string;
  hierarchy: string[];
}
export const TOTAL_NAME = "합계 (+)";
export const TOTAL_NEW_NAME = "전체 (+)";

export const TOTAL_OFFER = "매물합계 (+)";
export const TOTAL_HOLD_OFFER = "매물합계 보유율 (+)";
const SALE_ITEM_TITLE_LIST = [TOTAL_OFFER, "매매", "전세", "월세"];
const SALE_ITEM_HOLD_OFFER_LIST = [
  TOTAL_HOLD_OFFER,
  "매매 보유율",
  "전세 보유율",
  "월세 보유율",
];
const COMMON_TITLE_LIST = [TOTAL_NAME, "매매", "전세", "월세"];
const COMMON_NEW_TITLE_LIST = [TOTAL_NEW_NAME, "매매", "전세", "월세"];

const COUNT_TYPE_LIST = [
  "totalCount",
  "tradeCount",
  "rentalCount",
  "monthlyCount",
];
const COUNT_HOLD_OFFER_TYPE_LIST = [
  "totalRate",
  "tradeRate",
  "rentalRate",
  "monthlyRate",
];
const RATE_TYPE_LIST = ["totalRate", "tradeRate", "rentalRate", "monthlyRate"];
const PRICE_TYPE_LIST = [
  "totalPrice",
  "tradePrice",
  "rentalPrice",
  "monthlyPrice",
];
const COMMISSION_TYPE_LIST = [
  "totalCommission",
  "tradeCommission",
  "rentalCommission",
  "monthlyCommission",
];

const getHierarchy = (groupTitle: string, list: string[], index: number) => {
  console.log("groupTitle, list, index :", groupTitle, list, index);

  if (index && groupTitle === "매물") {
    return [`${groupTitle}`, TOTAL_OFFER, list[index]]; // 왜 하위메뉴가안나와?
  }
  if (index && groupTitle === "매물 보유율") {
    return [`${groupTitle}`, TOTAL_HOLD_OFFER, list[index]]; // 왜 하위메뉴가안나와?
  }

  if (index && groupTitle === "건당 매출") {
    return [`${groupTitle}`, TOTAL_NEW_NAME, list[index]];
  } else if (index && groupTitle === "거래비율") {
    return [`${groupTitle}`, TOTAL_NEW_NAME, list[index]];
  } else if (index) {
    return [`${groupTitle}`, TOTAL_NAME, list[index]];
  } else {
    return [`${groupTitle}`, list[index]];
  }
};

const ROW_TITLE_LIST: Row[] = [
  {
    id: "buyingCount",
    groupTitle: "손님",
    titleList: [],
  },
  {
    id: "buyingRate",
    groupTitle: "타부동산대비손님등록률",
    titleList: [],
  },
  {
    id: "offerCount",
    groupTitle: "매물",
    titleList: SALE_ITEM_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: COUNT_TYPE_LIST[index], // COUNT_TYPE_LIST
        hierarchy: getHierarchy("매물", SALE_ITEM_TITLE_LIST, index),
      };
    }),
  },
  {
    id: "offerRate",
    groupTitle: "매물 보유율",
    titleList: SALE_ITEM_HOLD_OFFER_LIST.map((title, index) => {
      return {
        label: title,
        id: COUNT_HOLD_OFFER_TYPE_LIST[index], // COUNT_TYPE_LIST
        hierarchy: getHierarchy(
          "매물 보유율",
          SALE_ITEM_HOLD_OFFER_LIST,
          index
        ),
      };
    }),
  },
  // 2개가 같이 보이는데, 왜그런지 원인 분석 필요!
  // {
  //   id: "offerRate",
  //   groupTitle: "매물 보유율",
  //   titleList: SALE_ITEM_TITLE_LIST.map((title, index) => {
  //     return {
  //       label: title,
  //       id: COUNT_TYPE_LIST[index], // COUNT_TYPE_LIST
  //       hierarchy: getHierarchy("매물 보유율", SALE_ITEM_TITLE_LIST, index),
  //     };
  //   }),
  // },
  {
    id: "otherTradeCount",
    groupTitle: "타부동산 거래건수",
    titleList: COMMON_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: COUNT_TYPE_LIST[index],
        hierarchy: getHierarchy("타부동산 거래건수", COMMON_TITLE_LIST, index),
      };
    }),
  },
  {
    id: "contractCount",
    groupTitle: "계약",
    titleList: COMMON_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: COUNT_TYPE_LIST[index],
        hierarchy: getHierarchy("계약", COMMON_TITLE_LIST, index),
      };
    }),
  },
  {
    id: "tradeRate",
    groupTitle: "거래비율",
    titleList: COMMON_NEW_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: RATE_TYPE_LIST[index],
        hierarchy: getHierarchy("거래비율", COMMON_NEW_TITLE_LIST, index),
      };
    }),
  },
  {
    id: "tradePrice",
    groupTitle: "거래금액",
    titleList: COMMON_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: PRICE_TYPE_LIST[index],
        hierarchy: getHierarchy("거래금액", COMMON_TITLE_LIST, index),
      };
    }),
  },
  {
    id: "registerCommission",
    groupTitle: "매출(가계약일 기준)",
    titleList: COMMON_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: COMMISSION_TYPE_LIST[index],
        hierarchy: getHierarchy(
          "매출(가계약일 기준)",
          COMMON_TITLE_LIST,
          index
        ),
      };
    }),
  },
  {
    id: "perCommission",
    groupTitle: "건당 매출",
    titleList: COMMON_NEW_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: COMMISSION_TYPE_LIST[index],
        hierarchy: getHierarchy("건당 매출", COMMON_NEW_TITLE_LIST, index),
      };
    }),
  },
  {
    id: "balanceCommission",
    groupTitle: "매출(잔금일 기준)",
    titleList: COMMON_TITLE_LIST.map((title, index) => {
      return {
        label: title,
        id: COMMISSION_TYPE_LIST[index],
        hierarchy: getHierarchy("매출(잔금일 기준)", COMMON_TITLE_LIST, index),
      };
    }),
  },
];

function getDefaultObject({ id, groupTitle, titleList }: Row) {
  if (!titleList.length)
    //
    return [
      {
        id,
        groupTitle,
        title: "",
        hierarchy: [groupTitle],
      },
    ];

  return titleList.map((item, index) => {
    return {
      id: id + item.id,
      groupTitle: index ? "" : groupTitle,
      title: item.label,
      hierarchy: item.hierarchy,
    };
  });
}

export const getTreeDataPathOfAssignedDistrictSummary = (
  row: DynamicObject
) => {
  return row.hierarchy;
};

export function getFormattedBranchAssignedDistrictSummaryDataRow(
  year: number,
  summaryData: BranchAssignedDistrictSummaryDto[],
  formattedColumns: GridColDef[]
) {
  const transposedData = transpose(summaryData);
  const defaultTemplates = ROW_TITLE_LIST.map((row, index) =>
    getDefaultObject(row)
  ).flat();

  const result = defaultTemplates.map((item) => {
    const row: DynamicObject = item;
    const formattedData = transposedData[item.id];

    formattedData?.forEach((data, index) => {
      const summaryDate = summaryData[index].date.split("-");
      const month = summaryDate[1];

      const date = getFormattedMonthAndDate(getEndOfMonth(year, Number(month)));

      // Todo 23.02.24 회의 결과 하드 코딩하기로 결정. (추후 퍼센티지 처리 방식 수정 예정임.)
      // 매물보유율 반올림시 숫자가 너무 작게 나와 예외적으로 서버에서 *100해서 나옴
      if (item.id.includes("offerRate")) {
        row[date] = `${data}%`;
        return;
      }

      if (item.id.includes("Rate")) {
        console.log('item.id.includes("Rate") :', item.id.includes("Rate"));
        row[date] = `${transformNumberToRatio(data)}%`;
        return;
      }

      if (item.id.includes("Commission")) {
        row[date] = Math.round(data * 1.1);
        return;
      }

      row[date] = data;
    });

    formattedColumns.forEach((column) => {
      const columnName = column.field;
      if (!(columnName in row)) {
        row[columnName] = "-";
      }
    });

    return row;
  });

  return result;
}
