import { SelectChangeEvent, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentBox } from "../../components/ContentBox";
import WdbPaperContainer from "../../components/WdbPaperContainer";
import MonthlyTabContents from "../../components/branch/MonthlyTabContents";
import TabContents from "../../components/branch/TabContents";
import ManagerDataTabContents from "../../containers/manager/components/ManagerDataTabContents";
import SelectGroupContainer from "../../containers/manager/components/ManagerSelectGroupContainer";
import {
  getFormattedManagerOfferPropertyChartData,
  getFormattedManagerVisitPropertyChartData,
  getFormattedManagerbuyingPropertyChartData,
} from "../../containers/manager/utils/chartResourceFormatter";
import useDatePickerController from "../../hook/useDatePickerController";
import useManager from "../../hook/useManager";
import useRangeController from "../../hook/useRangeController";
import useRangePickerController from "../../hook/useRangePickerController";
import {
  MamagerMonthlyRequest,
  ManagerDataRequest,
} from "../../interface/Manager";
import { getBranchList } from "../../store/api/branchDataApi";
import {
  getManagerDataApi,
  getManagerMonthlyData,
  getManagerOfBranchList,
} from "../../store/api/managerDataApi";

export type ManagerDataTabType = "MONTH_TAB" | "DATE_TAB" | "PERIOD_TAB";
const tabs: { label: string; value: ManagerDataTabType }[] = [
  {
    label: "월별지표",
    value: "MONTH_TAB",
  },
  {
    label: "날짜조회",
    value: "DATE_TAB",
  },
  {
    label: "기간조회",
    value: "PERIOD_TAB",
  },
];
const currentYear = new Date().getFullYear();
export default function ManagerDataPage() {
  const { selectedYear, onChangeYear } = useRangeController(currentYear);
  const { selectedDate, onChangeDatePicker } = useDatePickerController();

  const {
    selectedRangePickerFilter,
    onChangeRangePickerFilter,
    onChangeRangePicker,
    rangePickerValue,
    startDate,
    endDate,
  } = useRangePickerController(dayjs("2023-06-06", "YYYY-MM-DD"));

  const {
    selectedValue,
    selectedManagerList,
    selectedBranchNo,
    selectedManagerNo,
    selectedManagerAccountInfoNo,
    selectableBranchList,

    apiManagerDataList,
    setApiManagerDataList,
    setManagerList,
    setManagerAccountInfoNo,
    setManagerNo,

    handleBranchList,
    handleManagerOptionList,
    toggleRadio,

    onChangeBranch,
    onChangeManagerOfBranch,

    chartTradeTypeList,
    getManagerChartData,
    resultManagerChartData,
    setmanagerOfferChartData,
    managerOfferChartData,
    buyingPropertyChartData,
    setBuyingPropertyChartData,
    visitPropertyChartData,
    setVisitPropertyChartData,
  } = useManager();

  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState<ManagerDataTabType>("MONTH_TAB");

  const onChangeAccount = async (event: SelectChangeEvent<unknown>) => {
    const accountInfoNo = event.target.value;
    const params: MamagerMonthlyRequest = {
      accountInfoNo: accountInfoNo as number | string,
      companyGroupNo: selectedBranchNo as number | string,
      year: selectedYear,
    };

    const selectedAccountNo = accountInfoNo as number;
    const resultManagerMonthlyData = await getManagerMonthlyData(params);
    if (resultManagerMonthlyData.error) return;

    setManagerAccountInfoNo(resultManagerMonthlyData.data);
    setManagerNo(selectedAccountNo);
  };

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: ManagerDataTabType
  ) => {
    const resultManagerOfBranchList = await getManagerOfBranchList(
      selectedBranchNo
    );
    if (resultManagerOfBranchList.error) return;
    handleManagerOptionList(resultManagerOfBranchList);
    setManagerNo(0);
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params: { searchDate: string | null; companyGroupNo?: number } = {
          searchDate: selectedDate,
        };

        if (selectedBranchNo !== 0) {
          params.companyGroupNo = selectedBranchNo;
        }
        const resultBranchList = await getBranchList();
        if (resultBranchList.error) return;
        handleBranchList(resultBranchList);

        const resultManagerData = await getManagerDataApi(params);
        if (resultManagerData.error) return;
        setLoading(false);
        setApiManagerDataList(resultManagerData.data);
      } catch (error) {}
    };

    fetchData();

    const fetchChartData = async () => {
      try {
        const ManagerOfferProperty = getFormattedManagerOfferPropertyChartData(
          resultManagerChartData
        );
        const ManagerBuyingProperty =
          getFormattedManagerbuyingPropertyChartData(resultManagerChartData);
        const ManagerVisitProperty = getFormattedManagerVisitPropertyChartData(
          resultManagerChartData
        );
        setmanagerOfferChartData(ManagerOfferProperty);
        setBuyingPropertyChartData(ManagerBuyingProperty);
        setVisitPropertyChartData(ManagerVisitProperty);
      } catch (eror) {}
    };

    fetchChartData();
  }, [
    selectedBranchNo,
    setManagerList,
    selectedDate,
    selectedManagerList,
    selectedManagerNo,
    setManagerAccountInfoNo,
    resultManagerChartData,
  ]);

  useEffect(() => {
    getManagerChartData({
      startDate: startDate,
      endDate: endDate,
      companyGroupNo: selectedBranchNo,
      accountInfoNo: selectedManagerNo,
    });
  }, [selectedManagerNo, setManagerAccountInfoNo, rangePickerValue]);

  return (
    <ManagerDataContainer>
      <WdbPaperContainer>
        <SelectGroupContainer
          tabValue={tabValue}
          currentYear={currentYear}
          selectedYear={selectedYear}
          selectedValue={selectedValue}
          selectedBranchNo={selectedBranchNo}
          selectedManagerNo={selectedManagerNo}
          selectableBranchList={selectableBranchList}
          selectedManagerList={selectedManagerList}
          selectedRangePickerFilter={selectedRangePickerFilter}
          rangePickerValue={rangePickerValue}
          toggleRadio={toggleRadio}
          onChangeManagerOfBranch={onChangeManagerOfBranch}
          onChangeYear={onChangeYear}
          onChangeAccount={onChangeAccount}
          onChangeBranch={onChangeBranch}
          onChangeRangePickerFilter={onChangeRangePickerFilter}
          onChangeRangePicker={onChangeRangePicker}
          selectedDate={selectedDate}
          onChangeDatePicker={onChangeDatePicker}
        />
      </WdbPaperContainer>
      <ContentBox>
        <TapWrap value={tabValue} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </TapWrap>
        {tabValue === "MONTH_TAB" && (
          <MonthlyTabContents
            year={currentYear}
            gridData={selectedManagerAccountInfoNo as ManagerDataRequest}
            loading={loading}
            selectedManagerNo={selectedManagerNo}
          />
        )}
        {tabValue === "DATE_TAB" && (
          <TabContents
            tabValue={tabValue}
            gridData={apiManagerDataList}
            loading={loading}
          />
        )}
        {tabValue === "PERIOD_TAB" && (
          <ManagerDataTabContents
            chartTradeTypeList={chartTradeTypeList}
            selectedManagerNo={selectedManagerNo}
            managerOfferChartData={managerOfferChartData}
            buyingPropertyChartData={buyingPropertyChartData}
            visitPropertyChartData={visitPropertyChartData}
          />
        )}
      </ContentBox>
    </ManagerDataContainer>
  );
}

const ManagerDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TapWrap = styled(Tabs)({
  marginBottom: "15px",
});
