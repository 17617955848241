import { Breadcrumbs, BreadcrumbsProps } from "@mui/material";
import styled from "styled-components";
import { font } from "../styles/font";

function WdbBreadCrumb(props: BreadcrumbsProps) {
  return <BreadCrumb aria-label="breadcrumb">{props.children}</BreadCrumb>;
}

export default WdbBreadCrumb;

const BreadCrumb = styled(Breadcrumbs)`
  * {
    font-size: ${font.px14};
  }
`;
