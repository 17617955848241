import {
  TradeSalesSizeRequest,
  TradeSalesSizeResponse,
} from "../../interface/TradeSales";
import { get, WebResponse } from "../../module/HTTPWebClient";

export async function getTradeSalesSizeApi({
  year,
  month,
  searchType,
  areaCode,
  bothRate,
  monthlyMarketShare,
  rentalMarketShare,
  tradeMarketShare,
  undefineToNewRate,
  wdbDiscountRate,
}: TradeSalesSizeRequest): Promise<WebResponse<TradeSalesSizeResponse>> {
  try {
    const url = `/trade-sales/stat`;
    const { data } = await get<{
      data: TradeSalesSizeResponse;
      message: string;
      resultCode: string;
    }>(url, {
      params: {
        year,
        month,
        searchType,
        areaCode,
        bothRate,
        monthlyMarketShare,
        rentalMarketShare,
        tradeMarketShare,
        undefineToNewRate,
        wdbDiscountRate,
      },
    });

    return {
      data: data.data,
      error: false,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
}
