import { Button } from "@mui/material";
import styled from "styled-components";
import { color } from "../../../styles/color";

interface TradeTypeOption {
  value: string;
  label: string;
  color: string;
}

const tradeTypeOption: TradeTypeOption[] = [
  { value: "total", label: "전체", color: "black" },
  { value: "trade", label: "매매", color: color.trade },
  { value: "rental", label: "전세", color: color.rental },
  { value: "monthlyRental", label: "월세", color: color.monthly },
];

interface GuestChartTradeTypeButtonGroupProps {
  chartInputs: Record<string, boolean>;
  onClickTradeTypeButton: (value: string) => void;
}

function GuestChartTradeTypeButtonGroup({
  chartInputs,
  onClickTradeTypeButton,
}: GuestChartTradeTypeButtonGroupProps) {
  return (
    <Wrapper>
      {tradeTypeOption.map(({ value, label, color }: TradeTypeOption) => (
        <Button
          key={value}
          value={value}
          sx={{
            background: chartInputs[value] ? color : "none",
            color: !chartInputs[value] ? color : "none",
            borderColor: !chartInputs[value] ? color : "none",
            "&:hover": {
              background: chartInputs[value] ? color : "none",
              borderColor: !chartInputs[value] ? color : "none",
            },
          }}
          variant={chartInputs[value] ? "contained" : "outlined"}
          size="small"
          onClick={() => {
            onClickTradeTypeButton(value);
          }}
        >
          {label}
        </Button>
      ))}
    </Wrapper>
  );
}

export default GuestChartTradeTypeButtonGroup;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;
