import InfoIcon from "@mui/icons-material/Info";
import { Alert, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import {
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  LegendElement,
  LegendItem,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { useEffect, useRef } from "react";
import { Chart } from "react-chartjs-2";
import styled from "styled-components";
import AreaBreadCrumb, {
  AreaBreadCrumbProps,
} from "../../../components/AreaBreadCrumb";
import TradeTypeChips from "../../../components/TradeTypeChips";
import WdbBreadCrumb from "../../../components/WdbBreadCrumb";
import QuickSearchToolbar from "../../../components/atoms/grid/QuickSearchToolbar";
import { FlexWrapper } from "../../../components/atoms/layout/FlexWrapper";
import { SelectOption } from "../../../interface/CommonInterface";
import { SalesHoldingColumn } from "../../../interface/SalesHolding";
import { ChartTradeType } from "../../../interface/CommonInterface";
import { SalesHoldingTabType } from "../../../pages/SalesHoldingPage";
import {
  salesHoldingDateColumn,
  salesHoldingDateColumnGroupingModel,
} from "../../../static/gridColumn/salesHoldingColumn";
import { color } from "../../../styles/color";
// import { ChartData } from "../utils/chartResourceFormatter";

import AreaChips from "./AreaChips";
import { ChartData } from "../../../interface/chartFormatter";
ChartJS.register(
  LineController,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export interface SalesHoldingTabContentsProps {
  tabValue: SalesHoldingTabType;
  gridData: SalesHoldingColumn[];
  naverScrapLastDate?: string;
  selectedDate: string | null;
  setSelectedSido: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSigungu: React.Dispatch<React.SetStateAction<string>>;
  setSelectedBjdong: React.Dispatch<React.SetStateAction<string>>;
  wdbChartData: ChartData;
  naverChartData: ChartData;
  selectableSigunguList: SelectOption[];
  selectableBjdongList: SelectOption[];
  loading: boolean;
  chartTradeTypeList: ChartTradeType;
  setChartTradeTypeList: React.Dispatch<React.SetStateAction<ChartTradeType>>;
  selectedAreaChips: SelectOption[];
  setSelectedAreaChips: React.Dispatch<React.SetStateAction<SelectOption[]>>;
}

export type SalesHodlingContentsType = SalesHoldingTabContentsProps &
  AreaBreadCrumbProps;

const SalesHoldingTabContents = ({
  tabValue,
  sidoSelect,
  sigunguSelect,
  bjdongSelect,
  onClickSigunguXButton,
  onClickBjdongXButton,
  gridData,
  naverScrapLastDate,
  selectedDate,
  setSelectedSido,
  setSelectedSigungu,
  setSelectedBjdong,
  wdbChartData,
  naverChartData,
  selectableSigunguList,
  selectableBjdongList,
  loading,
  chartTradeTypeList,
  setChartTradeTypeList,
  selectedAreaChips,
  setSelectedAreaChips,
}: SalesHodlingContentsType) => {
  const chartWdbRef = useRef<ChartJS<"line", number[], string>>(null);
  const chartNaverRef = useRef<ChartJS<"line", number[], string>>(null);
  const { total, trade, rental, monthlyRental } = chartTradeTypeList;

  useEffect(() => {
    const legendItemsWdb = chartWdbRef.current?.legend?.legendItems;
    const chartWdb = chartWdbRef.current?.legend?.chart;
    const legendItemsNaver = chartNaverRef.current?.legend?.legendItems;
    const chartNaver = chartNaverRef.current?.legend?.chart;

    if (legendItemsWdb && legendItemsNaver) {
      const totalWdbLegendItem1 = legendItemsWdb[0];
      const totalWdbLegendItem2 = legendItemsWdb[4];
      const totalNaverLegendItem1 = legendItemsNaver[0];
      const totalNaverLegendItem2 = legendItemsNaver[4];

      const tradeWdbLegendItem1 = legendItemsWdb[1];
      const tradeWdbLegendItem2 = legendItemsWdb[5];
      const tradeNaverLegendItem1 = legendItemsNaver[1];
      const tradeNaverLegendItem2 = legendItemsNaver[5];

      const rentalWdbLegendItem1 = legendItemsWdb[2];
      const rentalWdbLegendItem2 = legendItemsWdb[6];
      const rentalNaverLegendItem1 = legendItemsNaver[2];
      const rentalNaverLegendItem2 = legendItemsNaver[6];

      const monthlyRentalWdbLegendItem1 = legendItemsWdb[3];
      const monthlyRentalWdbLegendItem2 = legendItemsWdb[7];
      const monthlyRentalNaverLegendItem1 = legendItemsNaver[3];
      const monthlyRentalNaverLegendItem2 = legendItemsNaver[7];

      const handleChartWdbShow = (item1: LegendItem, item2?: LegendItem) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartWdb?.show(index1);
          item1.hidden = false;
          chartWdb?.show(index2);
          item2.hidden = false;
        } else if (index1 !== undefined) {
          chartWdb?.show(index1);
          item1.hidden = false;
        }
      };

      const handleChartWdbHidden = (item1: LegendItem, item2?: LegendItem) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartWdb?.hide(index1);
          item1.hidden = true;
          chartWdb?.hide(index2);
          item2.hidden = true;
        } else if (index1 !== undefined) {
          chartWdb?.hide(index1);
          item1.hidden = true;
        }
      };

      const handleChartNaverShow = (item1: LegendItem, item2?: LegendItem) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartNaver?.show(index1);
          item1.hidden = false;
          chartNaver?.show(index2);
          item2.hidden = false;
        } else if (index1 !== undefined) {
          chartNaver?.show(index1);
          item1.hidden = false;
        }
      };

      const handleChartNaverHidden = (
        item1: LegendItem,
        item2?: LegendItem
      ) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartNaver?.hide(index1);
          item1.hidden = true;
          chartNaver?.hide(index2);
          item2.hidden = true;
        } else if (index1 !== undefined) {
          chartNaver?.hide(index1);
          item1.hidden = true;
        }
      };

      if (legendItemsWdb?.length > 4) {
        if (total) {
          handleChartWdbShow(totalWdbLegendItem1, totalWdbLegendItem2);
          handleChartNaverShow(totalNaverLegendItem1, totalNaverLegendItem2);
        } else {
          handleChartWdbHidden(totalWdbLegendItem1, totalWdbLegendItem2);
          handleChartNaverHidden(totalNaverLegendItem1, totalNaverLegendItem2);
        }

        if (trade) {
          handleChartWdbShow(tradeWdbLegendItem1, tradeWdbLegendItem2);
          handleChartNaverShow(tradeNaverLegendItem1, tradeNaverLegendItem2);
        } else {
          handleChartWdbHidden(tradeWdbLegendItem1, tradeWdbLegendItem2);
          handleChartNaverHidden(tradeNaverLegendItem1, tradeNaverLegendItem2);
        }

        if (rental) {
          handleChartWdbShow(rentalWdbLegendItem1, rentalWdbLegendItem2);
          handleChartNaverShow(rentalNaverLegendItem1, rentalNaverLegendItem2);
        } else {
          handleChartWdbHidden(rentalWdbLegendItem1, rentalWdbLegendItem2);
          handleChartNaverHidden(
            rentalNaverLegendItem1,
            rentalNaverLegendItem2
          );
        }

        if (monthlyRental) {
          handleChartWdbShow(
            monthlyRentalWdbLegendItem1,
            monthlyRentalWdbLegendItem2
          );
          handleChartNaverShow(
            monthlyRentalNaverLegendItem1,
            monthlyRentalNaverLegendItem2
          );
        } else {
          handleChartWdbHidden(
            monthlyRentalWdbLegendItem1,
            monthlyRentalWdbLegendItem2
          );
          handleChartNaverHidden(
            monthlyRentalNaverLegendItem1,
            monthlyRentalNaverLegendItem2
          );
        }
      }

      if (legendItemsWdb?.length <= 4) {
        if (total) {
          handleChartWdbShow(totalWdbLegendItem1);
          handleChartNaverShow(totalNaverLegendItem1);
        } else {
          handleChartWdbHidden(totalWdbLegendItem1);
          handleChartNaverHidden(totalNaverLegendItem1);
        }

        if (trade) {
          handleChartWdbShow(tradeWdbLegendItem1);
          handleChartNaverShow(tradeNaverLegendItem1);
        } else {
          handleChartWdbHidden(tradeWdbLegendItem1);
          handleChartNaverHidden(tradeNaverLegendItem1);
        }

        if (rental) {
          handleChartWdbShow(rentalWdbLegendItem1);
          handleChartNaverShow(rentalNaverLegendItem1);
        } else {
          handleChartWdbHidden(rentalWdbLegendItem1);
          handleChartNaverHidden(rentalNaverLegendItem1);
        }

        if (monthlyRental) {
          handleChartWdbShow(monthlyRentalWdbLegendItem1);
          handleChartNaverShow(monthlyRentalNaverLegendItem1);
        } else {
          handleChartWdbHidden(monthlyRentalWdbLegendItem1);
          handleChartNaverHidden(monthlyRentalNaverLegendItem1);
        }
      }
    }
  }, [wdbChartData, naverChartData]);

  useEffect(() => {
    const legendItemsWdb = chartWdbRef.current?.legend?.legendItems;
    const chartWdb = chartWdbRef.current?.legend?.chart;
    const legendItemsNaver = chartNaverRef.current?.legend?.legendItems;
    const chartNaver = chartNaverRef.current?.legend?.chart;

    if (legendItemsWdb && legendItemsNaver) {
      const totalWdbLegendItem1 = legendItemsWdb[0];
      const totalWdbLegendItem2 = legendItemsWdb[4];
      const totalNaverLegendItem1 = legendItemsNaver[0];
      const totalNaverLegendItem2 = legendItemsNaver[4];

      const tradeWdbLegendItem1 = legendItemsWdb[1];
      const tradeWdbLegendItem2 = legendItemsWdb[5];
      const tradeNaverLegendItem1 = legendItemsNaver[1];
      const tradeNaverLegendItem2 = legendItemsNaver[5];

      const rentalWdbLegendItem1 = legendItemsWdb[2];
      const rentalWdbLegendItem2 = legendItemsWdb[6];
      const rentalNaverLegendItem1 = legendItemsNaver[2];
      const rentalNaverLegendItem2 = legendItemsNaver[6];

      const monthlyRentalWdbLegendItem1 = legendItemsWdb[3];
      const monthlyRentalWdbLegendItem2 = legendItemsWdb[7];
      const monthlyRentalNaverLegendItem1 = legendItemsNaver[3];
      const monthlyRentalNaverLegendItem2 = legendItemsNaver[7];

      const handleChartWdbShow = (item1: LegendItem, item2?: LegendItem) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartWdb?.show(index1);
          item1.hidden = false;
          chartWdb?.show(index2);
          item2.hidden = false;
        } else if (index1 !== undefined) {
          chartWdb?.show(index1);
          item1.hidden = false;
        }
      };

      const handleChartWdbHidden = (item1: LegendItem, item2?: LegendItem) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartWdb?.hide(index1);
          item1.hidden = true;
          chartWdb?.hide(index2);
          item2.hidden = true;
        } else if (index1 !== undefined) {
          chartWdb?.hide(index1);
          item1.hidden = true;
        }
      };

      const handleChartNaverShow = (item1: LegendItem, item2?: LegendItem) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartNaver?.show(index1);
          item1.hidden = false;
          chartNaver?.show(index2);
          item2.hidden = false;
        } else if (index1 !== undefined) {
          chartNaver?.show(index1);
          item1.hidden = false;
        }
      };

      const handleChartNaverHidden = (
        item1: LegendItem,
        item2?: LegendItem
      ) => {
        const index1 = item1.datasetIndex;
        const index2 = item2 ? item2.datasetIndex : undefined;

        if (index1 !== undefined && item2 && index2 !== undefined) {
          chartNaver?.hide(index1);
          item1.hidden = true;
          chartNaver?.hide(index2);
          item2.hidden = true;
        } else if (index1 !== undefined) {
          chartNaver?.hide(index1);
          item1.hidden = true;
        }
      };

      if (legendItemsWdb?.length > 4) {
        if (total) {
          handleChartWdbShow(totalWdbLegendItem1, totalWdbLegendItem2);
          handleChartNaverShow(totalNaverLegendItem1, totalNaverLegendItem2);
        } else {
          handleChartWdbHidden(totalWdbLegendItem1, totalWdbLegendItem2);
          handleChartNaverHidden(totalNaverLegendItem1, totalNaverLegendItem2);
        }

        if (trade) {
          handleChartWdbShow(tradeWdbLegendItem1, tradeWdbLegendItem2);
          handleChartNaverShow(tradeNaverLegendItem1, tradeNaverLegendItem2);
        } else {
          handleChartWdbHidden(tradeWdbLegendItem1, tradeWdbLegendItem2);
          handleChartNaverHidden(tradeNaverLegendItem1, tradeNaverLegendItem2);
        }

        if (rental) {
          handleChartWdbShow(rentalWdbLegendItem1, rentalWdbLegendItem2);
          handleChartNaverShow(rentalNaverLegendItem1, rentalNaverLegendItem2);
        } else {
          handleChartWdbHidden(rentalWdbLegendItem1, rentalWdbLegendItem2);
          handleChartNaverHidden(
            rentalNaverLegendItem1,
            rentalNaverLegendItem2
          );
        }

        if (monthlyRental) {
          handleChartWdbShow(
            monthlyRentalWdbLegendItem1,
            monthlyRentalWdbLegendItem2
          );
          handleChartNaverShow(
            monthlyRentalNaverLegendItem1,
            monthlyRentalNaverLegendItem2
          );
        } else {
          handleChartWdbHidden(
            monthlyRentalWdbLegendItem1,
            monthlyRentalWdbLegendItem2
          );
          handleChartNaverHidden(
            monthlyRentalNaverLegendItem1,
            monthlyRentalNaverLegendItem2
          );
        }
      }

      if (legendItemsWdb?.length <= 4) {
        if (total) {
          handleChartWdbShow(totalWdbLegendItem1);
          handleChartNaverShow(totalNaverLegendItem1);
        } else {
          handleChartWdbHidden(totalWdbLegendItem1);
          handleChartNaverHidden(totalNaverLegendItem1);
        }

        if (trade) {
          handleChartWdbShow(tradeWdbLegendItem1);
          handleChartNaverShow(tradeNaverLegendItem1);
        } else {
          handleChartWdbHidden(tradeWdbLegendItem1);
          handleChartNaverHidden(tradeNaverLegendItem1);
        }

        if (rental) {
          handleChartWdbShow(rentalWdbLegendItem1);
          handleChartNaverShow(rentalNaverLegendItem1);
        } else {
          handleChartWdbHidden(rentalWdbLegendItem1);
          handleChartNaverHidden(rentalNaverLegendItem1);
        }

        if (monthlyRental) {
          handleChartWdbShow(monthlyRentalWdbLegendItem1);
          handleChartNaverShow(monthlyRentalNaverLegendItem1);
        } else {
          handleChartWdbHidden(monthlyRentalWdbLegendItem1);
          handleChartNaverHidden(monthlyRentalNaverLegendItem1);
        }
      }
    }
  }, [chartTradeTypeList]);

  return (
    <Wrapper>
      {tabValue === "DATE_TAB" && (
        <>
          <FlexWrapper justifyContent="space-between">
            <AreaBreadCrumb
              sidoSelect={sidoSelect}
              sigunguSelect={sigunguSelect}
              bjdongSelect={bjdongSelect}
              onClickSigunguXButton={onClickSigunguXButton}
              onClickBjdongXButton={onClickBjdongXButton}
            />
            <WdbBreadCrumb aria-label="breadcrumb">
              <span>네이버 스크랩일자 : {naverScrapLastDate}</span>
              <span>검색일자 : {selectedDate}</span>
            </WdbBreadCrumb>
          </FlexWrapper>
          <GridWrapper>
            <DataGridPro
              experimentalFeatures={{ columnGrouping: true }}
              rows={gridData}
              columns={salesHoldingDateColumn}
              disableSelectionOnClick
              columnGroupingModel={salesHoldingDateColumnGroupingModel}
              columnVisibilityModel={{
                areaCode: false,
              }}
              rowHeight={40}
              hideFooter={true}
              loading={loading}
              onRowClick={(params: GridRowParams) => {
                if (sidoSelect?.value === "전체") {
                  setSelectedSido(params.row.areaCode);
                } else if (
                  sidoSelect?.value !== "전체" &&
                  sigunguSelect?.value === "전체"
                ) {
                  setSelectedSigungu(params.row.areaCode);
                } else if (
                  sigunguSelect?.value !== "전체" &&
                  bjdongSelect?.value === "전체"
                ) {
                  setSelectedBjdong(params.row.areaCode);
                } else {
                  return;
                }
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />
          </GridWrapper>
        </>
      )}

      {tabValue === "PERIOD_TAB" && (
        <>
          {sidoSelect?.value !== "전체" ? (
            <>
              <Divider />
              <TradeTypeChips
                chartTradeTypeList={chartTradeTypeList}
                setChartTradeTypeList={setChartTradeTypeList}
                selectedAreaChips={selectedAreaChips}
                setSelectedAreaChips={setSelectedAreaChips}
                sidoSelect={sidoSelect}
              />
              <AreaChips
                selectableSigunguList={selectableSigunguList}
                selectableBjdongList={selectableBjdongList}
                sidoSelect={sidoSelect}
                sigunguSelect={sigunguSelect}
                bjdongSelect={bjdongSelect}
                setArea={setSelectedSigungu}
                resetSigungu={onClickSigunguXButton}
                resetBjdong={onClickBjdongXButton}
                selectedAreaChips={selectedAreaChips}
                setSelectedAreaChips={setSelectedAreaChips}
              />

              <Divider />
              <Typography
                variant="h5"
                align="center"
                color={color.woodaeRed}
                fontWeight={600}
              >
                우대빵 매물 보유율
              </Typography>
              <ChartWrapper>
                <Chart
                  type="line"
                  data={wdbChartData}
                  ref={chartWdbRef}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        ticks: {
                          callback(tickValue, index, ticks) {
                            if (typeof tickValue === "string") {
                              return `${tickValue}%`;
                            } else {
                              return `${tickValue.toFixed(1)}%`;
                            }
                          },
                        },
                        title: {
                          display: true,
                          text: "매물 보유율",
                          padding: {
                            top: 0,
                            bottom: 10,
                            y: 0,
                          },
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        onClick: (
                          e: ChartEvent,
                          legendItem: LegendItem,
                          legend: LegendElement<"line">
                        ) => {
                          const index = legendItem.datasetIndex;
                          const ci = legend.chart;

                          if (index !== undefined) {
                            if (ci.isDatasetVisible(index)) {
                              ci.hide(index);
                              legendItem.hidden = true;
                            } else {
                              ci.show(index);
                              legendItem.hidden = false;
                            }
                          }
                        },
                      },
                    },
                  }}
                />
              </ChartWrapper>
              <Divider sx={{ my: 3 }} />
              <Stack
                gap="8px"
                flexDirection={"row"}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Typography
                  variant="h5"
                  align="center"
                  color={color.naverGreen}
                  fontWeight={600}
                >
                  네이버 매물개수(동일매물묶음 적용)
                </Typography>
                <FlexWrapper gap="2px">
                  <InfoIcon fontSize="inherit" />
                  <Typography
                    variant="caption"
                    display="block"
                    fontSize={"0.5rem"}
                  >
                    월요일 새벽에 한 번 집계합니다.
                  </Typography>
                </FlexWrapper>
              </Stack>
              <ChartWrapper>
                <Chart
                  type="line"
                  data={naverChartData}
                  ref={chartNaverRef}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        title: {
                          display: true,
                          text: "네이버 매물 개수",
                          padding: {
                            top: 0,
                            bottom: 10,
                            y: 0,
                          },
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        onClick: (
                          e: ChartEvent,
                          legendItem: LegendItem,
                          legend: LegendElement<"line">
                        ) => {
                          const index = legendItem.datasetIndex;
                          const ci = legend.chart;

                          if (index !== undefined) {
                            if (ci.isDatasetVisible(index)) {
                              ci.hide(index);
                              legendItem.hidden = true;
                            } else {
                              ci.show(index);
                              legendItem.hidden = false;
                            }
                          }
                        },
                      },
                    },
                  }}
                />
              </ChartWrapper>
            </>
          ) : (
            <Alert severity="error">
              시도(시군구 : 전체) 단위의 기간 조회만 가능합니다.
            </Alert>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .label-total {
    background-color: black;
    color: white;
    svg {
      color: white;
    }
  }

  .label-selected {
    background-color: #999999ff;
    color: white;
    svg {
      color: white;
    }
  }
`;

const GridWrapper = styled.div`
  width: 100%;
  height: 80vh;

  .header-naver-count {
    border-left: 4px double #f0f0f0;
    border-right: 4px double #f0f0f0;
    border-top: 4px double #f0f0f0;
  }

  .header-wdb-count {
    border-top: 4px double #f0f0f0;
    border-left: 4px double #f0f0f0;
  }

  // ✅
  .header-same-offer-adrate,
  .header-area-name {
    border-top: 4px double #f0f0f0;
  }

  div [data-field="areaName"] {
    cursor: pointer;
  }

  div [data-field="naverTradeCount"],
  div [data-field="woodaeTradeCount"] {
    border-left: 4px double #f0f0f0;
  }

  // ✅
  .header-naver-group-count {
    border-right: 4px double #f0f0f0;
    border-top: 4px double #f0f0f0;
  }

  div [data-field="naverTotalCount"],
  div [data-field="naverTotalGroupCount"] {
    border-right: 4px double #f0f0f0;
    background-color: ${color.grid.background.tradeCell};
  }

  div [data-field="woodaeTotalCount"] {
    background-color: ${color.grid.background.tradeCell};
  }

  div [data-field="woodaeTradeRate"],
  div [data-field="woodaeRentalRate"],
  div [data-field="woodaeMonthlyRate"] {
    border-right: 1px solid #f0f0f0;
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 50vh;
`;

export default SalesHoldingTabContents;
