import { Pagination } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import styled from "styled-components";
import { WdbPagination } from "../../../hook/usePagination";
import {
  TradeTypeValue,
  TransactionResponseList,
  TransactionResponseListTradeColumn,
} from "../../../interface/Transaction";
import {
  transactionMonthlyColumns,
  transactionRentalColumns,
  transactionTradeColumns,
} from "../../../static/gridColumn/transactionColumn";

export interface TransactionGridProps {
  tradeGridData:
    | TransactionResponseList[]
    | TransactionResponseListTradeColumn[];
  tradeType: TradeTypeValue;
  loading: boolean;
}

function TransactionGrid({
  tradeGridData,
  tradeType,
  loading,
}: TransactionGridProps) {
  const getTransactionColumn = (tradeType: TradeTypeValue) => {
    switch (tradeType) {
      default:
        return transactionTradeColumns;
      case "TRADE":
        return transactionTradeColumns;
      case "RENTAL":
        return transactionRentalColumns;
      case "MONTHLY_RENTAL":
        return transactionMonthlyColumns;
    }
  };

  return (
    <GridWrapper>
      <DataGridPro
        sx={{ mt: 2 }}
        columns={getTransactionColumn(tradeType)}
        rows={tradeGridData}
        rowHeight={40}
        loading={loading}
      />
    </GridWrapper>
  );
}

const GridWrapper = styled.div`
  width: 100%;
  height: 80vh;
  .MuiDataGrid-main {
    color: ${(props) => props.theme.colorLight};
    background: ${(props) => props.theme.bgColorLight};
  }
`;

export default TransactionGrid;
