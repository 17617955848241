import { PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";

interface GroupHeaderProps {
  backgroundColor?: string;
}
function GroupHeaderChip({
  backgroundColor,
  children,
}: PropsWithChildren<GroupHeaderProps>) {
  return (
    <GroupHeaderWrapper backgroundColor={backgroundColor}>
      {children}
    </GroupHeaderWrapper>
  );
}

export default GroupHeaderChip;

const GroupHeaderWrapper = styled.span<GroupHeaderProps>`
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "inherit"};
`;
