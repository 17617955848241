import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumbs } from "@mui/material";
import styled from "styled-components";
import { SelectOption } from "../interface/CommonInterface";
import { font } from "../styles/font";

export interface AreaBreadCrumbProps {
  sidoSelect?: SelectOption;
  sigunguSelect?: SelectOption;
  bjdongSelect?: SelectOption;
  onClickSidoXButton?: () => void;
  onClickSigunguXButton?: () => void;
  onClickBjdongXButton?: () => void;
}

function AreaBreadCrumb(props: AreaBreadCrumbProps) {
  const renderSido = () => {
    if (!props.sidoSelect) return null;

    return (
      <span>
        {props.sidoSelect.label}{" "}
        {props.sigunguSelect?.value === "전체" && props.onClickSidoXButton && (
          <FontAwesomeIcon
            icon={faXmarkCircle}
            onClick={props.onClickSidoXButton}
          />
        )}
      </span>
    );
  };

  const renderSigungu = () => {
    if (!props.sigunguSelect || props.sigunguSelect?.value === "전체") {
      return null;
    }

    return (
      <span>
        {props.sigunguSelect.label}{" "}
        {props.onClickSigunguXButton &&
          (!props.bjdongSelect || props.bjdongSelect?.value === "전체") && (
            <FontAwesomeIcon
              icon={faXmarkCircle}
              onClick={props.onClickSigunguXButton}
            />
          )}
      </span>
    );
  };

  const renderBjdong = () => {
    if (!props.bjdongSelect || props.bjdongSelect?.value === "전체") {
      return null;
    }

    return (
      <span>
        {props.bjdongSelect.label}{" "}
        {props.onClickBjdongXButton && (
          <FontAwesomeIcon
            icon={faXmarkCircle}
            onClick={props.onClickBjdongXButton}
          />
        )}
      </span>
    );
  };
  return (
    <>
      <Wrapper>
        <BreadCrumb separator=">" aria-label="breadcrumb">
          {renderSido()}
          {renderSigungu()}
          {renderBjdong()}
        </BreadCrumb>
      </Wrapper>
    </>
  );
}

export default AreaBreadCrumb;

const Wrapper = styled.span`
  padding: 0 16px svg {
    font-size: ${font.px12};
  }
  svg:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  span {
    font-size: ${font.px14};
  }
  ol > li {
    color: ${(props) => props.theme.colorSecond};
  }
  ol > li:last-child {
    color: ${(props) => props.theme.color};
  }
`;

const BreadCrumb = styled(Breadcrumbs)`
  display: inline-block;
`;
