import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridColumnGroupingModel,
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import styled from "styled-components";
import CommisionRentalMonthlyGuideGrid from "../../../../components/CommisionRentalMonthlyGuideGrid";
import CommisionTradeGuideGrid from "../../../../components/CommisionTradeGuideGrid";
import { GridCaption } from "../../../../components/GridCaption";
import { FlexWrapper } from "../../../../components/atoms/layout/FlexWrapper";
import GroupHeaderChip from "../../../../components/table/GroupHeaderChip";
import { TotalBrokerageCommissionSize } from "../../../../interface/TradeSales";
import { color } from "../../../../styles/color";
import {
  COMMON_COLUMNS_OPTIONS,
  getCellValueTotal,
} from "../../utils/tableResourceFormatter";
import CloseIcon from "@mui/icons-material/Close";

function TotalBrokerageCommissionSizeTable({
  gridData,
  loading,
}: {
  gridData: TotalBrokerageCommissionSize[];
  loading: boolean;
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const onOpen = () => setModalOpen(true);
  const onClose = () => setModalOpen(false);

  return (
    <>
      <Wrapper>
        <FlexWrapper justifyContent="space-between" alignItem="flex-end">
          <Button variant="outlined" onClick={onOpen}>
            중개보수 요율표
          </Button>
          <GridCaption textAlign="right">(단위 : 만원)</GridCaption>
        </FlexWrapper>
        <div style={{ height: "80vh", width: "100%" }}>
          <DataGridPro
            experimentalFeatures={{ columnGrouping: true }}
            columns={formattedColumns}
            rows={[...gridData, getCellValueTotal(gridData)]}
            rowHeight={40}
            disableSelectionOnClick
            columnGroupingModel={columnGroupingModel}
            loading={loading}
          />
        </div>
      </Wrapper>
      <Dialog open={modalOpen} onClose={onClose} maxWidth={false}>
        <DialogTitle>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <span>중개보수 요율표</span>{" "}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack flexDirection={"row"} gap={2}>
            <div>
              <GridCaption>[서울시, 주택, 매매/교환]</GridCaption>
              <CommisionTradeGuideGrid />
            </div>
            <div>
              <GridCaption>[서울시, 주택, 전세 임대차]</GridCaption>
              <CommisionRentalMonthlyGuideGrid />
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TotalBrokerageCommissionSizeTable;

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "매매",
    headerAlign: "center",
    headerClassName: "trade-header",
    renderHeaderGroup: () => (
      <GroupHeaderChip backgroundColor={color.trade}>매매</GroupHeaderChip>
    ),
    children: [
      {
        field: "tradeAveragePerTradePrice",
      },
      {
        field: "tradeMaxCommissionRate",
      },
      {
        field: "tradeTotalPrice",
      },
      {
        field: "tradeOneSideCommission",
      },
    ],
  },
  {
    groupId: "전세",
    headerAlign: "center",
    headerClassName: "rental-header",
    renderHeaderGroup: () => (
      <GroupHeaderChip backgroundColor={color.rental}>전세</GroupHeaderChip>
    ),
    children: [
      {
        field: "rentalAveragePerTradePrice",
      },
      {
        field: "rentalMaxCommissionRate",
      },
      {
        field: "rentalTradeMaxPrice",
      },
      {
        field: "rentalOneSideCommission",
      },
    ],
  },
  {
    groupId: "월세",
    headerAlign: "center",
    headerClassName: "monthly-header",
    renderHeaderGroup: () => (
      <GroupHeaderChip backgroundColor={color.monthly}>월세</GroupHeaderChip>
    ),
    children: [
      {
        field: "monthlyAveragePerTradePrice",
      },
      {
        field: "monthlyMaxCommissionRate",
      },
      {
        field: "monthlyTradeMaxPrice",
      },
      {
        field: "monthlyOneSideCommission",
      },
    ],
  },
  {
    groupId: "중개 수수료 합계",
    headerAlign: "center",
    headerClassName: "total-header",
    renderHeaderGroup: () => <span>중개 수수료 합계</span>,
    children: [
      {
        field: "totalOneSideCommission",
      },
      {
        field: "totalBothCommission",
      },
    ],
  },
];

const columns: GridColDef[] = [
  { field: "areaName", headerName: "구분", minWidth: 150, sortable: false },
  {
    field: "tradeAveragePerTradePrice",
    headerName: "건당 평균 거래 금액",
    renderHeader: () => (
      <p>
        건당 평균
        <br />
        거래 금액
      </p>
    ),

    flex: 0.7,
  },
  {
    field: "tradeMaxCommissionRate",
    headerName: "중개수수료상한요율",
    renderHeader: () => (
      <p>
        중개수수료
        <br />
        상한요율
      </p>
    ),
  },
  {
    field: "tradeTotalPrice",
    headerName: "거래 금액",

    flex: 1,
  },
  {
    field: "tradeOneSideCommission",
    headerName: "중개수수료(일방)",
    renderHeader: () => (
      <p>
        중개수수료
        <br />
        (일방)
      </p>
    ),

    flex: 0.5,
  },

  {
    field: "rentalAveragePerTradePrice",
    headerName: "건당 평균 거래 금액",
    renderHeader: () => (
      <p>
        건당 평균
        <br />
        거래 금액
      </p>
    ),

    flex: 0.7,
  },
  {
    field: "rentalMaxCommissionRate",
    headerName: "중개수수료상한요율",
    renderHeader: () => (
      <p>
        중개수수료
        <br />
        상한요율
      </p>
    ),
  },
  {
    field: "rentalTradeMaxPrice",
    headerName: "최대 거래 금액",

    flex: 1,
  },
  {
    field: "rentalOneSideCommission",
    headerName: "중개수수료 (일방)",
    renderHeader: () => (
      <p>
        중개수수료
        <br />
        (일방)
      </p>
    ),

    flex: 0.5,
  },

  {
    field: "monthlyAveragePerTradePrice",
    headerName: "건당 평균 거래 금액",
    renderHeader: () => (
      <p>
        건당 평균
        <br />
        거래 금액
      </p>
    ),

    flex: 0.7,
  },
  {
    field: "monthlyMaxCommissionRate",
    headerName: "중개수수료상한요율",
    renderHeader: () => (
      <p>
        중개수수료
        <br />
        상한요율
      </p>
    ),
  },
  {
    field: "monthlyTradeMaxPrice",
    headerName: "최대 거래 금액",

    flex: 1,
  },
  {
    field: "monthlyOneSideCommission",
    headerName: "중개수수료 (일방)",
    renderHeader: () => (
      <p>
        중개수수료
        <br />
        (일방)
      </p>
    ),

    flex: 0.5,
  },
  {
    field: "totalOneSideCommission",
    headerName: "일방",

    flex: 0.7,
  },
  {
    field: "totalBothCommission",
    headerName: "양타",

    flex: 0.7,
  },
];

const formattedColumns: GridColDef[] = columns.map((item) => {
  if (item.field.toUpperCase().includes("RATE")) {
    const result: GridColDef = {
      ...item,
      ...COMMON_COLUMNS_OPTIONS,
      renderCell: ({ value }) => (value ? `${value}%` : "-"),
    };
    return result;
  }

  const result: GridColDef = {
    ...item,
    ...COMMON_COLUMNS_OPTIONS,
  };
  return result;
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & .trade-header {
    border-left: 4px double #f0f0f0;
    border-right: 4px double #f0f0f0;
  }
  & .rental-header,
  .monthly-header {
    border-right: 4px double #f0f0f0;
  }
  & .total-header {
    & span {
      font-size: 0.9rem;
      color: black;
      font-weight: 600;
    }
  }
  & div [data-field="tradeTotalCount"],
  div [data-field="tradeAveragePerTradePrice"] {
    border-left: 4px double #f0f0f0;
  }
  & div [data-field="tradeOneSideCommission"],
  div [data-fields="|-rentalOneSideCommission-|"],
  div [data-field="monthlyOneSideCommission"],
  div [data-field="rentalOneSideCommission"],
  div [data-fields="|-monthlyOneSideCommission-|"] {
    border-right: 4px double #f0f0f0;
  }
  & div [data-field="tradeOneSideCommission"] {
    background: ${color.grid.background.tradeCell};
  }
  & div [data-field="rentalOneSideCommission"] {
    background: ${color.grid.background.rentalCell};
  }
  & div [data-field="monthlyOneSideCommission"] {
    background: ${color.grid.background.monthlyCell};
  }
  & div [data-id="total"] {
    border-top: 4px double #f0f0f0;
  }
`;
