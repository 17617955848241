import React from "react";
function WdbSmallLogoIcon() {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7093 0.00666296C18.0166 0.00666296 17.3439 0.00666296 16.6438 0.00666296H13.1829L14.8598 2.92004L15.089 3.31732L13.3947 6.25319L13.2054 5.91338L11.6307 3.19489C11.5783 3.10244 11.5235 3.0075 11.4712 2.91005C10.9081 1.86813 10.4646 0.596335 12.1763 0.00666296C11.3715 -0.00832871 10.6016 0.00666296 9.79437 0.00666296H6.33105L8.0079 2.92004L9.75201 5.93337L11.4612 8.91671L13.2353 12C14.3714 9.93115 15.4079 7.97224 16.5864 5.92587L18.3131 2.90255L16.878 5.43615L19.9999 0.00666296H18.7093Z"
        fill="#C30D23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29961 3.19488L8.5935 8.9167L6.90171 12L0 0.00665283H3.46331L5.26971 3.14241L5.29961 3.19488Z"
        fill="#595757"
      />
    </svg>
  );
}
export default WdbSmallLogoIcon;
