import { CheckBoxOption, SelectOption } from "../interface/CommonInterface";

export const selectableSidoOptionList: SelectOption[] = [
  { value: "전체", label: "전체" },
  { value: "41", label: "경기도" },
  { value: "48", label: "경상남도" },
  { value: "26", label: "부산광역시" },
  { value: "11", label: "서울특별시" },
  { value: "28", label: "인천광역시" },
  { value: "27", label: "대구광역시" },
];

export const tradeTypeOptionList: SelectOption[] = [
  { value: "TRADE", label: "매매" },
  { value: "RENTAL", label: "전세" },
  { value: "MONTHLY_RENTAL", label: "월세" },
];

export const tradeTypeCheckList: CheckBoxOption[] = [
  { name: "total", label: "전체" },
  { name: "trade", label: "매매" },
  { name: "rental", label: "전세" },
  { name: "monthlyRental", label: "월세" },
];

export const selectableDateFilterOptionList: SelectOption[] = [
  { value: "periodSetting", label: "기간 설정" },
  { value: "last30days", label: "최근 30일" },
  { value: "last180days", label: "최근 180일" },
  { value: "last365days", label: "최근 365일" },
  { value: "thisMonth", label: "이번 달" },
  { value: "lastMonth", label: "지난 달" },
];
