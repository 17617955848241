import styled from "styled-components";
import { Card } from "../components/atoms/Card";
import { FlexWrapper } from "../components/atoms/layout/FlexWrapper";

const DashBoardWrapper = styled.div``;

export function DashBoardPage() {
  return (
    <>
      <DashBoardWrapper>
        <FlexWrapper>
          <Card width="20vw" flexDirection="column">
            <div className="card-category">직접 맡기신 아파트 매물수</div>
            <div className="card-title">37,456 개</div>
            <div>start</div>
          </Card>
          <Card width="20vw" flexDirection="column">
            <a href="https://nts.go.kr/upload/ebook/20210325/ecatalog5.html">
              "주택과 세금" 자료 보기 (국세청,행정안전부)
            </a>
          </Card>
          <Card width="20vw" flexDirection="column">
            <div className="card-category">누적 거래액</div>
            <div className="card-title">831,373,200</div>
          </Card>

          <Card>
            <FlexWrapper flexDirection="column">
              <div>신규확보 매물 분석</div>
            </FlexWrapper>
          </Card>
        </FlexWrapper>
      </DashBoardWrapper>
    </>
  );
}
